import React from "react";
import {
  Theme,
  makeStyles,
  createStyles,
  Drawer,
  IconButton,
  Divider,
  Typography,
  Container,
} from "@material-ui/core";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      borderTop: "1px",
      borderTopStyle: "solid",
      borderTopColor: theme.palette.primary.main,
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      top: "auto",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      top: "auto",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7),
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    view: {
      padding: theme.spacing(3, 0),
    },
    title: {
      padding: theme.spacing(2, 0),
    },
  })
);

interface Props {
  title: string;
  subtitle?: string;
  // if the page has sub-pages, this can be used to
  // describe which sub-page is currently displayed
  view?: string;
  drawerChildren?: React.ReactNode;
}

export const PageContainer: React.FC<Props> = ({
  title,
  subtitle,
  view,
  children,
  drawerChildren,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = (): void => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      {drawerChildren && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerToggle}>
              {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          {drawerChildren}
        </Drawer>
      )}
      <Container>
        <div className={classes.title}>
          <Typography variant="subtitle1">
            {title}
            {view && ` > ${view}`}
          </Typography>
          {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
        </div>
        <Divider />
        <div className={classes.view}>{children}</div>
      </Container>
    </div>
  );
};
