import React, { useCallback } from "react";
import {
  TableRow,
  RowRendererProps,
} from "../../../../components/PaginatedListTableRenderer";
import { unixTimeHumanReadable } from "../../../../api/connx";
import { truncateWithEllipses } from "../../../../helpers/strings";
import { RVModelOrder } from "../../../../api/connx/rvmodelorder";
import RVRowActions from "../RVRow";
import { Dropdown } from "react-bootstrap";

export interface OrderEvent {
  modelID: string;
  orderID: string;
}

export interface LinkEvent extends OrderEvent {
  // only present for 'unlink' events
  thingName?: string;
}

export interface RowProps {
  displayIDs?: boolean;
  onAssignOwner?: (orderID: string) => Promise<void> | void;
  onDelete?: (e: OrderEvent) => boolean | Promise<boolean>;
  onEdit?: (e: OrderEvent) => void;
  onLink?: (e: LinkEvent) => void;
  canDelete?: boolean;
  canUpdate?: boolean;
  canLink?: boolean;
  canAssignOrder?: boolean;
}

export const Row = ({
  item,
  index,
  onDeleted,
  displayIDs,
  onAssignOwner,
  onEdit,
  onDelete,
  onLink,
  canDelete,
  canUpdate,
  canAssignOrder,
  canLink,
  ...childProps
}: RowProps & RowRendererProps<RVModelOrder>): JSX.Element => {
  const handleAssignOwner = useCallback(() => {
    onAssignOwner && onAssignOwner(item.docID);
  }, [onAssignOwner, item]);

  const handleDelete = canDelete
    ? (): boolean | Promise<boolean> =>
        onDelete?.({ modelID: item.groupID, orderID: item.docID }) || false
    : undefined;

  const handleEdit = canUpdate
    ? (): void => onEdit?.({ modelID: item.groupID, orderID: item.docID })
    : undefined;

  const handleLink = (): void =>
    onLink?.({
      modelID: item.groupID,
      orderID: item.docID,
      thingName: item.iotThingName,
    });

  return (
    <TableRow item={item} {...childProps}>
      <div>{item.name}</div>
      <div>{truncateWithEllipses(item.desc, 100)}</div>
      <div>{item.serialNumber}</div>
      <div>{unixTimeHumanReadable(item.orderDate)}</div>
      <div>{item.provisioned ? "Yes" : "No"}</div>
      {displayIDs && <div>{item.docID}</div>}
      <RVRowActions
        getItemName={(): string => item.name}
        onDelete={handleDelete}
        onDeleted={(): void => onDeleted(index)}
        onEdit={handleEdit}
      >
        <Dropdown.Item
          disabled={!onAssignOwner || true !== canAssignOrder}
          onClick={handleAssignOwner}
        >
          Assign
        </Dropdown.Item>
        <Dropdown.Item
          disabled={undefined === item.iotThingName || true !== canLink}
          onClick={handleLink}
        >
          {"" !== item.iotThingName ? "Unlink" : "Link"}
        </Dropdown.Item>
      </RVRowActions>
    </TableRow>
  );
};
