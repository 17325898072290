import gql from "graphql-tag";
import { ApolloClient } from "@apollo/client";

type SystemEvent = {
  id: string;
  timestamp: string; // RFC3339
  type: string;
  params: object;
};

type SystemEventListQueryInput = {
  orderID: string;
  maxResults: number;
  nextKey?: string;
};

type SystemEventListQueryOutput = {
  events: SystemEvent[];
  nextKey?: string;
};

const fragments = {
  systemevent: gql`
    fragment systemevent on Event {
      id
      timestamp
      type
      params
    }
  `,
};

class SystemEventsAPI<TCacheShape> {
  private readonly client: ApolloClient<TCacheShape>;

  constructor(client: ApolloClient<TCacheShape>) {
    this.client = client;
  }

  async listEvents(
    input: SystemEventListQueryInput
  ): Promise<SystemEventListQueryOutput> {
    // schema of the query
    const GQL = gql`
      query($in: EventQueryInput!) {
        events(in: $in) {
          events {
            ...systemevent
          }
          nextKey
        }
      }
      ${fragments.systemevent}
    `;

    // execute the query
    interface Output {
      events: SystemEventListQueryOutput;
    }
    const r = await this.client.query<Output>({
      query: GQL,
      variables: {
        in: {
          systemID: input.orderID,
          maxResults: input.maxResults,
          nextKey: input.nextKey,
        },
      },
    });

    // return output
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.events;
  }
}

export default SystemEventsAPI;
