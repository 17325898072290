import React, { useState, useEffect, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { UserGroup } from "../../api/usergroup";
import { Loading } from "../../components";

interface EditUserGroupDetailsFormProps {
  onSave: (values: any) => Promise<UserGroup>;
  userGroupValues: UserGroup;
}

interface EditUserGroupDetailsFormValues {
  name: string;
  email: string;
  desc: string;
}

const EditUserGroupDetailsForm = ({
  onSave,
  userGroupValues = {
    name: "",
    email: "",
    desc: "",
    id: "",
    revision: 0,
  } as UserGroup,
}: EditUserGroupDetailsFormProps): JSX.Element => {
  const schema = yup.object().shape({
    name: yup
      .string()
      .min(5, "Too short!")
      .max(25, "Too long!")
      .required("Group name is required"),
    email: yup.string().email().required("Email is required"),
    desc: yup
      .string()
      .min(10, "Too short!")
      .max(128, "Too long!")
      .required("Description is required"),
  });

  const { name = "", email = "", desc = "", ...otherProps } = userGroupValues;

  const [values, setValues] = useState<EditUserGroupDetailsFormValues>({
    name: name,
    email: email,
    desc: desc,
    ...otherProps,
  });

  const handleSubmit = useCallback(
    async (
      values: EditUserGroupDetailsFormValues,
      { setSubmitting }: FormikHelpers<EditUserGroupDetailsFormValues>
    ) => {
      try {
        const r = await onSave(values);
        setValues(r);
      } catch (e) {
        console.log(e);
        alert(e.message);
      } finally {
        setSubmitting(false);
      }
    },
    [onSave]
  );

  return (
    <Formik
      validationSchema={schema}
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        isValid,
        dirty,
      }): JSX.Element => (
        <Form
          noValidate
          onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
            handleSubmit(e as React.FormEvent<HTMLFormElement>)
          }
        >
          <Form.Group>
            <Form.Label>Group name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter group name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={(touched.name || isValid) && !errors.name}
            />
            {errors.name && touched.name && (
              <Form.Text>{errors.name}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Contact email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter contact email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={(touched.email || isValid) && !errors.email}
            />
            {errors.email && touched.email && (
              <Form.Text>{errors.email}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Group description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a short group description"
              name="desc"
              value={values.desc}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={(touched.desc || isValid) && !errors.desc}
            />
            {errors.desc && touched.desc && (
              <Form.Text>{errors.desc}</Form.Text>
            )}
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={isSubmitting || !isValid || !dirty}
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};

interface UpdateUserGroupOutput {
  userGroup: UserGroup;
}

interface EditUserGroupDetailsProps {
  onSave: (
    values: any
  ) => Promise<UpdateUserGroupOutput> | UpdateUserGroupOutput;
  getUserGroupDetails: () => Promise<UserGroup>;
}

const EditUserGroupDetails = ({
  onSave,
  getUserGroupDetails,
}: EditUserGroupDetailsProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [userGroupDetails, setUserGroupDetails] = useState<UserGroup>(
    {} as UserGroup
  );

  useEffect(() => {
    setLoading(true);
    const c = new AbortController();
    const load = async (): Promise<void> => {
      try {
        const r = await getUserGroupDetails();
        if (!c.signal.aborted) {
          setUserGroupDetails(r);
        }
      } catch (e) {
        if (!c.signal.aborted) {
          console.log(e);
          alert(e.message);
        }
      } finally {
        if (!c.signal.aborted) {
          setLoading(false);
        }
      }
    };
    load();
    return (): void => {
      c.abort();
    };
  }, [getUserGroupDetails]);

  const handleSave = useCallback(
    async (v: any) => {
      const r = await onSave(v);
      return r.userGroup;
    },
    [onSave]
  );

  return loading ? (
    <Loading />
  ) : (
    <EditUserGroupDetailsForm
      onSave={handleSave}
      userGroupValues={userGroupDetails}
    />
  );
};

export default EditUserGroupDetails;
