import React from "react";
import { ApolloClient } from "@apollo/client";
import { ApolloProvider } from "@apollo/react-hooks";

export function withApolloProvider<P = {}>(
  client: ApolloClient<unknown>,
  Component: React.ComponentType<P>
): React.FC<P> {
  const Wrapper: React.FC<P> = (props) => (
    <ApolloProvider client={client}>
      <Component {...props} />
    </ApolloProvider>
  );
  return Wrapper;
}
