import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { gql } from "apollo-boost";
import { ApolloError } from "apollo-client/errors/ApolloError";
import { isApolloError } from "apollo-client/errors/ApolloError";
import React, { ReactNode, SyntheticEvent, useEffect, useReducer } from "react";
import UserSelect, {
  SelectedUserEvent,
  UserOption,
} from "../../../../components/user/UserSelect";

const useStyles = makeStyles({
  select: {
    minHeight: 250,
  },
});

export const ASSIGN_OWNER = gql`
  mutation($userID: ID!, $orderID: ID!) {
    rvOrderOwner(userID: $userID, orderID: $orderID)
  }
`;

export interface UserSelectProps {
  onSelectUser?: (e: SelectedUserEvent) => Promise<void> | void;
  isDisabled?: boolean;
  value?: UserOption;
}

export interface AssignOwnerModalProps {
  orderID: string;
  show: boolean;
  onDone: () => void;
  userSelect?: (props: UserSelectProps) => ReactNode;
}
interface State {
  showDialog?: boolean;
  showAlert?: boolean;
  submitting?: boolean;
  alertSeverity?: "success" | "error";
  alertMessage?: string;
  selectedUser?: UserOption;
}

type Action =
  | { type: "hidden" }
  | { type: "show" }
  | { type: "selected_user"; user: UserOption }
  | { type: "submitting" }
  | { type: "success" }
  | { type: "failure"; message?: string };

const reducer = (prev: State, action: Action): State => {
  switch (action.type) {
    case "show":
      return { showDialog: true };
    case "selected_user":
      return { ...prev, selectedUser: action.user };
    case "submitting":
      return { ...prev, showDialog: false, submitting: true };
    case "success":
      return {
        showAlert: true,
        alertSeverity: "success",
        alertMessage: "Assigned order OK",
      };
    case "failure":
      return {
        showAlert: true,
        alertSeverity: "error",
        alertMessage: action.message || "Failed to assign order",
      };
    case "hidden":
    default:
      return {};
  }
};

export const AssignOwnerModal = ({
  orderID,
  show,
  onDone,
  userSelect = UserSelect,
}: AssignOwnerModalProps): JSX.Element => {
  // state
  const [state, dispatch] = useReducer(reducer, {} as State);

  // styles
  const classes = useStyles();

  // handlers
  useEffect(() => {
    dispatch({ type: show ? "show" : "hidden" });
  }, [show]);
  const handleClose = (): void => onDone();
  const handleUserChange = (e: SelectedUserEvent): void => {
    dispatch({ type: "selected_user", user: e.user });
  };
  const [setOwnerID] = useMutation(ASSIGN_OWNER);
  const handleAssign = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    dispatch({ type: "submitting" });
    onDone();
    try {
      await setOwnerID({
        variables: {
          userID: state.selectedUser?.user.id,
          orderID: orderID,
        },
      });
      dispatch({ type: "success" });
    } catch (e) {
      if (isApolloError(e)) {
        const apolloError = e as ApolloError;
        const messages = apolloError.graphQLErrors.map(
          ({ message }) => message
        );
        dispatch({ type: "failure", message: messages.join() });
      } else {
        console.error(e);
        dispatch({ type: "failure" });
      }
    }
  };
  const handleHideAlert = (): void => dispatch({ type: "hidden" });

  return (
    <>
      <Snackbar
        open={state.showAlert === true}
        autoHideDuration={6000}
        onClose={handleHideAlert}
        data-testid="alerts"
      >
        <Alert severity={state.alertSeverity}>{state.alertMessage}</Alert>
      </Snackbar>
      <Dialog
        open={state.showDialog === true}
        onClose={handleClose}
        data-testid="dialog-root"
      >
        <DialogTitle>Assign order to user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a user to assign the order to.
          </DialogContentText>
          <div className={classes.select}>
            {userSelect({
              isDisabled: state.submitting === true,
              onSelectUser: handleUserChange,
              value: state.selectedUser,
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAssign}
            color="primary"
            disabled={state.selectedUser === undefined}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
