import React from "react";
import AppliedRoute from "../components/AppliedRoute";
import { Dashboard } from "../containers/connx/dcx/dashboard/Dashboard";
import { useParams } from "react-router-dom";
import { useSearchParams } from "./util";

const DCXDashboardRouted: React.FC = () => {
  const { orderID } = useParams<{ orderID: string }>();
  const searchParams = useSearchParams();
  return (
    <Dashboard
      orderID={orderID}
      dashName={searchParams.get("dash_name") || undefined}
    />
  );
};

const DCXRoutes: React.FC<{ childProps: unknown }> = ({ childProps }) => (
  <AppliedRoute
    path="/dcx/:orderID/dashboard"
    props={childProps}
    exact
    component={DCXDashboardRouted}
  />
);

DCXRoutes.displayName = "DCXRoutes";

export default DCXRoutes;
