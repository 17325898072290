import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ConnXClient } from "../../../api/apolloClient";
import moment from "moment";
import { Card } from "react-bootstrap";
import Chip from "@material-ui/core/Chip";
import {
  Tooltip,
  makeStyles,
  Theme,
  createStyles,
  Box,
  LinearProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { withApolloProvider } from "../../../helpers/apollo";
import { ActionMenu, OnDCXDashEvent } from "./live/ActionMenu";
import { useOrder } from "../../../helpers/orderContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertsRoot: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      textTransform: "uppercase",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    gauge: {
      width: 100,
      height: 100,
    },
    table: {
      width: "100%",
    },
  })
);

interface GaugeProps {
  pct: number;
}

const LinearProgressWithLabel = (props: GaugeProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={props.pct} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.pct
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

interface LiveData {
  pvPower: number;
  battSOC: number;
  updatedAt: string;
}

interface LiveParam {
  value: number;
  updatedAt: string;
}

interface Alerts {
  name: string;
  message: string;
  level: string;
}

interface DCXLiveQueryResult {
  pvPower?: LiveParam;
  batterySOC?: LiveParam;
  batteryTotalVoltage?: LiveParam;
  liquid0?: LiveParam;
  liquid1?: LiveParam;
  liquid2?: LiveParam;
  liquid5?: LiveParam;
  liquid3?: LiveParam;
  liquid4?: LiveParam;
  alerts: { alerts: Alerts[] };
}

const DCX_LIVE_QUERY = gql`
  query dcxData($orderID: ID!) {
    pvPower: dcxLiveParam(orderID: $orderID, input: { name: "pv.power" }) {
      value
      updatedAt
    }
    batterySOC: dcxLiveParam(
      orderID: $orderID
      input: { name: "battery.soc" }
    ) {
      value
      updatedAt
    }
    batteryTotalVoltage: dcxLiveParam(
      orderID: $orderID
      input: { name: "battery.total_voltage" }
    ) {
      value
      updatedAt
    }
    liquid0: dcxLiveParam(
      orderID: $orderID
      input: { name: "liquids.level0" }
    ) {
      value
      updatedAt
    }
    liquid1: dcxLiveParam(
      orderID: $orderID
      input: { name: "liquids.level1" }
    ) {
      value
      updatedAt
    }
    liquid2: dcxLiveParam(
      orderID: $orderID
      input: { name: "liquids.level2" }
    ) {
      value
      updatedAt
    }
    liquid5: dcxLiveParam(
      orderID: $orderID
      input: { name: "liquids.level5" }
    ) {
      value
      updatedAt
    }
    liquid3: dcxLiveParam(
      orderID: $orderID
      input: { name: "liquids.level3" }
    ) {
      value
      updatedAt
    }
    liquid4: dcxLiveParam(
      orderID: $orderID
      input: { name: "liquids.level4" }
    ) {
      value
      updatedAt
    }
    alerts: dcxAlerts(orderID: $orderID, input: {}) {
      alerts {
        name
        message
        level
      }
    }
  }
`;

interface Props {
  onDCXDash?: (e: OnDCXDashEvent) => Promise<void> | void;
  pollInterval?: number;
  title?: string;
}

interface GaugeData {
  label: string;
  status: string;
  state: string;
  stateTooltip: string;
  pct: number;
}

export const adcToPct = (val: number): number =>
  Math.round((Math.min(5, Math.max(0, val)) / 5.0) * 100);

export function formatGaugeData(
  data?: DCXLiveQueryResult
): Record<string, GaugeData> {
  const gaugeData: Record<string, GaugeData> = {
    pv: {
      label: "Solar",
      status: "N/A",
      state: "",
      stateTooltip: "",
      pct: 0,
    },
    batt: {
      label: "Battery",
      status: "N/A",
      state: "",
      stateTooltip: "",
      pct: 0,
    },
    volt: {
      label: "Battery Volts",
      status: "N/A",
      state: "",
      stateTooltip: "",
      pct: 0,
    },
    liquid0: {
      label: "Potable 1",
      status: "N/A",
      state: "",
      stateTooltip: "",
      pct: 0,
    },
    liquid1: {
      label: "Potable 2",
      status: "N/A",
      state: "",
      stateTooltip: "",
      pct: 0,
    },
    liquid2: {
      label: "Grey 1",
      status: "N/A",
      state: "",
      stateTooltip: "",
      pct: 0,
    },
    liquid5: {
      label: "Grey 2",
      status: "N/A",
      state: "",
      stateTooltip: "",
      pct: 0,
    },
    liquid3: {
      label: "Waste",
      status: "N/A",
      state: "",
      stateTooltip: "",
      pct: 0,
    },
    liquid4: {
      label: "Fuel",
      status: "N/A",
      state: "Unknown",
      stateTooltip: "",
      pct: 0,
    },
  };
  if (data) {
    if (data.pvPower) {
      const NOMINAL_ECO_SITE_PV_MAX = 3000; // Watts
      gaugeData["pv"].status = `${data.pvPower.value / 1000}kW`;
      gaugeData["pv"].pct =
        (data.pvPower.value / NOMINAL_ECO_SITE_PV_MAX) * 100;
    }
    if (data.batterySOC) {
      gaugeData["batt"].status = ``;
      gaugeData["batt"].pct = data.batterySOC.value;
    }
    if (data.batteryTotalVoltage) {
      const NOMINAL_ECO_SITE_VOLTS_MAX = 58.4; // Volts
      const NOMINAL_ECO_SITE_VOLTS_MIN = 48; // Volts
      gaugeData["volt"].status = `${data.batteryTotalVoltage.value}V`;
      gaugeData["volt"].pct = Math.min(
        100,
        Math.max(
          0,
          ((data.batteryTotalVoltage.value - NOMINAL_ECO_SITE_VOLTS_MIN) /
            (NOMINAL_ECO_SITE_VOLTS_MAX - NOMINAL_ECO_SITE_VOLTS_MIN)) *
            100
        )
      );
    }

    const liquids = [
      { record: "liquid0", liveParam: data.liquid0 },
      { record: "liquid1", liveParam: data.liquid1 },
      { record: "liquid2", liveParam: data.liquid2 },
      { record: "liquid5", liveParam: data.liquid5 },
      { record: "liquid3", liveParam: data.liquid3 },
    ];
    liquids.forEach((l) => {
      if (l.liveParam) {
        gaugeData[l.record].status = ``;
        gaugeData[l.record].pct = adcToPct(l.liveParam.value);
      }
    });

    if (data.liquid4) {
      let state = "";
      let tooltip = "";
      if (0 === data.liquid4.value) {
        state = "Unknown";
        tooltip = "Not Connected";
      } else if (2.5 < data.liquid4.value) {
        state = "Ok";
        tooltip = "More than 40%";
      } else {
        state = "Low";
        tooltip = "40% or less";
      }
      gaugeData["liquid4"].status = ``;
      gaugeData["liquid4"].state = state;
      gaugeData["liquid4"].stateTooltip = tooltip;
    }
  }
  return gaugeData;
}

const LiveInternal: React.FC<Props> = ({
  onDCXDash,
  pollInterval = 30000,
  title = "DCX",
}) => {
  const { docID: orderID } = useOrder();

  const { error, data } = useQuery<DCXLiveQueryResult>(DCX_LIVE_QUERY, {
    fetchPolicy: "no-cache",
    variables: { orderID },
    pollInterval: pollInterval,
  });

  if (error) {
    console.log(error);
  }

  const gaugeData = formatGaugeData(data);

  let alerts = [] as Alerts[];
  let updatedAt = "N/A";
  if (data) {
    alerts = data.alerts.alerts;
    if (data.pvPower) {
      updatedAt = moment(data.pvPower.updatedAt).format("LLL");
    }
  }

  const classes = useStyles();

  const guages = [
    "pv",
    "batt",
    "liquid0",
    "liquid1",
    "liquid2",
    "liquid5",
    "liquid3",
    "liquid4",
  ];

  return (
    <Card body>
      <Card.Title>
        <div className="d-flex justify-content-between align-items-center">
          <div>{title}</div>
          <div className={classes.alertsRoot}>
            {alerts
              .filter((x) => x.level === "warn" || x.level === "error")
              .map((x, i) => (
                <Tooltip title={x.message} key={`${x.name}-${i}`}>
                  <Chip label={x.name} size="small" icon={<ErrorIcon />} />
                </Tooltip>
              ))}
          </div>
          <div>{onDCXDash && <ActionMenu onDCXDash={onDCXDash} />}</div>
        </div>
      </Card.Title>
      <TableContainer>
        <Table
          className={classes.table}
          aria-label="Live data fields"
          size="small"
          padding="none"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Updated
              </TableCell>
              <TableCell align="right">{updatedAt}</TableCell>
            </TableRow>
            {guages.map((key, i) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {gaugeData[key].label}
                  {gaugeData[key].status !== "" && `(${gaugeData[key].status})`}
                </TableCell>
                <TableCell align="right">
                  {gaugeData[key].state !== "" && (
                    <span className="pr-1">
                      <Tooltip title={gaugeData[key].stateTooltip}>
                        <InfoOutlined />
                      </Tooltip>
                    </span>
                  )}
                  {gaugeData[key].state !== "" && `${gaugeData[key].state}`}
                  {gaugeData[key].state === "" && (
                    <LinearProgressWithLabel key={i} pct={gaugeData[key].pct} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default withApolloProvider(ConnXClient, LiveInternal);
