import React, { SyntheticEvent, useState, useCallback } from "react";
import PaginatedSearchList from "../../components/PaginatedSearchList";
import { DeviceConfiguration, unixTimeHumanReadable } from "../../api/connx";
import { anyGranted } from "../../user/user";
import "./DeviceConfigurationList.css";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ValueType } from "react-select";
import {
  withPaginatedItemSupport,
  ListRendererProps,
  DataSourceOutput,
} from "../../components/PaginatedList";
import {
  renderTable,
  RowRendererProps,
} from "../../components/PaginatedListTableRenderer";
import { RBACPermission } from "../../user/rbac";

interface Option {
  label: string;
  value: string;
}

interface RowProps {
  onClone?: (item: DeviceConfiguration, version: string) => void;
  hasPermission?: (p: RBACPermission) => boolean;
}

export const DeviceConfigRow: React.FC<
  RowProps & RowRendererProps<DeviceConfiguration>
> = (props) => {
  const { item, onClone, hasPermission = anyGranted } = props;

  const [selectedVersion, setSelectedVersion] = useState<ValueType<Option>>(
    null
  );

  const handleClone = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      const sv = selectedVersion as Option;
      if (sv) {
        onClone?.(item, sv.value);
      }
    },
    [onClone, item, selectedVersion]
  );

  const versionOptions = item.versions?.map<Option>((v) => {
    return { label: unixTimeHumanReadable(v.version), value: v.docID };
  });

  return (
    <tr>
      <td>{item.name}</td>
      <td>{item.desc}</td>
      <td>
        {item.device
          ? item.device.manufacturer + "/" + item.device.name
          : "<Device not found>"}
      </td>
      <td className="VersionDropdown">
        <Select
          options={versionOptions}
          value={selectedVersion}
          onChange={(v: ValueType<Option>): void => setSelectedVersion(v)}
        />
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" />
          <Dropdown.Menu>
            <Dropdown.Item
              key="0"
              disabled={
                !hasPermission("device_cfg_update") ||
                !selectedVersion ||
                !item.device ||
                !onClone
              }
              onClick={(e: any): void => handleClone(e)}
            >
              Copy
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

const MyRow = withRouter(
  ({
    history,
    ...props
  }: RouteComponentProps & RowRendererProps<DeviceConfiguration>) => {
    const handleClone = useCallback(
      (item: DeviceConfiguration, version: string) => {
        history.push(
          "/connx/config/defaults/clone/" +
            item.groupID +
            "/" +
            item.docID +
            "/" +
            version
        );
      },
      [history]
    );

    return <DeviceConfigRow onClone={handleClone} {...props} />;
  }
);

const TableRenderer = renderTable(MyRow);

const MyTableRenderer = (
  props: ListRendererProps<DeviceConfiguration>
): JSX.Element => (
  <TableRenderer {...props}>
    <div>Name</div>
    <div>Desc</div>
    <div>Device</div>
    <div>Versions</div>
    <div></div>
  </TableRenderer>
);

export type ListDeviceConfigurations = ({
  filter,
  nextKey,
}: {
  filter: string;
  nextKey?: string;
}) =>
  | Promise<DataSourceOutput<DeviceConfiguration>>
  | DataSourceOutput<DeviceConfiguration>;

interface DeviceConfigurationListProps {
  listDeviceConfigurations: ListDeviceConfigurations;
}

const DeviceConfigurationList = ({
  listDeviceConfigurations,
}: DeviceConfigurationListProps): JSX.Element => {
  // wrap data source such that it conforms to required data source
  const cb = useCallback(
    async ({ filter, nextKey }: { filter: string; nextKey?: string }) => {
      const r = await listDeviceConfigurations({ filter, nextKey });
      return {
        ...r,
        items: r.items.map((v) => withPaginatedItemSupport(v, v.docID)),
      };
    },
    [listDeviceConfigurations]
  );

  return <PaginatedSearchList getItems={cb} ListRenderer={MyTableRenderer} />;
};

export default DeviceConfigurationList;
