import React, { useState, useEffect, useCallback, ReactNode } from "react";
import "./PaginatedSearchList.css";
import { Button, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import Loading from "./Loading";

// Items returned from a data source must at minimum implement
// this interface
export interface Item {
  id: string;
}

// defines the output of the data source
export interface DataSourceOutput<TItem> {
  items: TItem[];
  nextKey?: string;
}

// defines a data source
export interface DataSource<TItem> {
  (nextKey?: string):
    | Promise<DataSourceOutput<TItem>>
    | DataSourceOutput<TItem>;
}

export interface ListRendererProps<TItem> {
  list: TItem[];
  onDeleted: (index: number) => void;
}

export type NamedChildren = {
  // optional element to display above the table
  tableOptions?: ReactNode;
};

export interface RenderItemsProps<TItem extends Item> {
  item: TItem;
}

interface PaginatedListProps<TItem extends Item> {
  dataSource: DataSource<TItem>;
  renderItem?: (props: RenderItemsProps<TItem>) => JSX.Element;
  setLoading?: (loading: boolean) => void;
  noItemsMessage?: string;
  refreshToken?: string | number;
  ListRenderer?: React.ComponentType<ListRendererProps<TItem>>;
  children?: NamedChildren;
}

// given a model and a value to use as the 'id' field, return an intersection of the TModel and Item types,
// with the 'id' field populated
export const withPaginatedItemSupport = <TModel extends object>(
  model: TModel,
  idVal: string
): TModel & Item => {
  return {
    ...model,
    id: idVal,
  };
};

interface UseDataSourceResult<TItem extends Item> {
  items: TItem[];
  submitting: boolean;
  noItems: boolean;
  deleteItem: (index: number) => void;
  getResults: () => void;
  hasMoreItems: boolean;
}

function useDataSource<TItem extends Item>({
  dataSource,
  refreshToken,
}: PaginatedListProps<TItem>): UseDataSourceResult<TItem> {
  const [listState, setListState] = useState<{
    items: TItem[];
    nextKey?: string;
    submitting: boolean;
    noItems: boolean;
  }>({
    items: new Array<TItem>(),
    submitting: false,
    noItems: false,
  });

  const doQueryAsync = useCallback(
    async (
      existingItems: TItem[],
      { nextKey, signal }: { nextKey?: string; signal?: AbortSignal }
    ) => {
      setListState((prev) => ({
        ...prev,
        noItems: false,
        submitting: true,
      }));
      try {
        // run the query
        const r = await dataSource(nextKey);
        if (signal && signal.aborted) return;
        // check if should display has items
        const hasItems =
          undefined === nextKey
            ? // new query, something returned?
              r.items.length > 0
            : // continuation of previous query
              true;
        setListState((prev) => ({
          ...prev,
          items:
            nextKey === undefined ? r.items : [...existingItems, ...r.items],
          nextKey: r.nextKey === null ? undefined : r.nextKey,
          submitting: false,
          noItems: !hasItems,
        }));
      } catch (e) {
        console.log(e);
      }
    },
    [dataSource]
  );

  const getResults = useCallback(() => {
    doQueryAsync(listState.items, { nextKey: listState.nextKey });
  }, [doQueryAsync, listState.items, listState.nextKey]);

  const hasMoreItems = listState.nextKey !== undefined;

  // populate the list on initial draw or if refresh token changes
  useEffect(() => {
    const abortController = new AbortController();
    // reset cursor and items
    setListState((prev) => ({
      ...prev,
      items: [],
      nextKey: undefined,
    }));
    // run the query
    doQueryAsync([], { signal: abortController.signal });
    // return a cleanup function to abort any async operations in the
    // query
    return (): void => {
      abortController.abort();
    };
  }, [refreshToken, doQueryAsync]);

  // handle deletion of an item
  const deleteItem = useCallback(
    (index: number) => {
      setListState((prev) => {
        let newItems = prev.items;
        if (index >= 0 && index < prev.items.length) {
          newItems = [...prev.items];
          newItems.splice(index);
        }
        return {
          ...prev,
          items: newItems,
        };
      });
    },
    [setListState]
  );

  return {
    items: listState.items,
    submitting: listState.submitting,
    noItems: listState.noItems,
    deleteItem,
    getResults,
    hasMoreItems,
  };
}

const List = ({
  list,
  renderItem,
}: ListRendererProps<Item> & {
  renderItem?: (item: Item) => JSX.Element;
}): JSX.Element => (
  <ListGroup>
    {list &&
      list.map((item) => (
        <ListGroup.Item key={item.id}>{renderItem?.(item)}</ListGroup.Item>
      ))}
  </ListGroup>
);

const PaginatedList = <TItem extends Item>({
  dataSource,
  renderItem,
  setLoading,
  noItemsMessage = "No items found",
  // Optional. How to render the items.
  ListRenderer = List,
  children,
  refreshToken,
  ...rest
}: PaginatedListProps<TItem>): JSX.Element => {
  // custom hook to handle refreshing data source
  const {
    items,
    submitting,
    noItems,
    deleteItem,
    getResults,
    hasMoreItems,
  } = useDataSource({ dataSource, refreshToken });

  // callback for loading
  useEffect(() => {
    setLoading?.(submitting);
  }, [setLoading, submitting]);

  // optional child components
  const { tableOptions } = children || ({} as NamedChildren);

  return (
    <div className="PaginatedList">
      <div>{tableOptions}</div>
      <div className="list">
        {noItems === true ? (
          noItemsMessage
        ) : (
          <ListRenderer
            list={items}
            onDeleted={deleteItem}
            {...{
              ...{ renderItem: renderItem },
              ...rest,
            }} // only applicable for renderers that have custom item renderers
          />
        )}
      </div>
      <div className="interactions">{submitting && <Loading />}</div>
      <div className="interactions">
        {!submitting && hasMoreItems && (
          <Button variant="primary" type="submit" onClick={getResults}>
            <FontAwesomeIcon icon={faEllipsisH} title="More" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default PaginatedList;
