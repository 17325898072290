import React from "react";
import { DCXAlerts, DCXAlertsConfig } from "./DCXAlerts";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useUser } from "../../../userContext";
import {
  ErrorView,
  useSuccessFailureToasty,
  SuccessFailureToasty,
  Loading,
} from "../../../components";

interface AlertsConfigQueryInput {
  userID: string;
}

interface AlertsConfigQueryOutput {
  batterySOC: {
    config: {
      phoneNumbers: string[];
    };
  };
  batteryUsage: {
    config: {
      phoneNumbers: string[];
    };
  };
  generatorFailToStart: {
    config: {
      phoneNumbers: string[];
    };
  };
}

export const ALERTS_CONFIG_QUERY = gql`
  query userAlertConfig($userID: ID!) {
    batterySOC: userAlertConfig(
      input: { user: $userID, type: DCX_BATTERY_SOC }
    ) {
      config {
        phoneNumbers
      }
    }
    batteryUsage: userAlertConfig(
      input: { user: $userID, type: DCX_BATTERY_USAGE }
    ) {
      config {
        phoneNumbers
      }
    }
    generatorFailToStart: userAlertConfig(
      input: { user: $userID, type: DCX_GENERATOR_FAIL_TO_START }
    ) {
      config {
        phoneNumbers
      }
    }
  }
`;

interface UpdateUserAlertConfigInput {
  userID: string;
  batterySOCPhoneNumbers: string[];
  batteryUsagePhoneNumbers: string[];
  generatorFailToStartNumbers: string[];
}

interface UpdateUserAlertConfigOutput {
  reserved: boolean;
}

const ALERTS_CONFIG_MUTATE = gql`
  mutation updateUserAlertConfig(
    $userID: ID!
    $batterySOCPhoneNumbers: [String!]!
    $batteryUsagePhoneNumbers: [String!]!
    $generatorFailToStartNumbers: [String!]!
  ) {
    batterySOC: updateUserAlertConfig(
      input: {
        user: $userID
        type: DCX_BATTERY_SOC
        phoneNumbers: $batterySOCPhoneNumbers
      }
    ) {
      reserved
    }
    batteryUsage: updateUserAlertConfig(
      input: {
        user: $userID
        type: DCX_BATTERY_USAGE
        phoneNumbers: $batteryUsagePhoneNumbers
      }
    ) {
      reserved
    }
    generatorFailToStart: updateUserAlertConfig(
      input: {
        user: $userID
        type: DCX_GENERATOR_FAIL_TO_START
        phoneNumbers: $generatorFailToStartNumbers
      }
    ) {
      reserved
    }
  }
`;

const Alerts: React.FC<{}> = () => {
  const userCtx = useUser();

  // snackbar state
  const {
    props: toastyProps,
    setSuccess: setToastySuccess,
    setFailure: setToastyFailure,
  } = useSuccessFailureToasty();

  // graphql query to get current configuration
  const { loading, error, data } = useQuery<
    AlertsConfigQueryOutput,
    AlertsConfigQueryInput
  >(ALERTS_CONFIG_QUERY, {
    variables: {
      userID: userCtx.user.id,
    },
    fetchPolicy: "no-cache",
    skip: !userCtx.user.isAuthenticated,
  });

  const [updateConfig] = useMutation<
    UpdateUserAlertConfigOutput,
    UpdateUserAlertConfigInput
  >(ALERTS_CONFIG_MUTATE, {});

  const handleSubmit = async (cfg: DCXAlertsConfig): Promise<void> => {
    try {
      await updateConfig({
        variables: {
          userID: userCtx.user.id,
          batterySOCPhoneNumbers: cfg.batterySOCCritical
            ? [cfg.phoneNumber]
            : [],
          batteryUsagePhoneNumbers: cfg.batteryUsageHigh
            ? [cfg.phoneNumber]
            : [],
          generatorFailToStartNumbers: cfg.generatorFailToStart
            ? [cfg.phoneNumber]
            : [],
        },
      });
      setToastySuccess();
    } catch (e) {
      setToastyFailure();
      throw e;
    }
  };

  if (error) {
    return <ErrorView error={error} />;
  }
  if (loading) {
    return <Loading delay={0} />;
  }
  if (!data) {
    return <ErrorView error="No alerts data!" />;
  }
  return (
    <>
      <DCXAlerts
        initialState={{
          batterySOCCritical: data.batterySOC.config.phoneNumbers.length > 0,
          batteryUsageHigh: data.batteryUsage.config.phoneNumbers.length > 0,
          generatorFailToStart:
            data.generatorFailToStart.config.phoneNumbers.length > 0,
          phoneNumber: data.batterySOC.config.phoneNumbers[0],
        }}
        onSubmit={handleSubmit}
      />
      <SuccessFailureToasty
        successMessage="Updated OK"
        failureMessage="Failed to update"
        {...toastyProps}
      />
    </>
  );
};

export default Alerts;
