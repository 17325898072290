import React, { useState, useCallback } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Form, ButtonToolbar } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import Client, {
  Firmware,
  FirmwareVersion,
  RegisteredDevice,
} from "../../api/connx";

const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(10)
    .max(50)
    .matches(/^[a-z\s\d-]*$/i)
    .required("Name is required"),
  desc: yup
    .string()
    .trim()
    .min(20)
    .max(100)
    .matches(/^[a-z\s\d.-?,]*$/i)
    .required("Description is required"),
  deviceid: yup
    .mixed()
    .test("deviceid-test", "Device is required", (v) => null !== v),
  fwdir: yup
    .mixed()
    .test("fwdir-test", "Firmware is required", (v) => null !== v),
  fwver: yup
    .mixed()
    .test("fwver-test", "Firmware version is required", (v) => null !== v),
});

interface OptionType<TModel> {
  label: string;
  value: string;
  data: TModel;
}

export interface FormValues {
  name: string;
  desc: string;
  deviceid?: OptionType<RegisteredDevice>;
  fwdir?: OptionType<Firmware>;
  fwver?: OptionType<FirmwareVersion>;
}

const initialValues: FormValues = {
  name: "",
  desc: "",
};

const loadDeviceOptions = async (
  inputValue: string
): Promise<OptionType<RegisteredDevice>[]> => {
  try {
    const r = await Client.listRegisteredDevices(inputValue);
    return r.items.map((v) => {
      return { value: v.id, label: v.name, data: v };
    });
  } catch (e) {
    return [];
  }
};

const loadFirmwareOptions = async (
  inputValue: string
): Promise<OptionType<Firmware>[]> => {
  try {
    const r = await Client.listFirmware(inputValue);
    return r.fw.map((v) => {
      return { value: v.id, label: v.id, data: v };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

const loadFirmwareVersionsOptions = (fw?: Firmware) => async (): Promise<
  OptionType<FirmwareVersion>[]
> => {
  try {
    return fw
      ? fw.versions.map((v) => {
          return { value: v.version, label: v.version, data: v };
        })
      : [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

interface CreateDeviceConfigurationProps {
  canEditConfig?: boolean;
  initialValues?: FormValues;
  confirmActionLabel?: string;
  onSubmit: (values: FormValues) => Promise<any> | any;
  onCancel?: () => void;
}

const CreateDeviceConfiguration = (
  props: CreateDeviceConfigurationProps
): JSX.Element => {
  const canEditConfig = true === props.canEditConfig;

  const [values, setValues] = useState(props.initialValues || initialValues);

  const { onSubmit } = props;

  const handleSubmit = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      try {
        await onSubmit(values);
        console.log(values);
        setValues(initialValues);
        resetForm(initialValues);
        alert("Device configuration created");
      } catch (e) {
        console.log(e);
        alert(e.message);
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit]
  );

  return (
    <div>
      <Formik
        validationSchema={schema}
        initialValues={values}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
          isValid,
          setFieldValue,
          resetForm,
          dirty,
        }): JSX.Element => (
          <Form
            noValidate
            onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
              handleSubmit(e as React.FormEvent<HTMLFormElement>)
            }
          >
            {/* Name and description */}
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name. E.g. ozxcorp-brakelight-cfg"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting || !canEditConfig}
                isValid={
                  (touched.name || isValid) && !errors.name && canEditConfig
                }
              />
              {errors.name && touched.name && (
                <Form.Text>{errors.name}</Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                name="desc"
                value={values.desc}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting || !canEditConfig}
                isValid={
                  (touched.desc || isValid) && !errors.desc && canEditConfig
                }
              />
              {errors.desc && touched.desc && (
                <Form.Text>{errors.desc}</Form.Text>
              )}
            </Form.Group>

            {/* Target device */}
            <Form.Group>
              <Form.Label>Target device</Form.Label>
              <AsyncSelect
                cacheOptions
                onChange={(v): void => setFieldValue("deviceid", v)}
                loadOptions={loadDeviceOptions}
                isDisabled={isSubmitting || !canEditConfig}
                value={values.deviceid}
              />
            </Form.Group>

            {/* Firmware */}
            <Form.Group>
              <Form.Label>Firmware</Form.Label>
              <AsyncSelect
                cacheOptions
                onChange={(v): void => {
                  setFieldValue("fwdir", v);
                  setFieldValue("fwver", null);
                }}
                loadOptions={loadFirmwareOptions}
                isDisabled={isSubmitting}
                value={values.fwdir}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Firmware version</Form.Label>
              <AsyncSelect
                key={JSON.stringify(values.fwdir ? values.fwdir.value : "")}
                defaultOptions
                cacheOptions
                onChange={(v): void => setFieldValue("fwver", v)}
                loadOptions={loadFirmwareVersionsOptions(
                  values.fwdir ? values.fwdir.data : undefined
                )}
                isDisabled={
                  isSubmitting ||
                  undefined === values.fwdir ||
                  undefined !== errors.fwdir
                }
                value={values.fwver}
              />
            </Form.Group>

            {/* Save and cancel */}
            <ButtonToolbar>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || !isValid || !dirty}
              >
                {" "}
                {props.confirmActionLabel || "Create"}{" "}
              </Button>
              <Button
                variant="secondary"
                className="ml-1"
                onClick={(): void => {
                  const r = window.confirm("Are you sure?");
                  if (true === r) {
                    resetForm();
                    props.onCancel && props.onCancel();
                  }
                }}
                disabled={isSubmitting}
              >
                {" "}
                Cancel{" "}
              </Button>
            </ButtonToolbar>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateDeviceConfiguration;
export { schema, initialValues };
