import React from "react";
import { LoadingSpinner } from "..";
import { makeStyles, Theme } from "@material-ui/core";
import { AutoSizer, Size } from "react-virtualized";
import Chart from "react-apexcharts";
import moment from "moment";

// From https://github.com/apexcharts/apexcharts.js/blob/master/types/apexcharts.d.ts
type ApexYAxis = {
  show?: boolean;
  opposite?: boolean;
  min?: number | ((min: number) => number);
  max?: number | ((max: number) => number);
  decimalsInFloat?: number;
  labels?: {
    style?: {
      colors?: string;
    };
    formatter?(val: number): string;
  };
  axisBorder?: {
    show?: boolean;
    color?: string;
  };
  title?: {
    text?: string;
    style?: {
      color?: string;
    };
  };
};

interface EventPoint {
  x: number;
  label: string;
  color: string;
}

interface DataPoint {
  x: number;
  y: number;
}

interface Series {
  data: DataPoint[];
  name: string;
}

export type ValueFormatterFunc = (val: number) => string;

interface YDomain {
  min?: number;
  max?: number;
  seriesIdx: number;
  valueFormatter: ValueFormatterFunc;
}

interface Props {
  series?: Series[];
  events?: EventPoint[];
  height: number;
  xDomain?: number[];
  yDomains: YDomain[];
  curves: string[];
  loading?: boolean;
  showMax?: boolean;
  showMin?: boolean;
  zoomNonZero?: boolean;
  bar: boolean;
  title: string;
}

const defaultColors = [
  "#008FFB",
  "#00E396",
  "#feb019",
  "#ff455f",
  "#775dd0",
  "#80effe",
  "#0077B5",
  "#ff6384",
  "#c9cbcf",
  "#0057ff",
  "#00a9f4",
  "#2ccdc9",
  "#5e72e4",
];

const useStyles = makeStyles<Theme, Props>({
  root: {
    height: (props): number => props.height,
  },
});

export const getFirstNonZeroY = (data: DataPoint[]): number => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].y !== 0) {
      return i;
    }
  }
  return -1;
};

export const getLastNonZeroY = (data: DataPoint[]): number => {
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i].y !== 0) {
      return i;
    }
  }
  return -1;
};

export const trimStart = (series: Series[]): number => {
  // Find the last X value that has a zero Y value so we can start our domain from there.
  let zoomStartX = -1;
  for (let i = 0; i < series.length; i++) {
    let firstNonZeroY = getFirstNonZeroY(series[i].data);
    if (-1 === firstNonZeroY) {
      continue;
    }
    // Go back one so we see one zero Y value
    firstNonZeroY = Math.max(0, firstNonZeroY - 1);
    if (-1 === zoomStartX) {
      zoomStartX = series[i].data[firstNonZeroY].x;
    } else {
      zoomStartX = Math.min(series[i].data[firstNonZeroY].x, zoomStartX);
    }
  }
  return zoomStartX;
};

export const trimEnd = (series: Series[]): number => {
  // Find the first X value going backwards from the end that has a zero Y value so we can end our domain from there.
  let zoomEndX = -1;
  for (let i = 0; i < series.length; i++) {
    let lastNonZeroY = getLastNonZeroY(series[i].data);
    if (-1 === lastNonZeroY) {
      continue;
    }
    // Go forward one so we see one zero Y value
    lastNonZeroY = Math.min(series[i].data.length - 1, lastNonZeroY + 1);
    if (-1 === zoomEndX) {
      zoomEndX = series[i].data[lastNonZeroY].x;
    } else {
      zoomEndX = Math.max(series[i].data[lastNonZeroY].x, zoomEndX);
    }
  }
  return zoomEndX;
};

export const calcZoomNonZero = (series: Series[]): number[] | undefined => {
  // From the start, find the last X value in a row that has a zero Y value so we can start our domain from there.
  const zoomStartX = trimStart(series);

  // Find the first X value going backwards from the end that has a zero Y value so we can end our domain from there.
  const zoomEndX = trimEnd(series);

  if (-1 === zoomStartX || -1 === zoomEndX) {
    // They will both be -1.
    // Nothing to trim.
    return undefined;
  }

  // Trim
  return [zoomStartX, zoomEndX];
};

// Function that uses a passed in comparison function to find min/max DataPoint y.
export const compareFuncY = (
  compareFunc: (...values: number[]) => number,
  data: DataPoint[]
): DataPoint | undefined => {
  if (0 === data.length) {
    return undefined;
  }

  return data.reduce((prev: DataPoint, cur: DataPoint) => {
    return prev.y === compareFunc(prev.y, cur.y) ? prev : cur;
  }, data[0]);
};

export const genYAxisOptions = (
  yDomain: YDomain,
  yDomainIdx: number,
  multiAxis: boolean,
  series: Series[] | undefined
): ApexYAxis => {
  let color = "#000000";
  let axisTitle = undefined;
  const seriesIdx = yDomain.seriesIdx;
  if (multiAxis && 0 <= seriesIdx) {
    color = defaultColors[seriesIdx];
    axisTitle =
      series && series[seriesIdx] ? series[seriesIdx].name : undefined;
  }
  return {
    show: 0 === yDomainIdx || 0 <= seriesIdx,
    min: yDomain.min,
    max: yDomain.max,
    decimalsInFloat: 0,
    opposite: 1 === yDomainIdx % 2,
    axisBorder: {
      show: multiAxis,
      color: color,
    },
    labels: {
      style: {
        colors: color,
      },
      formatter: (val: number): string => {
        return yDomain.valueFormatter(val);
      },
    },
    title: {
      text: axisTitle,
      style: {
        color: color,
      },
    },
  };
};

export const PeriodicChart: React.FC<Props> = (props) => {
  const {
    yDomains,
    curves,
    loading = false,
    showMax,
    showMin,
    zoomNonZero,
    bar,
    events,
    title,
  } = props;
  let { xDomain, series } = props;

  const classes = useStyles(props);

  const hasData = series?.some((x) => x.data.length > 0);

  let yAxisAnno = Array<unknown>();
  if (series) {
    // Get the hour and minutes as a locale string
    const toTimeFromUnixMilliseconds = (milli: number): string => {
      const dateWithouthSecond = new Date(milli);
      return dateWithouthSecond.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    // Function that uses a passed in comparison function to find min/max DataPoint y for annotating.
    const genYAnno = (
      series: Series[],
      compareFunc: (...values: number[]) => number
    ): Array<unknown> => {
      return series.map((s, i) => {
        const anno = compareFuncY(compareFunc, s.data);
        if (undefined === anno) {
          return {};
        }
        const yAxisIndex = i % 2;
        return {
          y: anno.y,
          borderColor: defaultColors[i],
          yAxisIndex,
          label: {
            position: yAxisIndex ? "right" : "left",
            textAnchor: yAxisIndex ? "end" : "start",
            offsetX: yAxisIndex ? -8 : 8,
            offsetY: showMin ? 16 : -3,
            borderColor: defaultColors[i],
            style: {
              color: "#fff",
              background: defaultColors[i],
            },
            text:
              yDomains[i].valueFormatter(anno.y) +
              " @ " +
              toTimeFromUnixMilliseconds(anno.x),
          },
        };
      });
    };

    // Calculate max/min annotations. Only allow one to prevent possible UI overlapping.
    // If more that one is needed, the annotation style will need to be revised.
    if (hasData && (showMax || showMin)) {
      yAxisAnno = genYAnno(series, showMax ? Math.max : Math.min);
    }

    // charting package uses UTC for all timestamps.
    // whilst there is a option (datetimeUTC) to display in local time, it doesn't
    // work on all browsers; work-around is to determine the local timezone
    // UTC offset and manually apply it to all timestamps.
    const utcOffsetLocal = moment
      .duration(moment().utcOffset(), "minute")
      .asMilliseconds();
    series = series.map((s) => ({
      ...s,
      data: s.data.map((dp) => ({
        x: dp.x + utcOffsetLocal,
        y: dp.y,
      })),
    }));

    // Optional determine initial zoom to ignore zero values at the start/end of the day.
    if (zoomNonZero && series) {
      const newXDomain = calcZoomNonZero(series);
      if (undefined !== newXDomain) {
        xDomain = newXDomain;
      }
    }
  }

  const multiAxis = 1 < yDomains.length;
  const yAxisOptions = yDomains.map((d, i) => {
    return genYAxisOptions(d, i, multiAxis, series);
  });

  // Events => annotations
  let xAxisAnno = Array<unknown>();

  if (events) {
    // charting package uses UTC for all timestamps.
    // whilst there is a option (datetimeUTC) to display in local time, it doesn't
    // work on all browsers; work-around is to determine the local timezone
    // UTC offset and manually apply it to all timestamps.
    const utcOffsetLocal = moment
      .duration(moment().utcOffset(), "minute")
      .asMilliseconds();

    if (events && events.length > 0) {
      xAxisAnno = events.map((dp) => {
        return {
          x: dp.x + utcOffsetLocal,
          borderColor: dp.color, // FIXME: Map the event type to a colour.
          fillColor: dp.color, // FIXME: Map the event type to a colour.
          label: {
            borderColor: dp.color, // FIXME: Map the event type to a colour.
            text: dp.label,
            position: "bottom",
            style: {
              background: dp.color,
              color: "#FFF",
            },
          },
        };
      });
    }
  }

  return (
    <div className={classes.root}>
      {loading && <LoadingSpinner />}
      {!loading && !hasData && "No data available!"}
      {!loading && hasData && (
        <AutoSizer>
          {({ width, height }: Size): JSX.Element => (
            <Chart
              width={width}
              height={height}
              type={bar ? "bar" : "line"}
              options={{
                stroke: {
                  curve: curves,
                },
                title: { text: title },
                colors: defaultColors,
                tooltip: {
                  y: {
                    formatter: (
                      val: number,
                      series: {
                        series: unknown;
                        seriesIndex: number;
                        dataPointIndex: number;
                      }
                    ): string => {
                      return yDomains[series.seriesIndex].valueFormatter(val);
                    },
                  },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                      position: "top",
                    },
                  },
                },
                xaxis: {
                  type: "datetime",
                  min: xDomain?.[0],
                  max: xDomain?.[1],
                },
                yaxis: yAxisOptions,
                annotations: {
                  xaxis: xAxisAnno,
                  yaxis: yAxisAnno,
                },
                chart: {
                  toolbar: {
                    export: {
                      csv: {
                        headerCategory: "Time",
                        dateFormatter(timestamp: string): string {
                          const time = moment.utc(timestamp);
                          return time.format("YYYY-MM-DD HH:mm:ss");
                        },
                      },
                    },
                  },
                },
              }}
              series={series}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};
