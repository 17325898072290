import React, { useEffect, useState } from "react";
import registerServiceWorker from "./registerServiceWorker";

export const ServiceWorkerContext = React.createContext<{
  updateAvailable: boolean;
}>({
  updateAvailable: false,
});

let refreshing = false;

export const ServiceWorkerProvider: React.FC = ({ children }) => {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  // register service worker
  useEffect(() => {
    registerServiceWorker({
      onNewContentAvailable: () => {
        setUpdateAvailable(true);
      },
      onNewContent: () => {
        if (refreshing) return;
        window.location.reload();
        refreshing = true;
      },
    });
  }, []);

  // wrap children in service work context provider
  return (
    <ServiceWorkerContext.Provider value={{ updateAvailable }}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export const useServiceWorker = (): {
  updateAvailable: boolean;
} => React.useContext(ServiceWorkerContext);
