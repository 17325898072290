import React from "react";
import PaginatedSearchList from "../../components/PaginatedSearchList";
import {
  RegisteredDevice,
  getDeviceConfigVersion,
  unixTimeHumanReadable,
  ListingResult,
} from "../../api/connx";
import {
  renderTable,
  TableRow,
  TableRowAction,
} from "../../components/PaginatedListTableRenderer";
import { ListRendererProps } from "../../components/PaginatedList";

interface MyRowProps {
  item: RegisteredDevice;
}

const MyRow = ({ item, ...childProps }: MyRowProps): JSX.Element => {
  const selectedConfigVersion = getDeviceConfigVersion(item);

  return (
    <TableRow item={item} {...childProps}>
      <div>{item.manufacturer}</div>
      <div>{item.name}</div>
      <div>{item.desc}</div>
      <div>{item.defaultConfig ? item.defaultConfig.name : ""}</div>
      <div>
        {selectedConfigVersion
          ? unixTimeHumanReadable(selectedConfigVersion.version)
          : ""}
      </div>
    </TableRow>
  );
};

const TableRenderer = renderTable(MyRow);

const MyTableRenderer = (
  props: ListRendererProps<RegisteredDevice>
): JSX.Element => (
  <TableRenderer {...props}>
    <div>Manufacturer</div>
    <div>Name</div>
    <div>Description</div>
    <div>Default configuration</div>
    <div>Default configuration version</div>
    <div></div>
  </TableRenderer>
);

interface RegisteredDeviceListProps {
  actions?: TableRowAction<RegisteredDevice>[];
  dataSource: ({
    filter,
    nextKey,
  }: {
    filter: string;
    nextKey?: string;
  }) =>
    | Promise<ListingResult<RegisteredDevice>>
    | ListingResult<RegisteredDevice>;
  refresh?: string | number;
}

const RegisteredDevicesList = ({
  dataSource,
  ...childProps
}: RegisteredDeviceListProps): JSX.Element => (
  <PaginatedSearchList
    getItems={dataSource}
    ListRenderer={MyTableRenderer}
    {...childProps}
  />
);

export default RegisteredDevicesList;
