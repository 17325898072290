import React from "react";
import "./NotFound.css";

const NotFound = () => (
  <div className="NotFound">
    <h3>Sorry, page not found!</h3>
  </div>
);

NotFound.displayName = "NotFound";

export default NotFound;
