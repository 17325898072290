import React, { useEffect, useState } from "react";
import { CircularProgress, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      margin: "88px auto",
    },
  })
);

const Loading = ({ delay = 1000 }: { delay?: number }): JSX.Element => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return (): void => {
      clearTimeout(timeout);
    };
  });
  return (
    <div
      className={classes.loader}
      hidden={!show}
      data-testid="loading-progress"
    >
      <CircularProgress />
    </div>
  );
};

export const LoadingSpinner = Loading;

export default Loading;
