import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { anyGranted } from "../../../user/user";
import RVModelList from "./RVModelList";
import RVModelOrderList from "./rvmodelorderlist/RVModelOrderList";
import RVModelOrderDetails, {
  RVModelOrderFormValues,
} from "./RVModelOrderDetails";
import RVModelDetails, { RVModelFormValues } from "./RVModelDetails";
import { RVModelClient, RVModelOrderClient } from "../../../api/api";
import { RBACPermission } from "../../../user/rbac";
import { PageContainer } from "../../../components";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Dashboard from "./Dashboard";
import Export from "./Export";
import { useAnchor } from "../../../helpers/useAnchor";
import { ApolloProvider } from "@apollo/react-hooks";
import { ConnXClient } from "../../../api/apolloClient";
import { useUser } from "../../../userContext";

async function handleCreateModel(v: RVModelFormValues): Promise<void> {
  const r = await RVModelClient.createRVModel({
    name: v.name,
    desc: v.desc,
  });
  alert("Created model '" + r.name + "'.");
}

async function handleCreateOrder(v: RVModelOrderFormValues): Promise<void> {
  if (!v.model) {
    throw new Error("Model not selected");
  }
  const r = await RVModelOrderClient.createRVOrder({
    groupID: v.model.docID,
    name: v.name,
    desc: v.desc,
    serialNumber: v.serialNumber,
  });
  alert("Created order '" + r.name + "'.");
}

interface Props {
  hasPermission: (p: RBACPermission) => boolean;
}

type Anchors = "edit" | "dash" | "export";

const RVModelView: React.FC<Props> = (props) => {
  const { hasPermission = anyGranted } = props;
  const { user } = useUser();

  const { anchor, setAnchor } = useAnchor<Anchors>("dash");

  const viewNames: Record<string, string> = {
    edit: "Edit",
    dash: "Dashboard",
    export: "Export",
  };

  const showEdit = (): boolean =>
    hasPermission("rv_model_read") ||
    hasPermission("rv_model_create") ||
    hasPermission("rv_order_read") ||
    hasPermission("rv_order_create");

  if (anchor === "edit" && !showEdit()) {
    setAnchor("dash");
    return <div />;
  }

  return (
    <>
      <PageContainer
        title="Models and orders"
        view={viewNames[anchor]}
        drawerChildren={
          <>
            <List>
              {showEdit() && (
                <ListItem button onClick={(): void => setAnchor("edit")}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Edit"} />
                </ListItem>
              )}
              <ListItem button onClick={(): void => setAnchor("dash")}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItem>
              {user.features.hasFeature("web.dcx.export") && (
                <ListItem button onClick={(): void => setAnchor("export")}>
                  <ListItemIcon>
                    <CloudDownloadIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Export"} />
                </ListItem>
              )}
            </List>
          </>
        }
      >
        {anchor === "edit" && (
          <Tabs id="device-configuration-tabs">
            {hasPermission("rv_model_read") && (
              <Tab eventKey="list-rv-models" title="Models">
                <br />
                <RVModelList />
              </Tab>
            )}
            {hasPermission("rv_model_create") && (
              <Tab eventKey="create-rv-model" title="New model">
                <br />
                <RVModelDetails onSubmit={handleCreateModel} />
              </Tab>
            )}
            {hasPermission("rv_order_read") && (
              <Tab eventKey="list-rv-model-orders" title="Orders">
                <br />
                <RVModelOrderList />
              </Tab>
            )}
            {hasPermission("rv_order_create") && (
              <Tab eventKey="create-rv-model-order" title="New order">
                <br />
                <RVModelOrderDetails
                  onSubmit={handleCreateOrder}
                  canEditModel={true}
                  canEditSN={true}
                  keepSelectedModelOnClear={true}
                />
              </Tab>
            )}
          </Tabs>
        )}
        {anchor === "dash" && <Dashboard />}
        {anchor === "export" && user.features.hasFeature("web.dcx.export") && (
          <ApolloProvider client={ConnXClient}>
            <Export />
          </ApolloProvider>
        )}
      </PageContainer>
    </>
  );
};

export default RVModelView;
