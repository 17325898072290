import React, { FunctionComponent } from "react";
import { useCallback } from "react";
import { Dropdown } from "react-bootstrap";

export interface RVRowActionsProps {
  getItemName(): string;
  onDelete?: () => Promise<boolean> | boolean;
  onDeleted?: () => void;
  onEdit?: () => void;
}

const RVRowActions: FunctionComponent<RVRowActionsProps> = ({
  getItemName,
  onDelete,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDeleted = (): void => {},
  onEdit,
  children,
}) => {
  const handleDelete = useCallback(async () => {
    const n = getItemName();
    const r = window.prompt(
      `You are about to delete the item named '${n}'. Enter the name below to confirm.`
    );
    if (r !== n) {
      alert("Cancelled");
      return;
    }
    try {
      const defDelete = (): boolean => {
        return false;
      };
      const del = onDelete || defDelete;
      if (await del()) {
        alert("Deleted");
        onDeleted();
      } else {
        alert("Failed to delete");
      }
    } catch (e) {
      console.log(e);
      alert("Failed to delete");
    }
  }, [getItemName, onDelete, onDeleted]);

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" />
      <Dropdown.Menu>
        <Dropdown.Item
          key="0"
          disabled={!onEdit}
          onClick={(): void => {
            onEdit && onEdit();
          }}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item key="1" disabled={!onDelete} onClick={handleDelete}>
          Delete
        </Dropdown.Item>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RVRowActions;
