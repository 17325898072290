import React, { useCallback } from "react";
import PaginatedSearchList from "../../components/PaginatedSearchList";
import {
  TableRow,
  renderTable,
} from "../../components/PaginatedListTableRenderer";
import { ListFirmwareOutput } from "../../api/connx";
import { ListRendererProps } from "../../components/PaginatedList";

interface FlattenedFirmware {
  id: string;
  manufacturer: string;
  device: string;
  version: string;
}

interface MyRowProps {
  item: FlattenedFirmware;
}

const MyRow = ({ item, ...childProps }: MyRowProps): JSX.Element => (
  <TableRow item={item} {...childProps}>
    <div>{item.manufacturer}</div>
    <div>{item.device}</div>
    <div>{item.version}</div>
  </TableRow>
);

const TableRenderer = renderTable(MyRow);

const MyTableRenderer = (
  props: ListRendererProps<FlattenedFirmware>
): JSX.Element => (
  <TableRenderer {...props}>
    <div>Manufacturer</div>
    <div>Device</div>
    <div>Version</div>
    <div></div>
  </TableRenderer>
);

interface FirmwareListProps {
  getFirmware: (
    filter?: string
  ) => Promise<ListFirmwareOutput> | ListFirmwareOutput;
}

const FirmwareList = ({
  getFirmware,
  ...rest
}: FirmwareListProps): JSX.Element => {
  const apiWrapped = useCallback(
    async ({ filter }: { filter: string }) => {
      const r = await getFirmware(filter);
      return {
        items: r.fw
          ? r.fw.flatMap((fw) =>
              fw.versions.map((v) => {
                return {
                  id: fw.id + "@" + v.version,
                  manufacturer: fw.manufacturer,
                  device: fw.device,
                  version: v.version,
                };
              })
            )
          : ([] as FlattenedFirmware[]),
      };
    },
    [getFirmware]
  );

  return (
    <PaginatedSearchList
      getItems={apiWrapped}
      ListRenderer={MyTableRenderer}
      {...rest}
    />
  );
};

export default FirmwareList;
