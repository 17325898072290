import { UserClient } from "./apolloClient";
import gql from "graphql-tag";
import { CheckGraphQLErrorForCode, ThrowGraphQLError } from "../graphql/errors";

const fragments = {
  userGroupFragment: gql`
    fragment UserGroup on UserGroup {
      id
      revision
      name
      email
      desc
    }
  `,
};

export interface CreateUserGroupInput {
  name: string;
  email: string;
  desc: string;
}

export interface UserGroup {
  id: string;
  revision: number;
  name: string;
  email: string;
  desc: string;
}

const CreateUserGroup = async ({
  name,
  email,
  desc,
}: CreateUserGroupInput): Promise<UserGroup> => {
  const createUserGroup = gql`
    mutation createUserGroup($input: CreateUserGroupInput!) {
      createUserGroup(input: $input) {
        clientMutationId
      }
    }
  `;

  const r = await UserClient.mutate({
    mutation: createUserGroup,
    variables: {
      input: {
        name: name,
        email: email,
        description: desc,
        clientMutationId: "",
      },
    },
  });
  return r.data.createUserGroup.userGroup;
};

export interface GetUserGroupOutput {
  UserGroup?: UserGroup;
}

const GetUserGroup = async (id: string): Promise<GetUserGroupOutput> => {
  const userGroupQuery = gql`
    query UserGroup($id: ID!) {
      UserGroup(id: $id) {
        ...UserGroup
      }
    }
    ${fragments.userGroupFragment}
  `;

  const r = await UserClient.query({
    query: userGroupQuery,
    variables: {
      id: id,
    },
  });

  return { UserGroup: r.data.UserGroup };
};

export interface UpdateUserGroupInput {
  id: string;
  revision: number;
  name?: string;
  desc?: string;
  email?: string;
}

export interface UpdateUserGroupOutput {
  userGroup: UserGroup;
}

const UpdateUserGroup = async ({
  id,
  revision,
  name,
  desc,
  email,
}: UpdateUserGroupInput): Promise<UpdateUserGroupOutput> => {
  const updateUserGroup = gql`
    mutation updateUserGroup($input: UpdateUserGroupInput!) {
      updateUserGroup(input: $input) {
        group {
          ...UserGroup
        }
        clientMutationId
      }
    }
    ${fragments.userGroupFragment}
  `;

  try {
    const r = await UserClient.mutate({
      mutation: updateUserGroup,
      variables: {
        input: {
          id: id,
          revision: revision,
          clientMutationId: "",
          name: name,
          email: email,
          description: desc,
        },
      },
    });
    return { userGroup: r.data.updateUserGroup.group };
  } catch (e) {
    console.log(e);
    if (CheckGraphQLErrorForCode(e, "revision_error")) {
      throw new Error(
        "User group data has changed since loaded, please try again"
      );
    } else {
      throw new Error("Failed to save");
    }
  }
};

interface UserGroupFilter {
  name: string;
  value: string;
}

export interface GetUserGroupsInput {
  filters?: UserGroupFilter[];
  nextKey?: string;
}

export interface GetUserGroupsOutput {
  items: UserGroup[];
  nextKey?: string;
}

const GetUserGroups = async (
  p: GetUserGroupsInput
): Promise<GetUserGroupsOutput> => {
  const userGroups = gql`
    query UserGroups($input: GetUserGroupsInput!) {
      UserGroups(input: $input) {
        groups {
          id
          name
          email
          desc
        }
        nextKey
      }
    }
  `;

  // define the query input
  const input = {
    maxResults: 10,
    nextKey: p.nextKey,
    filters: p.filters,
  };

  const r = await UserClient.query({
    query: userGroups,
    variables: { input: input },
  });

  return {
    items: r.data.UserGroups.groups,
    nextKey: r.data.UserGroups.nextKey,
  };
};

const DeleteUserGroup = async (id: string): Promise<void> => {
  const deleteUserGroup = gql`
    mutation deleteUserGroup($input: DeleteUserGroupInput!) {
      deleteUserGroup(input: $input) {
        clientMutationId
      }
    }
  `;

  await UserClient.mutate({
    mutation: deleteUserGroup,
    variables: {
      input: {
        id: id,
        clientMutationId: "",
      },
    },
  });
};

const addUser = async (userId: string, groupId: string): Promise<void> => {
  const addUserToGroup = gql`
    mutation addUserToGroup($input: AddUserToGroupInput!) {
      addUserToGroup(input: $input) {
        clientMutationId
      }
    }
  `;
  try {
    await UserClient.mutate({
      mutation: addUserToGroup,
      variables: {
        input: {
          user: userId,
          group: groupId,
          clientMutationId: "",
        },
      },
    });
  } catch (e) {
    ThrowGraphQLError(
      e,
      "already_in_group",
      "User is already assigned to a group"
    );
  }
};

const removeUser = async (userId: string, groupId: string): Promise<void> => {
  const removeUserFromGroup = gql`
    mutation removeUserFromGroup($input: RemoveUserFromGroupInput!) {
      removeUserFromGroup(input: $input) {
        clientMutationId
      }
    }
  `;
  try {
    await UserClient.mutate({
      mutation: removeUserFromGroup,
      variables: {
        input: {
          user: userId,
          group: groupId,
          clientMutationId: "",
        },
      },
    });
  } catch (e) {
    ThrowGraphQLError(e, "already_in_group", "User is not part of this group");
  }
};

export {
  CreateUserGroup,
  GetUserGroup,
  GetUserGroups,
  UpdateUserGroup,
  DeleteUserGroup,
  addUser,
  removeUser,
};
