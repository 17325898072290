import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FormResult, GenOverrideForm } from "./GenOverrideForm";
import { Loading, ErrorView } from "../../../../components";

interface GenOverrideProps {
  orderID: string;
  open: boolean;
  onClose: () => void;
  onUpdate: (data: FormResult) => void;
  onClear: () => void;
}

export interface QueryResult {
  remoteGeneratorSOCOverride: {
    minSOC: number | null;
    expiry: string | null;
  };
}

export const OVERRIDE_QUERY = gql`
  query remoteGeneratorSOCOverride($orderID: ID!) {
    remoteGeneratorSOCOverride(orderID: $orderID) {
      minSOC
      expiry
    }
  }
`;

export const GenOverride: React.FC<GenOverrideProps> = ({
  orderID,
  open,
  onClose,
  onUpdate,
  onClear,
}) => {
  // Loading initial values from the backend.
  const { loading, data, error } = useQuery<QueryResult>(OVERRIDE_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      orderID,
    },
  });

  return (
    <React.Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          Generator remote start override
        </DialogTitle>

        {loading && (
          <React.Fragment>
            <DialogContent dividers>
              <Loading delay={0} />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={onClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </React.Fragment>
        )}

        {/* error */}
        {error && <ErrorView />}

        {!loading && data && (
          <div data-testid="gen-override-form">
            <GenOverrideForm
              queryResult={data.remoteGeneratorSOCOverride}
              onClose={onClose}
              onUpdate={onUpdate}
              onClear={onClear}
            />
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
};
