import React from "react";
import { FieldProps, Field, ErrorMessage } from "formik";
import { Form } from "react-bootstrap";

interface FormGroupProps {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
}

const FormInput = ({
  field,
  form: { touched, errors, isValid },
  ...props
}: FieldProps): JSX.Element => (
  <>
    <Form.Control
      {...field}
      {...props}
      isValid={(touched[field.name] || isValid) && !errors[field.name]}
    />
  </>
);

const FormGroup = ({
  name,
  label,
  placeholder,
  type,
}: FormGroupProps): JSX.Element => (
  <Form.Group>
    <Form.Label>{label}</Form.Label>
    <Field
      name={name}
      component={FormInput}
      placeholder={placeholder}
      type={type}
    />
    <ErrorMessage name={name} />
  </Form.Group>
);

export default FormGroup;
