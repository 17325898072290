import React, { useCallback } from "react";
import AsyncSelect from "react-select/async";
import { GetUserGroups, UserGroup } from "../../api/usergroup";
import { ValueType } from "react-select";

export const userGroupToOption = (v: UserGroup): UserGroupOption => ({
  value: v.id,
  label: v.name,
  data: v,
});

export const loadUserGroupOptions = async (
  inputValue: string
): Promise<UserGroupOption[]> => {
  try {
    type filter = {
      name: string;
      value: string;
    };
    type params = {
      filters?: filter[];
    };
    let p: params = {};
    if (inputValue !== "") {
      p = {
        filters: [{ name: "name", value: inputValue }],
        ...p,
      };
    }
    const r = await GetUserGroups(p);
    return r.items.map(userGroupToOption);
  } catch (e) {
    return [];
  }
};

export interface UserGroupOption {
  value: string;
  label: string;
  data?: UserGroup;
}

interface UserGroupSelectionProps {
  disabled?: boolean;
  userGroupOption?: UserGroupOption;
  onChange?: (u?: UserGroupOption) => void;
  loadOptions?: (inputValue: string) => Promise<UserGroupOption[]>;
}

const UserGroupSelection: React.FC<UserGroupSelectionProps> = ({
  userGroupOption,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (): void => {},
  disabled = false,
  loadOptions = loadUserGroupOptions,
}) => {
  const handleChange = useCallback(
    (v: ValueType<UserGroupOption>) => {
      const u = v as UserGroupOption;
      onChange(u);
    },
    [onChange]
  );
  return (
    <AsyncSelect
      defaultOptions={true}
      cacheOptions
      onChange={handleChange}
      loadOptions={loadOptions}
      isDisabled={disabled}
      placeholder={"Search user groups"}
      value={userGroupOption}
    />
  );
};

export default UserGroupSelection;
