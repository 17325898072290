import RVModelAPI from "./connx/rvmodel";
import RVModelOrderAPI from "./connx/rvmodelorder";
import SystemEventsAPI from "./connx/systemevents";
import { ConnXEventClient } from "./apolloClient";

const RVModelClient = new RVModelAPI();
const RVModelOrderClient = new RVModelOrderAPI();
const SystemEventsClient = new SystemEventsAPI(ConnXEventClient);

export { RVModelClient, RVModelOrderClient, SystemEventsClient };
