import React from "react";
import { skipWaiting } from "../registerServiceWorker";
import { Toast, Button } from "react-bootstrap";
import { useServiceWorker } from "../useServiceWorker";

export const RefreshToast: React.FC = () => {
  const { updateAvailable } = useServiceWorker();
  return (
    <Toast show={updateAvailable}>
      <Toast.Body>
        <span>A new version of the app is available</span>
        <Button
          variant="link"
          onClick={(): void => {
            skipWaiting();
          }}
        >
          Update
        </Button>
      </Toast.Body>
    </Toast>
  );
};
