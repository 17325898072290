import React, { useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import PaginatedList, {
  ListRendererProps,
} from "../../components/PaginatedList";
import AddUserGroupUser from "./AddUserGroupUser";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { User, GetUsersOutput } from "../../api/user";
import {
  renderTable,
  RowRendererProps,
} from "../../components/PaginatedListTableRenderer";

interface UserRowProps extends RowRendererProps<User> {
  onDelete?: (u: User) => void;
  canDelete?: (u: User) => boolean;
}

const UserRow: React.FC<UserRowProps> = ({
  item,
  onDelete,
  canDelete = (): boolean => false,
}) => {
  const handleDelete = useCallback(() => {
    onDelete?.(item);
  }, [onDelete, item]);

  return (
    <tr>
      <td>{item.email}</td>
      <td className="action">
        <Button
          disabled={!canDelete(item)}
          variant="danger"
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};

const TableRenderer = renderTable(UserRow);

const UserTableRenderer: React.FC<ListRendererProps<User>> = (props) => (
  <TableRenderer {...props}>
    <div>Email</div>
    <div></div>
  </TableRenderer>
);

interface EditUserGroupUsersProps {
  removeUser: (id: string) => Promise<void> | void;
  getGroupUsers: (nextKey?: string) => Promise<GetUsersOutput> | GetUsersOutput;
  findUsers: ({
    filter,
  }: {
    filter?: string;
  }) => Promise<GetUsersOutput> | GetUsersOutput;
  addUser: (userId: string) => Promise<void> | void;
}

const EditUserGroupUsers: React.FC<EditUserGroupUsersProps> = ({
  getGroupUsers,
  removeUser,
  findUsers,
  addUser,
}) => {
  const [refreshToken, setRefreshToken] = useState<number>(0);
  const [removeUserId, setRemoveUserId] = useState<User | null>(null);

  const removeUserWrapped = (user: User): void => {
    setRemoveUserId(user);
  };
  const addUserWrapped = async (userId: string): Promise<void> => {
    await addUser(userId);
    setRefreshToken(Math.random());
  };
  const onConfirmDeleteUser = async ({
    cancelled,
  }: {
    cancelled: boolean;
  }): Promise<void> => {
    try {
      if (false === cancelled && removeUserId) {
        await removeUser(removeUserId.id);
        setRefreshToken((cur) => cur + 1);
      }
    } catch (e) {
      console.log(e);
      alert(e.message);
    } finally {
      setRemoveUserId(null);
    }
  };

  const userRowProps = {
    onDelete: removeUserWrapped,
    canDelete: (): boolean => true,
  };

  return (
    <>
      <PaginatedList
        {...userRowProps}
        dataSource={getGroupUsers}
        ListRenderer={UserTableRenderer}
        noItemsMessage={"Group does not have any users"}
        refreshToken={refreshToken.toString()}
      />
      <AddUserGroupUser findUsers={findUsers} addUser={addUserWrapped} />
      <ConfirmationDialog
        show={removeUserId !== null}
        onClose={onConfirmDeleteUser}
        caption={`Remove user`}
        message={`Are you sure you want to remove '${
          removeUserId ? removeUserId.email : ""
        }' from the group?`}
      />
    </>
  );
};

export default EditUserGroupUsers;
