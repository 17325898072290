import { ConnXClient as GraphQLClient } from "./apolloClient";
import gql from "graphql-tag";

const LIST_QUERY = gql`
  query($input: ListRegisteredDevicesInput!) {
    listRegisteredDevices(input: $input) {
      devices {
        id
        manufacturer
        name
        desc
        revision
        defaultConfig {
          name
          desc
          versions {
            docID
            version
          }
        }
        defaultConfigVersionID
      }
      nextKey
    }
  }
`;

const NEW_DEVICE = gql`
  mutation($input: CreateRegisteredDeviceInput!) {
    createRegisteredDevice(input: $input) {
      id
      manufacturer
      name
      desc
      revision
    }
  }
`;

const DELETE_DEVICE = gql`
  mutation($id: ID!) {
    deleteRegisteredDevice(id: $id)
  }
`;

const LIST_FW = gql`
  query($input: ListFirmwareDevicesInput!) {
    listFirmwareDevices(input: $input) {
      fw {
        id
        manufacturer
        device
        versions {
          version
        }
      }
    }
  }
`;

const NEW_DEVICE_CFG = gql`
  mutation($input: CreateDeviceConfigInput!) {
    createDeviceConfig(input: $input) {
      deviceConfig {
        groupID
        docID
        deviceID
        name
        desc
      }
    }
  }
`;

const LIST_DEVICE_CFGS = gql`
  query($input: ListDeviceConfigsInput!) {
    listDeviceConfigs(input: $input) {
      deviceConfigs {
        groupID
        docID
        deviceID
        name
        desc
        device {
          id
          manufacturer
          name
          desc
        }
        versions {
          groupID
          docID
          version
          firmware {
            id
            manufacturer
            device
          }
          firmwareVersion {
            version
          }
        }
      }
      nextKey
    }
  }
`;

const GET_DEVICE_CFG = gql`
  query($groupID: ID!, $docID: ID!) {
    getDeviceConfig(groupID: $groupID, docID: $docID) {
      groupID
      docID
      deviceID
      name
      desc
      device {
        id
        name
        desc
      }
      versions {
        groupID
        version
        docID
        firmware {
          id
          manufacturer
          device
          versions {
            version
          }
        }
        firmwareVersion {
          version
        }
      }
    }
  }
`;

const VERSION_DEVICE_CFG = gql`
  mutation(
    $deviceConfigID: ID!
    $docID: ID!
    $input: VersionDeviceConfigInput!
  ) {
    versionDeviceConfig(
      deviceConfigID: $deviceConfigID
      docID: $docID
      input: $input
    ) {
      groupID
      docID
      firmware {
        id
        manufacturer
        device
      }
      firmwareVersion {
        version
      }
      params {
        name
        value
      }
    }
  }
`;

export interface RegisterNewDeviceInput {
  manufacturer: string;
  name: string;
  desc: string;
}

export interface FirmwareVersion {
  version: string;
}

export interface Firmware {
  id: string;
  manufacturer: string;
  device: string;
  versions: FirmwareVersion[];
}

export interface ListFirmwareOutput {
  fw: Firmware[];
}

export interface CreateNewDeviceConfigInput {
  name: string;
  desc: string;
  deviceid: string;
  fwdir: string;
  fwver: string;
}

export interface RegisteredDevice {
  id: string;
  manufacturer: string;
  name: string;
  desc: string;
  revision: number;
  defaultConfig: DeviceConfiguration;
  defaultConfigVersionID: string;
}

function toDateFromUnixSeconds(sec: number): Date {
  const toMilliseconds = 1000;
  return new Date(sec * toMilliseconds);
}

export function getDeviceConfigVersion(
  rd: RegisteredDevice
): DeviceConfigurationVersioned | undefined {
  return rd.defaultConfig
    ? rd.defaultConfig.versions.find(
        (i) => i.docID === rd.defaultConfigVersionID
      )
    : undefined;
}

export function unixTimeHumanReadable(version: number): string {
  return toDateFromUnixSeconds(version).toLocaleString();
}

export interface DeviceConfigurationVersioned {
  groupID: string;
  docID: string;
  version: number;
  firmware: Firmware;
  firmwareVersion: FirmwareVersion;
}

export interface DeviceConfiguration {
  groupID: string;
  docID: string;
  name: string;
  desc: string;
  device: RegisteredDevice;
  versions: DeviceConfigurationVersioned[];
}

export interface ListingResult<TItem> {
  items: TItem[];
  nextKey?: string;
}

export interface VersionDeviceConfigInput {
  fwver?: string;
  fwdir?: string;
}

export interface UpdateRegisteredDeviceInput {
  name?: string;
  desc?: string;
  manufacturer?: string;
  deviceConfigID?: string;
  deviceConfigVersionID?: string;
}

class ConnXClient {
  async createNewDeviceConfig(
    input: CreateNewDeviceConfigInput
  ): Promise<void> {
    await GraphQLClient.mutate({
      mutation: NEW_DEVICE_CFG,
      variables: {
        input: {
          deviceID: input.deviceid,
          name: input.name,
          desc: input.desc,
          fwdir: input.fwdir,
          fwver: input.fwver,
          params: [],
        },
      },
    });
  }

  async getDeviceConfig(
    groupID: string,
    docID: string
  ): Promise<DeviceConfiguration> {
    const r = await GraphQLClient.query({
      query: GET_DEVICE_CFG,
      variables: {
        groupID: groupID,
        docID: docID,
      },
    });
    return r.data.getDeviceConfig;
  }

  // given a device config and version id, make a copy of the DeviceConfigVersioned, updating it
  // with the given parameters.
  async versionDeviceConfig(
    deviceConfigID: string,
    versionID: string,
    params: VersionDeviceConfigInput
  ): Promise<any> {
    const r = await GraphQLClient.mutate({
      mutation: VERSION_DEVICE_CFG,
      variables: {
        deviceConfigID: deviceConfigID,
        docID: versionID,
        input: {
          fwdir: params.fwdir,
          fwver: params.fwver,
        },
      },
    });
    return r.data.versionDeviceConfig;
  }

  async listDeviceConfigurations(
    filter: string | null,
    nextKey?: string
  ): Promise<ListingResult<DeviceConfiguration>> {
    const r = await GraphQLClient.query({
      query: LIST_DEVICE_CFGS,
      variables: {
        input: {
          maxResults: 10,
          filter: filter,
          nextKey: nextKey,
        },
      },
    });
    return {
      items: r.data.listDeviceConfigs.deviceConfigs,
      nextKey: r.data.listDeviceConfigs.nextKey,
    };
  }

  async registerNewDevice(input: RegisterNewDeviceInput): Promise<void> {
    await GraphQLClient.mutate({
      mutation: NEW_DEVICE,
      variables: { input: input },
    });
  }

  async getRegisteredDevice(id: string): Promise<RegisteredDevice> {
    const GQL = gql`
      query($id: ID!) {
        getRegisteredDevice(id: $id) {
          id
          manufacturer
          name
          desc
          revision
          defaultConfig {
            docID
            deviceID
            name
            desc
            versions {
              version
              docID
            }
          }
          defaultConfigVersionID
        }
      }
    `;
    const r = await GraphQLClient.query({
      query: GQL,
      variables: { id: id },
    });
    return r.data.getRegisteredDevice;
  }

  // list all registered devices
  async listRegisteredDevices(
    filter: string,
    nextKey?: string
  ): Promise<ListingResult<RegisteredDevice>> {
    // define the query input
    const input = {
      maxResults: 10,
      nextKey: nextKey,
      filter: "",
    };
    if (null !== filter) {
      input.filter = filter;
    }

    const r = await GraphQLClient.query({
      query: LIST_QUERY,
      variables: { input: input },
    });

    return {
      items: r.data.listRegisteredDevices.devices,
      nextKey: r.data.listRegisteredDevices.nextKey,
    };
  }

  async updateRegisteredDevice(
    id: string,
    revision: number,
    changes: UpdateRegisteredDeviceInput
  ): Promise<RegisteredDevice> {
    const GQL = gql`
      mutation(
        $id: ID!
        $revision: Int!
        $input: UpdateRegisteredDeviceInput!
      ) {
        updateRegisteredDevice(id: $id, revision: $revision, input: $input) {
          id
          manufacturer
          name
          desc
          revision
        }
      }
    `;

    const r = await GraphQLClient.mutate({
      mutation: GQL,
      variables: {
        id: id,
        revision: revision,
        input: {
          name: changes.name,
          desc: changes.desc,
          manufacturer: changes.manufacturer,
          deviceConfigID: changes.deviceConfigID,
          deviceConfigVersionID: changes.deviceConfigVersionID,
        },
      },
    });

    return r.data.updateRegisteredDevice;
  }

  async deleteRegisteredDevice(id: string): Promise<void> {
    await GraphQLClient.mutate({
      mutation: DELETE_DEVICE,
      variables: { id: id },
    });
  }

  async listFirmware(filter?: string): Promise<ListFirmwareOutput> {
    const r = await GraphQLClient.query({
      query: LIST_FW,
      variables: { input: { filter: filter } },
    });
    return { fw: r.data.listFirmwareDevices.fw };
  }
}

const Client = new ConnXClient();

export default Client;
