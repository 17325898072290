import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

interface SuccessFailureToastyProps {
  open: boolean;
  success: boolean;
  successMessage: string;
  failureMessage: string;
  onClose?: () => void;
}

export const SuccessFailureToasty = ({
  open,
  success,
  successMessage,
  failureMessage,
  onClose,
}: SuccessFailureToastyProps): JSX.Element => {
  const handleSnackBarClosed = (
    event?: React.SyntheticEvent,
    reason?: string
  ): void => {
    if (reason === "clickaway") {
      return;
    }
    onClose?.();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleSnackBarClosed}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleSnackBarClosed}
        severity={success ? "success" : "error"}
      >
        {success ? successMessage : failureMessage}
      </MuiAlert>
    </Snackbar>
  );
};

interface SuccessFailureToastyHookResult {
  props: {
    open: boolean;
    success: boolean;
    onClose: () => void;
    successMessage?: string;
    failureMessage?: string;
  };
  setSuccess: (msg?: string) => void;
  setFailure: (msg?: string) => void;
}

export const useSuccessFailureToasty = (): SuccessFailureToastyHookResult => {
  const [updateResult, setUpdateResult] = React.useState<{
    open: boolean;
    success: boolean;
    successMessage?: string;
    failureMessage?: string;
  }>({
    open: false,
    success: true,
  });
  return {
    props: {
      ...updateResult,
      onClose: (): void => {
        setUpdateResult({ ...updateResult, open: false });
      },
    },
    setSuccess: (msg?: string): void => {
      setUpdateResult((p) => ({
        ...p,
        success: true,
        open: true,
        successMessage: msg,
      }));
    },
    setFailure: (msg?: string): void => {
      setUpdateResult((p) => ({
        ...p,
        success: false,
        open: true,
        failureMessage: msg,
      }));
    },
  };
};

export default SuccessFailureToasty;
