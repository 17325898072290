import { ConnXClient as GraphQLClient } from "../apolloClient";
import gql from "graphql-tag";

export interface RVModelOrder {
  groupID: string;
  docID: string;
  name: string;
  desc: string;
  revision: number;
  serialNumber: string;
  orderDate: number;
  provisioned: boolean;
  iotThingName?: string;
}

interface CreateRVModelOrderInput {
  groupID: string;
  name: string;
  desc: string;
  serialNumber: string;
}

interface ListRVOrdersInput {
  filterName?: string;
  nextKey?: string;
}

interface ListRVOrdersOutput {
  models: RVModelOrder[];
  nextKey?: string;
}

interface UpdateRVModelOrderInput {
  name?: string;
  desc?: string;
}

const fragments = {
  rvmodelorder: gql`
    fragment rvmodelorder on RVModelOrder {
      groupID
      docID
      name
      desc
      revision
      serialNumber
      orderDate
      provisioned
      iotThingName
    }
  `,
};

class RVModelOrderAPI {
  async get(modelID: string, orderID: string): Promise<RVModelOrder> {
    const GQL = gql`
      query($groupID: ID!, $docID: ID!) {
        getRVModelOrder(groupID: $groupID, docID: $docID) {
          ...rvmodelorder
        }
      }
      ${fragments.rvmodelorder}
    `;
    interface Output {
      getRVModelOrder: RVModelOrder;
    }
    const r = await GraphQLClient.query<Output>({
      query: GQL,
      variables: { groupID: modelID, docID: orderID },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.getRVModelOrder;
  }

  async update(
    order: RVModelOrder,
    changes: UpdateRVModelOrderInput
  ): Promise<RVModelOrder> {
    const GQL = gql`
      mutation(
        $groupID: ID!
        $docID: ID!
        $revision: Int!
        $input: UpdateRVModelOrderInput!
      ) {
        updateRVModelOrder(
          groupID: $groupID
          docID: $docID
          revision: $revision
          input: $input
        ) {
          ...rvmodelorder
        }
      }
      ${fragments.rvmodelorder}
    `;
    interface Output {
      updateRVModelOrder: RVModelOrder;
    }
    const r = await GraphQLClient.mutate<Output>({
      mutation: GQL,
      variables: {
        groupID: order.groupID,
        docID: order.docID,
        revision: order.revision,
        input: {
          name: changes.name,
          desc: changes.desc,
        },
      },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.updateRVModelOrder;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async delete(_modelID: string, _orderID: string): Promise<boolean> {
    throw new Error("not implemented");
  }

  async createRVOrder(input: CreateRVModelOrderInput): Promise<RVModelOrder> {
    const GQL = gql`
      mutation($input: CreateRVModelOrderInput!) {
        createRVModelOrder(input: $input) {
          ...rvmodelorder
        }
      }
      ${fragments.rvmodelorder}
    `;
    interface Output {
      createRVModelOrder: RVModelOrder;
    }
    const r = await GraphQLClient.mutate<Output>({
      mutation: GQL,
      variables: { input: input },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.createRVModelOrder;
  }

  async listRVOrders(
    modelID: string,
    params?: ListRVOrdersInput
  ): Promise<ListRVOrdersOutput> {
    const GQL = gql`
      query($groupID: ID!, $params: ListRVModelOrdersParams) {
        listRVModelOrders(groupID: $groupID, params: $params) {
          models {
            ...rvmodelorder
          }
          nextKey
        }
      }
      ${fragments.rvmodelorder}
    `;
    interface Output {
      listRVModelOrders: ListRVOrdersOutput;
    }
    const r = await GraphQLClient.query<Output>({
      query: GQL,
      variables: {
        groupID: modelID,
        params: params,
      },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.listRVModelOrders;
  }
}

export default RVModelOrderAPI;
