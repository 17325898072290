import { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { ApolloError, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export interface DataPoint {
  x: number;
  y: number;
}

const DCX_HISTORICAL_DATA_QUERY = gql`
  query dcxData(
    $orderID: ID!
    $names: [String!]!
    $start: String!
    $end: String!
  ) {
    dcxHistoricalData(
      orderID: $orderID
      input: {
        names: $names
        grouping: HOURLY
        periodStart: $start
        periodEnd: $end
      }
    ) {
      series {
        name
        data {
          time
          value
        }
      }
    }
  }
`;

interface DCXDataPoint {
  time: string;
  value: number;
}

interface DCXSeries {
  name: string;
  data: DCXDataPoint[];
}

interface DCXHistoricalDataQueryResult {
  dcxHistoricalData: {
    series?: DCXSeries[];
  };
}

export interface Series {
  name: string;
  data: DataPoint[];
}

export interface HistoricalDataResult {
  loading: boolean;
  error?: ApolloError;
  series?: Series[];
}

export function useHistoricalData(
  orderID: string,
  {
    seriesNames,
    start,
    end,
  }: { seriesNames: string[]; start: Moment; end: Moment }
): HistoricalDataResult {
  const [series, setSeries] = useState<Series[]>();

  // query backend
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery<DCXHistoricalDataQueryResult>(DCX_HISTORICAL_DATA_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      orderID,
      names: seriesNames,
      start: start.format(),
      end: end.format(),
    },
  });

  // handle query response
  useEffect(() => {
    if (queryData && queryData.dcxHistoricalData.series !== undefined) {
      setSeries(
        queryData.dcxHistoricalData.series.map((x) => ({
          name: x.name,
          data: x.data.map((y) => ({
            x: moment(y.time).valueOf(),
            y: y.value,
          })),
        }))
      );
    }
  }, [queryData]);

  // result
  return {
    loading: queryLoading,
    error: queryError,
    series: series,
  };
}
