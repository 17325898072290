import React, { useCallback } from "react";
import {
  DeviceConfiguration,
  VersionDeviceConfigInput,
  DeviceConfigurationVersioned,
} from "../../api/connx";
import Client from "../../api/connx";
import CreateDeviceConfiguration, {
  FormValues,
} from "./CreateDeviceConfiguration";
import { useModelLoader } from "../../helpers/hooks";
import { Loading } from "../../components";

type SaveDeviceConfigVersion = (
  deviceConfigID: string,
  versionID: string,
  params: VersionDeviceConfigInput
) => Promise<void> | void;

interface DeviceConfigurationEditorProps {
  config: DeviceConfiguration;
  versionID: string;
  onSubmit: SaveDeviceConfigVersion;
  onDone: () => void;
}

const DeviceConfigurationEditor = ({
  config,
  versionID,
  onDone,
  onSubmit,
}: DeviceConfigurationEditorProps): JSX.Element => {
  const handleSubmit = useCallback(
    async (v: FormValues) => {
      if (!v.fwdir) {
        throw new Error("firmware has not been selected ");
      }
      if (!v.fwver) {
        throw new Error("firmware version has not been selected ");
      }

      await onSubmit(config.docID, versionID, {
        fwdir: v.fwdir.value,
        fwver: v.fwver.value,
      });
      onDone();
    },
    [onDone, config, versionID, onSubmit]
  );

  const handleCancel = useCallback(() => {
    onDone();
  }, [onDone]);

  const version = ((): DeviceConfigurationVersioned | undefined => {
    return config.versions.find((x) => x.docID === versionID) || undefined;
  })();

  const initialValues: FormValues = {
    ...config,
    deviceid: {
      label: config.device.name,
      value: config.device.id,
      data: config.device,
    },
  };

  if (version) {
    initialValues.fwver = {
      label: version.firmwareVersion.version,
      value: version.firmwareVersion.version,
      data: version.firmwareVersion,
    };
    initialValues.fwdir = {
      label: version.firmware.id,
      value: version.firmware.id,
      data: version.firmware,
    };
  }

  return (
    <CreateDeviceConfiguration
      confirmActionLabel="Clone"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  );
};

interface DeviceConfigurationEditProps {
  groupID: string;
  cfgID: string;
  versionID: string;
  onError: (e: Error) => void;
  onDone: () => void;
  getDeviceConfig?: (
    groupID: string,
    docID: string
  ) => Promise<DeviceConfiguration> | DeviceConfiguration;
  onSubmit?: SaveDeviceConfigVersion;
}

const DeviceConfigurationEdit = ({
  groupID,
  cfgID,
  versionID,
  onError,
  onDone,
  getDeviceConfig = Client.getDeviceConfig,
  onSubmit = Client.versionDeviceConfig,
}: DeviceConfigurationEditProps): JSX.Element => {
  const handleLoad = useCallback(() => {
    return getDeviceConfig(groupID, cfgID);
  }, [groupID, cfgID, getDeviceConfig]);

  const [loading, config] = useModelLoader(handleLoad, onError);

  return (
    <>
      <div className="text-center page-header">
        <h1>ConnX Device Configuration</h1>
        <small>Copy and modify an existing configuration</small>
      </div>
      <div className="container">
        {loading ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <DeviceConfigurationEditor
            config={config}
            versionID={versionID}
            onDone={onDone}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </>
  );
};

export default DeviceConfigurationEdit;
