import React from "react";
import { DCXReports, DCXReportsConfig } from "./DCXReports";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useUser } from "../../../userContext";
import {
  ErrorView,
  useSuccessFailureToasty,
  SuccessFailureToasty,
  Loading,
} from "../../../components";

interface UserReportConfig {
  id: string;
}

interface ReportsConfigQueryOutput {
  reports: UserReportConfig[];
}

export const REPORTS_CONFIG_QUERY = gql`
  query userReportConfig {
    reports: userReportConfig {
      id
    }
  }
`;

interface UpdateUserReportConfigInput {
  userID: string;
  subscribed: boolean;
}

interface UpdateUserReportConfigOutput {
  id: string;
}

const REPORTS_CONFIG_MUTATE = gql`
  mutation updateUserReportConfig($subscribed: Boolean!) {
    reports: updateUserReportConfig(
      input: { subscribed: $subscribed, freq: freq_weekly }
    ) {
      id
    }
  }
`;

const Reports: React.FC<{}> = () => {
  const userCtx = useUser();

  // snackbar state
  const {
    props: toastyProps,
    setSuccess: setToastySuccess,
    setFailure: setToastyFailure,
  } = useSuccessFailureToasty();

  // graphql query to get current configuration
  const { loading, error, data } = useQuery<ReportsConfigQueryOutput>(
    REPORTS_CONFIG_QUERY,
    {
      fetchPolicy: "no-cache",
      skip: !userCtx.user.isAuthenticated,
    }
  );

  const [updateConfig] = useMutation<
    UpdateUserReportConfigOutput,
    UpdateUserReportConfigInput
  >(REPORTS_CONFIG_MUTATE, {});

  const handleSubmit = async (cfg: DCXReportsConfig): Promise<void> => {
    try {
      await updateConfig({
        variables: {
          userID: userCtx.user.id,
          subscribed: cfg.subscribed,
        },
      });
      setToastySuccess();
    } catch (e) {
      setToastyFailure();
      throw e;
    }
  };

  if (error) {
    return <ErrorView error={error} />;
  }
  if (loading) {
    return <Loading delay={0} />;
  }
  if (!data) {
    return <ErrorView error="No reports data!" />;
  }
  return (
    <>
      <DCXReports
        initialState={{
          subscribed: 0 < data.reports.length,
        }}
        onSubmit={handleSubmit}
      />
      <SuccessFailureToasty
        successMessage="Updated OK"
        failureMessage="Failed to update"
        {...toastyProps}
      />
    </>
  );
};

export default Reports;
