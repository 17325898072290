import React, { useContext, ReactNode } from "react";

export const OrderContext = React.createContext<{
  docID: string;
  isOwner: boolean;
  hasThing: boolean;
}>({
  docID: "",
  isOwner: false,
  hasThing: false,
});

export const OrderProvider = ({
  docID,
  isOwner,
  hasThing,
  children,
}: {
  docID: string;
  isOwner: boolean;
  hasThing: boolean;
  children?: ReactNode;
}): JSX.Element => {
  return (
    <OrderContext.Provider value={{ docID, isOwner, hasThing }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = (): {
  docID: string;
  isOwner: boolean;
  hasThing: boolean;
} => useContext(OrderContext);
