import getConfig from "../config";
import { Auth } from "aws-amplify";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import { ApolloClient, InMemoryCache } from "@apollo/client";

const cfg = getConfig();

const request = async (op) => {
  const cs = await Auth.currentSession();
  op.setContext({
    headers: { Authorization: "Bearer " + cs.idToken.jwtToken },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const defaultOptions = {
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const newClient = (url) => {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      requestLink,
      new HttpLink({ uri: url }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });
};

const UserClient = newClient(cfg.backend.graphqlEndPoint);
const ConnXClient = newClient(cfg.connx.graphqlEndPoint);
const ConnXEventClient = newClient(cfg.connxevents.graphqlEndPoint);

export { UserClient, ConnXClient, ConnXEventClient };
