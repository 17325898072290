import React, { useCallback } from "react";
import { useModelLoader } from "../../../helpers/hooks";
import { RVModelClient } from "../../../api/api";
import RVModelDetails, { RVModelFormValues } from "./RVModelDetails";
import { Loading } from "../../../components";

interface RVModelEditProps {
  groupID: string;
  modelID: string;
  onDone?: () => void;
}

const RVModelEdit: React.FC<RVModelEditProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const defaultDone = (): void => {};
  const done = props.onDone || defaultDone;

  // handler for loading model
  const handleLoadModel = useCallback(() => {
    return RVModelClient.get(props.groupID, props.modelID);
  }, [props]);

  // error handler
  const handleError = useCallback(
    (e: Error) => {
      console.log(e);
      alert("Failed to load");
      done();
    },
    [done]
  );

  // load the model
  const [loading, model] = useModelLoader(handleLoadModel, handleError);

  // handle submission of form
  const handleSubmit = useCallback(
    async (f: RVModelFormValues) => {
      try {
        await RVModelClient.update(model, f);
        done();
      } catch (e) {
        console.log(e);
        throw new Error("Failed to save");
      }
    },
    [done, model]
  );

  return (
    <>
      <div className="text-center page-header">
        <h1>Edit RV Model</h1>
      </div>
      <div className="container">
        {loading ? (
          <Loading />
        ) : (
          <RVModelDetails
            onSubmit={handleSubmit}
            defaultValues={{ name: model.name, desc: model.desc }}
            onCancel={props.onDone}
            confirmButtonLabel={"Save"}
          />
        )}
      </div>
    </>
  );
};

export default RVModelEdit;
