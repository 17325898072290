import React, { useCallback } from "react";
import AsyncSelect from "react-select/async";
import { RVModel } from "../../../api/connx/rvmodel";
import { RVModelClient } from "../../../api/api";
import { ValueType } from "react-select";

export const rvModelToOption = (v: RVModel): RVModelOption => ({
  value: v.docID,
  label: v.name,
  data: v,
});

export const loadRVModelOptions = async (
  inputValue: string
): Promise<RVModelOption[]> => {
  try {
    const r = await RVModelClient.listRVModels({
      maxResults: 50,
      filter: inputValue,
    });
    return r.models.map(rvModelToOption);
  } catch (e) {
    return [];
  }
};

export interface RVModelOption {
  value: string;
  label: string;
  data?: RVModel;
}

interface RVModelSelectionProps {
  disabled?: boolean;
  modelOption?: RVModelOption;
  onChange?: (v?: RVModel) => void;
  loadOptions?: (inputValue: string) => Promise<RVModelOption[]>;
}

const RVModelSelection: React.FC<RVModelSelectionProps> = ({
  modelOption,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (): void => {},
  disabled = false,
  loadOptions = loadRVModelOptions,
}) => {
  const handleChange = useCallback(
    (v: ValueType<RVModelOption>) => {
      const mdl = v as RVModelOption;
      onChange(mdl ? mdl.data : undefined);
    },
    [onChange]
  );
  return (
    <AsyncSelect
      defaultOptions={true}
      cacheOptions
      onChange={handleChange}
      loadOptions={loadOptions}
      isDisabled={disabled}
      placeholder={"Search models"}
      value={modelOption}
    />
  );
};

export default RVModelSelection;
