import { Field } from "formik";
import React from "react";
import UserGroupSelection, { UserGroupOption } from "./UserGroupSelection";

const fieldName = "group";

type FieldType = {
  value: UserGroupOption;
};

type FormType = {
  setFieldValue: (
    fieldName: string,
    value: UserGroupOption | undefined
  ) => void;
};

type Params = {
  field: FieldType;
  form: FormType;
};

const UserGroupSelectionInput = (): JSX.Element => (
  <Field name={fieldName} id={fieldName} type="text">
    {(params: Params): JSX.Element => (
      <div>
        <label htmlFor={fieldName} className={"label-color"}>
          {"Group"}
        </label>
        <div>
          <UserGroupSelection
            userGroupOption={params.field.value}
            onChange={(userGroupOption): void => {
              params.form.setFieldValue(fieldName, userGroupOption);
            }}
          />
        </div>
      </div>
    )}
  </Field>
);

export default UserGroupSelectionInput;
