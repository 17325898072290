import React from "react";

interface GenericError {
  message: string;
}

function isGenericError(error: any): error is GenericError {
  return undefined !== error && "message" in error;
}

type ErrorTypes = GenericError | string;

interface Props {
  error?: ErrorTypes;
}

const Error: React.FC<Props> = ({ error }) => {
  let message = "Oops, something went wrong!";
  if (typeof error === "string") {
    message = error;
  } else if (isGenericError(error)) {
    message = error.message;
  }
  return (
    <div data-testid="error-txt" className="d-flex justify-content-center">
      {<p>{message}</p>}
    </div>
  );
};

export default Error;
