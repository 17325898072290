import React from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const SimpleDatePicker = ({
  value,
  onChange,
  label,
}: {
  value: Date;
  onChange: (date: Date | null) => void;
  label: string;
}): JSX.Element => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disableToolbar
        variant="inline"
        format="PP"
        margin="normal"
        label={label}
        value={value}
        onChange={onChange}
        disableFuture={true}
        autoOk={true}
      />
    </MuiPickersUtilsProvider>
  );
};
