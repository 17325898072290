import React, { Component } from "react";
import { API } from "aws-amplify";
import MapGL, { NavigationControl } from "react-map-gl";
import { fromJS } from "immutable";
import MAP_STYLE from "./map-style-basic-v8.json";
import { dataLayer, circleDataLayer } from "./heat-map-layers";
import ContainerDimensions from "react-container-dimensions";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYWxpc3RlcmhhdHQiLCJhIjoiY2psZnVodWtmMTBvcDNwbWljNHhlYm01OSJ9.qJpwCMhYK8zWJjwD6QT6aQ";

const defaultMapStyle = fromJS(MAP_STYLE);

const navStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: "10px",
};

class MapView extends Component {
  state = {
    year: 2015,
    data: null,
    hoveredFeature: null,
    viewport: {
      latitude: -37.67737,
      longitude: 144.723927,
      zoom: 8,
      bearing: 0,
      pitch: 0,
      width: 500,
      height: 500,
    },
    mapStyle: defaultMapStyle,
  };

  componentDidMount() {
    window.addEventListener("resize", this._resize);
    this._resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._resize);
  }

  _onLoaded = () => {
    this.props.data && this._loaddata(this.props.data);
  };

  _loaddata = async (data) => {
    try {
      const mapStyle = defaultMapStyle
        .setIn(["sources", "heatmap"], fromJS({ type: "geojson", data }))
        .set(
          "layers",
          defaultMapStyle.get("layers").push(dataLayer).push(circleDataLayer)
        );
      this.setState({ data, mapStyle });
    } catch (e) {
      alert(e);
    }
  };

  _resize = () => {
    this.setState({
      viewport: {
        ...this.state.viewport,
        width: this.props.width || window.innerWidth,
        height: this.props.height || window.innerHeight,
      },
    });
  };

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  render() {
    const { viewport, mapStyle } = this.state;

    return (
      <div>
        <MapGL
          {...viewport}
          mapStyle={mapStyle}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          onViewportChange={this._updateViewport}
          onLoad={this._onLoaded}
        >
          <div className="nav" style={navStyle}>
            <NavigationControl onViewportChange={this._updateViewport} />
          </div>
        </MapGL>
      </div>
    );
  }
}

export default class HeatMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      S3key: null,
      isLoading: false,
      data: null,
    };
  }

  componentDidMount() {
    this._selectTrip(this.props.match.params.tripId);
  }

  _selectTrip = async (key) => {
    if (key !== this.state.S3key) {
      this.setState({ isLoading: true, S3key: key });
      try {
        const resp = await API.get("rvodo", `/trips/getTrip/${key}`);
        const data = resp.trip.geojson;
        this.setState({ data });
      } catch (e) {
        alert(e);
      }
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div className="HeatMap">
        Trip id: {this.props.match.params.tripId}
        <ContainerDimensions>
          {({ width }) =>
            this.state.isLoading ? (
              <div>Loading...</div>
            ) : (
              <MapView width={width} height={500} data={this.state.data} />
            )
          }
        </ContainerDimensions>
      </div>
    );
  }
}
