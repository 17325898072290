import React from "react";
import * as yup from "yup";
import FormGroup from "../../components/form/FormGroup";
import GenericForm from "../../components/form/GenericForm";
import RBACViewInput from "./RBACViewInput";
import { UserGroupOption } from "./UserGroupSelection";
import UserGroupSelectionInput from "./UserGroupSelectionInput";

export interface AdminSignUpUserData {
  email: string;
  name: string;
  familyName: string;
  group?: UserGroupOption;
  rbacRoles: string[];
}

const schema = yup.object<AdminSignUpUserData>({
  email: yup
    .string()
    .label("Email")
    .trim()
    .test(
      "lowercase",
      "Email must be all lowercase",
      (value) => value === String(value).toLowerCase()
    )
    .email()
    .required(),
  name: yup.string().label("First name").trim().min(1).max(100).required(),
  familyName: yup
    .string()
    .label("Family name")
    .trim()
    .min(1)
    .max(300)
    .required(),
  group: yup
    .mixed<UserGroupOption>()
    .test("group-test", "Group is required", (v) => undefined !== v),
  rbacRoles: yup.array(yup.string().required()).required(),
});

const DEFAULT_VALUES: AdminSignUpUserData = {
  email: "",
  name: "",
  familyName: "",
  group: undefined,
  rbacRoles: [],
};

interface AdminSignUpUserProps {
  onSubmit: (v: AdminSignUpUserData) => Promise<void> | void;
}

const AdminSignUpUser = ({ ...props }: AdminSignUpUserProps): JSX.Element => {
  return (
    <div className="container">
      <GenericForm defaultValues={DEFAULT_VALUES} schema={schema} {...props}>
        <FormGroup
          type="email"
          label="Email"
          name="email"
          placeholder="Email"
        />
        <FormGroup type="text" label="Name" name="name" placeholder="Name" />
        <FormGroup
          type="text"
          label="Family name"
          name="familyName"
          placeholder="Family name"
        />
        <div className="form-group">
          <UserGroupSelectionInput />
        </div>
        <div className="form-group">
          <RBACViewInput />
        </div>
      </GenericForm>
    </div>
  );
};

export default AdminSignUpUser;
