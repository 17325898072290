import { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { ApolloError, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export interface DataPoint {
  x: number;
  y: number;
  label: string;
}

export interface Series {
  data: DataPoint[];
  name: string;
}

const DCX_HISTORICAL_EVENTS_QUERY = gql`
  query dcxEvents(
    $orderID: ID!
    $type: String!
    $start: String!
    $end: String!
    $maxResults: Int!
  ) {
    dcxEvents(
      orderID: $orderID
      input: { type: $type, start: $start, end: $end, maxResults: $maxResults }
    ) {
      events {
        action
        time
      }
    }
  }
`;

interface DCXEvent {
  action: string;
  time: string;
}

interface DCXHistoricalEventsQueryResult {
  dcxEvents: {
    events?: DCXEvent[];
  };
}

export interface Event {
  action: string;
  time: number;
}

export interface HistoricalEventsResult {
  loading: boolean;
  error?: ApolloError | undefined;
  events?: Event[];
}

export function useHistoricalEvents(
  orderID: string,
  {
    eventType,
    start,
    end,
    maxResults,
  }: { eventType: string; start: Moment; end: Moment; maxResults: number }
): HistoricalEventsResult {
  const [events, setEvents] = useState<Event[]>();

  // query backend
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery<DCXHistoricalEventsQueryResult>(DCX_HISTORICAL_EVENTS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      orderID,
      type: eventType,
      start: start.format(),
      end: end.format(),
      maxResults,
    },
  });

  // handle query response
  useEffect(() => {
    if (queryData && queryData.dcxEvents.events !== undefined) {
      setEvents(
        queryData.dcxEvents.events.map((x) => ({
          action: x.action,
          time: moment(x.time).valueOf(),
        }))
      );
    }
  }, [queryData]);

  // result
  return {
    loading: queryLoading,
    error: queryError,
    events,
  };
}
