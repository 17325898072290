import React, { useCallback, useState } from "react";
import Order, { OnReleaseOrderEvent } from "./Order";
import gql from "graphql-tag";
import Loading from "../../../components/Loading";
import Error from "../../../components/Error";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Container, Row, Col, CardGroup } from "react-bootstrap";
import ConfirmationDialog from "../../../components";
import Live from "../../connx/dcx/Live";
import { OnDCXDashEvent } from "../../connx/dcx/live/ActionMenu";
import { ConnXClient } from "../../../api/apolloClient";
import { OrderProvider } from "../../../helpers/orderContext";

export interface Order {
  docID: string;
  name: string;
  desc: string;
  serialNumber: string;
  iotThingName: string;
}

export interface DCXDashEvent {
  order: Order;
}

interface OrderListProps {
  userID: string;
  canViewDCX?: boolean;
  onDCXDash?: (e: DCXDashEvent) => void;
}

const ORDER_IDS_QUERY = gql`
  query userRVOrderIDs($user: ID!) {
    userRVOrderIDs(user: $user)
  }
`;

interface OrderIdsQueryResult {
  userRVOrderIDs: string[];
}

const ORDERS_QUERY = gql`
  query rvOrders($orderIDs: [ID!]!) {
    rvOrders(orderIDs: $orderIDs) {
      docID
      name
      desc
      serialNumber
      iotThingName
    }
  }
`;

interface QueryResult {
  rvOrders: Order[];
}

export const RELEASE_ORDER_MUTATION = gql`
  mutation revokeRVOrderOwner($userID: ID!, $orderID: ID!) {
    revokeRVOrderOwner(userID: $userID, orderID: $orderID) {
      id
    }
  }
`;

type mutationResult = {
  revokeRVOrderOwner: {
    id: string;
  };
};

const OrdersList: React.FC<OrderListProps> = ({
  userID,
  canViewDCX = false,
  onDCXDash,
}) => {
  // list orders
  const { loading: queryLoading, error: queryError, data } = useQuery<
    OrderIdsQueryResult
  >(ORDER_IDS_QUERY, {
    variables: { user: userID },
  });

  // query order details
  const {
    loading: queryDetailsLoading,
    error: queryDetailsError,
    data: detailsData,
  } = useQuery<QueryResult>(ORDERS_QUERY, {
    variables: { orderIDs: data?.userRVOrderIDs },
    skip: !data,
    client: ConnXClient,
  });

  // order removal mutation
  const [
    releaseOrder,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<mutationResult>(RELEASE_ORDER_MUTATION, {
    update: (cache, { data }) => {
      // update the cache (remove item)
      if (!data) return;
      const qr = cache.readQuery<OrderIdsQueryResult>({
        query: ORDER_IDS_QUERY,
        variables: { user: userID },
      });
      if (!qr) return;
      const index = qr.userRVOrderIDs.findIndex(
        (o) => o === data.revokeRVOrderOwner.id
      );
      if (index === -1) return;
      qr.userRVOrderIDs.splice(index, 1);
      cache.writeQuery({
        query: ORDER_IDS_QUERY,
        data: qr,
      });
    },
  });

  // state
  const [confirmReleaseID, setConfirmReleaseID] = useState<
    OnReleaseOrderEvent | undefined
  >(undefined);

  // release order callback handler
  const handleRelease = useCallback(
    async (e: OnReleaseOrderEvent) => {
      setConfirmReleaseID(e);
    },
    [setConfirmReleaseID]
  );
  const handleDCXDash = useCallback(
    (e: OnDCXDashEvent) => {
      const order = detailsData?.rvOrders.find((v) => v.docID === e.orderID);
      if (!order) return;
      onDCXDash?.({ order });
    },
    [onDCXDash, detailsData]
  );

  // rendering
  if (queryLoading || mutationLoading || queryDetailsLoading) {
    return (
      <div data-testid="order-list-loading">
        <Loading />
      </div>
    );
  }
  if (queryError || mutationError || queryDetailsError) {
    return (
      <div data-testid="order-list-error">
        <Error />
      </div>
    );
  }
  if (!detailsData || detailsData.rvOrders.length === 0) {
    const noOrders = "You don't have any orders.";
    return <div data-testid="order-list-empty">{noOrders}</div>;
  }
  return (
    <>
      <ConfirmationDialog
        caption={`Confirm revocation of ownership of the order '${confirmReleaseID?.name}'.`}
        message="Are you sure you want to do this?"
        show={!!confirmReleaseID}
        onClose={async ({ cancelled }): Promise<void> => {
          if (!cancelled) {
            await releaseOrder({
              variables: { userID, orderID: confirmReleaseID?.id },
            });
          }
          setConfirmReleaseID(undefined);
        }}
      />
      <Container data-testid="order-list">
        <Row>
          <Col>
            {detailsData.rvOrders.map((o, i) => (
              <CardGroup key={i}>
                <Order
                  orderID={o.docID}
                  name={o.name}
                  description={o.desc}
                  serialNumber={o.serialNumber}
                  onRelease={handleRelease}
                />
                {canViewDCX && (
                  // FIXME: https://reactjs.org/docs/context.html#caveats
                  <OrderProvider
                    docID={o.docID}
                    isOwner={true}
                    hasThing={"" !== o.iotThingName}
                  >
                    <Live onDCXDash={canViewDCX ? handleDCXDash : undefined} />
                  </OrderProvider>
                )}
              </CardGroup>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrdersList;
