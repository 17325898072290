import React, { useCallback, useEffect, useState } from "react";
import {
  TableRow,
  renderTable,
  RowRendererProps,
} from "../../../components/PaginatedListTableRenderer";
import {
  ListRendererProps,
  DataSourceOutput,
} from "../../../components/PaginatedList";
import PaginatedSearchList, {
  CustomFilterComponentProps,
  BaseFilter,
  DataSourceOptions,
} from "../../../components/PaginatedSearchList";
import { RVModel } from "../../../api/connx/rvmodel";
import RVModelSelection from "../rvmodel/RVModelSelection";
import RVModelOrderSelection from "../rvmodel/RVModelOrderSelection";
import { RVModelOrder } from "../../../api/connx/rvmodelorder";
import { SystemEventsClient } from "../../../api/api";
import { Button } from "react-bootstrap";
import ModalJSON from "../../../components/ModalJSON";
import Moment from "react-moment";

interface SystemEvent {
  id: string;
  timestamp: string; // RFC3339
  type: string;
  params: object;
}

interface SystemEventListQueryInput {
  orderID: string;
  maxResults: number;
  nextKey?: string;
}

interface SystemEventListQueryOutput {
  events: SystemEvent[];
  nextKey?: string;
}

interface SystemEventListSource {
  (e: SystemEventListQueryInput):
    | Promise<SystemEventListQueryOutput>
    | SystemEventListQueryOutput;
}

interface SystemEventListProps {
  source?: (
    e: SystemEventListQueryInput
  ) => Promise<SystemEventListQueryOutput> | SystemEventListQueryOutput;
}

interface MyRowRendererProps {
  onViewParams?: (e: SystemEvent) => void;
}

const MyRow: React.FC<RowRendererProps<SystemEvent> & MyRowRendererProps> = ({
  item,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onViewParams = (): void => {},
  ...childProps
}) => {
  const handleView = (): void => {
    onViewParams(item);
  };
  return (
    <TableRow item={item} {...childProps}>
      <div>
        <Moment format="lll">{item.timestamp}</Moment>
      </div>
      <div>{item.type}</div>
      <div>
        <Button variant="link" onClick={handleView}>
          View
        </Button>
      </div>
    </TableRow>
  );
};

const TableRenderer = renderTable(MyRow);

const MyTableRenderer: React.FC<ListRendererProps<SystemEvent>> = (props) => (
  <TableRenderer {...props}>
    <div>Time</div>
    <div>Type</div>
    <div>Details</div>
  </TableRenderer>
);

interface MyFilterComponentProps extends BaseFilter {
  orderID?: string;
}

const MyCustomFilter: React.FC<CustomFilterComponentProps<
  MyFilterComponentProps
>> = ({ value, onChange, disabled, onDisableSearch }) => {
  // model state local; required to feed into order selection component
  const [model, setModel] = useState<RVModel | undefined>(undefined);

  // change model selection change
  const handleModelChange = useCallback(
    (mdl?: RVModel) => {
      setModel(mdl);
    },
    [setModel]
  );

  // handle order selection change
  const handleOrderChange = useCallback(
    (mdl?: RVModelOrder) => {
      onDisableSearch(!mdl);
      onChange({ ...value, orderID: mdl ? mdl.docID : undefined });
    },
    [onDisableSearch, onChange, value]
  );

  // ensure search is disabled when no order is selected
  useEffect(() => {
    onDisableSearch(!value.orderID);
  }, [onDisableSearch, value]);

  // components
  return (
    <>
      <div className="form-group">
        <RVModelSelection onChange={handleModelChange} disabled={disabled} />
      </div>
      <div className="form-group">
        <RVModelOrderSelection
          onChange={handleOrderChange}
          disabled={disabled}
          modelID={model ? model.docID : undefined}
        />
      </div>
    </>
  );
};

function getSystemEventsAdaptor(source: SystemEventListSource) {
  return async (
    params: DataSourceOptions<MyFilterComponentProps>
  ): Promise<DataSourceOutput<SystemEvent>> => {
    if (!params.filterParams.orderID) {
      // no order selected; return an empty set
      return { items: [] };
    }
    const r = await source({
      orderID: params.filterParams.orderID,
      maxResults: 10,
      nextKey: params.nextKey,
    });
    return {
      items: r.events,
      nextKey: r.nextKey,
    };
  };
}

function defaultSource(
  e: SystemEventListQueryInput
): Promise<SystemEventListQueryOutput> | SystemEventListQueryOutput {
  return SystemEventsClient.listEvents(e);
}

const SystemEventList: React.FC<SystemEventListProps> = ({
  source = defaultSource,
  ...rest
}) => {
  const handleGetItems = useCallback(getSystemEventsAdaptor(source), [source]);

  const [targetItem, setTargetItem] = useState<SystemEvent>({} as SystemEvent);

  const [show, setShow] = useState(false);
  const handleClose = (): void => setShow(false);

  const tableProps = {
    ...rest,
    onViewParams: (t: SystemEvent): void => {
      setTargetItem(t);
      setShow(true);
    },
  };

  return (
    <>
      <ModalJSON
        title="Event parameters"
        json={targetItem.params}
        show={show}
        onHide={handleClose}
      />
      <PaginatedSearchList
        getItems={handleGetItems}
        ListRenderer={MyTableRenderer}
        hideSearchField={true}
        CustomFilters={MyCustomFilter}
        {...tableProps}
      />
    </>
  );
};

export default SystemEventList;
