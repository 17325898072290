import React from "react";
import AppliedRoute from "../components/AppliedRoute";
import Terms from "../containers/help/Terms";
import Feedback from "../containers/help/Feedback";
import Documentation from "../containers/help/Documentation";

const HelpRoutes = ({ childProps }) => (
  <>
    <AppliedRoute
      path="/help/submitfeedback"
      exact
      component={Feedback}
      props={childProps}
    />
    <AppliedRoute
      path="/help/documentation"
      exact
      component={Documentation}
      props={childProps}
    />
    <AppliedRoute
      path="/help/terms"
      exact
      component={Terms}
      props={childProps}
    />
  </>
);

HelpRoutes.displayName = "HelpRoutes";

export default HelpRoutes;
