import React, { useState, useEffect, useReducer } from "react";
import { makeStyles, Theme, Grid } from "@material-ui/core";
import RVModelSelection from "./RVModelSelection";
import { RVModel } from "../../../api/connx/rvmodel";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { ApolloClient } from "@apollo/client";
import { ConnXClient } from "../../../api/apolloClient";
import { Loading, ErrorView } from "../../../components";
import Live from "../dcx/Live";
import { OnDCXDashEvent } from "../dcx/live/ActionMenu";
import { useHistory } from "react-router-dom";
import { useLocationState } from "../../../helpers/useLocationState";
import { OrderProvider } from "../../../helpers/orderContext";

const ORDERS_QUERY = gql`
  query listRVModelOrders($groupID: ID!) {
    listRVModelOrders(groupID: $groupID) {
      models {
        docID
        name
        iotThingName
      }
    }
  }
`;

interface Order {
  docID: string;
  name: string;
  iotThingName: string;
}

interface QueryResult {
  listRVModelOrders: {
    models: Order[];
  };
}

interface LocationState {
  model: string;
  modelName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  dcxLive: {
    padding: theme.spacing(3, 0),
  },
}));

const Dashboard: React.FC<{ client?: ApolloClient<unknown> }> = ({
  client = ConnXClient,
}) => {
  const [rvModel, setModel] = useState<RVModel | undefined>(undefined);

  const history = useHistory<LocationState>();
  const [locationState, setLocationState] = useLocationState<LocationState>();

  const handleModelChange = (m?: RVModel): void => {
    setModel(m);

    // Store the currently selected model in the current history location state.
    const newLocationState: LocationState = {
      model: "",
      modelName: "null",
    };
    if (m) {
      newLocationState.model = m.docID;
      newLocationState.modelName = m.name;
    }
    setLocationState(newLocationState);
  };

  const { loading, data, error } = useQuery<QueryResult>(ORDERS_QUERY, {
    skip: rvModel === undefined,
    variables: {
      groupID: rvModel?.docID,
    },
    client: client,
  });

  // Use the location to set the selected model.
  if (locationState) {
    if (locationState.model !== rvModel?.docID) {
      setModel({
        groupID: "",
        docID: locationState.model,
        name: locationState.modelName,
        desc: "",
        revision: -1,
      });
    }
  } else {
    if (undefined !== rvModel) {
      setModel(undefined);
    }
  }

  const [orders, setOrders] = useReducer(
    (
      _prev: Order[] | undefined,
      data: QueryResult | undefined
    ): Order[] | undefined => {
      if (data) {
        return data.listRVModelOrders.models
          .filter((x) => x.iotThingName !== "")
          .sort((a, b) => a.name.localeCompare(b.name));
      }
      return undefined;
    },
    undefined
  );

  useEffect(() => {
    setOrders(data);
  }, [data]);

  const handleDCXDash = (name: string): ((e: OnDCXDashEvent) => void) => {
    return (e: OnDCXDashEvent): void => {
      history.push(`/dcx/${e.orderID}/dashboard?dash_name=${name}`);
    };
  };

  const classes = useStyles();

  return (
    <>
      {/* select RV model */}
      <RVModelSelection
        modelOption={{
          value: "testvalue",
          label: rvModel ? rvModel.name : "",
          data: rvModel,
        }}
        onChange={handleModelChange}
      />

      {/* loading data */}
      {loading && <Loading />}

      {/* error */}
      {rvModel && error && <ErrorView />}

      {/* list of 'live' data */}
      {orders && (
        <div className={classes.dcxLive}>
          <Grid container spacing={2}>
            {orders.map((x) => (
              <Grid item key={x.docID} sm>
                {/* FIXME: https://reactjs.org/docs/context.html#caveats */}
                <OrderProvider
                  docID={x.docID}
                  isOwner={false}
                  hasThing={"" !== x.iotThingName}
                >
                  <Live title={x.name} onDCXDash={handleDCXDash(x.name)} />
                </OrderProvider>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
};

export default Dashboard;
