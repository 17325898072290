import React, { useReducer } from "react";
import { DialogContent, DialogActions, Button, Chip } from "@material-ui/core";
import { MinSOCSlider, validMinSOC } from "./MinSOCSlider";
import { FutureDateTimePicker } from "../../../../components/FutureDateTimePicker";

export interface FormResult {
  minSOC: number | string | Array<number | string>;
  expiry: Date;
}

interface QueryResult {
  minSOC: number | null;
  expiry: string | null;
}

interface Props {
  queryResult: QueryResult;
  onClose: () => void;
  onUpdate: (data: FormResult) => void;
  onClear: () => void;
}

export const validDate = (expiryDate: Date): boolean => {
  return expiryDate.valueOf() > Date.now();
};

type State = {
  valid: boolean;
  minSOC: number | string | Array<number | string>;
  expiry: Date;
};

export type Action =
  | {
      type: "setMinSOCChange";
      minSOC: number | string | Array<number | string>;
    }
  | {
      type: "setExpiryChange";
      expiry: Date;
    };

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "setMinSOCChange":
      return {
        valid: validMinSOC(action.minSOC) && validDate(state.expiry),
        minSOC: action.minSOC,
        expiry: state.expiry,
      };
    case "setExpiryChange":
      return {
        valid: validMinSOC(state.minSOC) && validDate(action.expiry),
        minSOC: state.minSOC,
        expiry: action.expiry,
      };
  }
}

export const GenOverrideForm: React.FC<Props> = ({
  queryResult,
  onClose,
  onUpdate,
  onClear,
}) => {
  const initialMinSOC = queryResult.minSOC ?? 0;
  const initialExpiry = new Date(queryResult.expiry ?? Date.now());
  const [state, dispatch] = useReducer(reducer, {
    valid: validMinSOC(initialMinSOC) && validDate(initialExpiry),
    minSOC: initialMinSOC,
    expiry: initialExpiry,
  });

  // If queryResult contents is null, gen override has never been set.
  // If queryResult.expiry is in the past, get override has expired.
  const set = null != queryResult.minSOC && null != queryResult.expiry;
  const expired = initialExpiry.valueOf() < Date.now();
  const status = set ? (expired ? "Expired" : "Active") : "Not set";

  const handleMinSOCChange = (
    minSOC: number | string | Array<number | string>
  ): void => {
    dispatch({ type: "setMinSOCChange", minSOC });
  };

  const handleDateChange = (expiry: Date): void => {
    dispatch({ type: "setExpiryChange", expiry });
  };

  // Update pressed.
  const handleOverrideUpdate = (): void => {
    onUpdate({ minSOC: state.minSOC, expiry: state.expiry });
  };

  return (
    <React.Fragment>
      <DialogContent dividers>
        <div className="d-flex justify-content-center">
          <Chip label={status} />
          {set && !expired && (
            <Button onClick={onClear} color="primary">
              Clear
            </Button>
          )}
        </div>
      </DialogContent>
      <DialogContent dividers>
        <MinSOCSlider value={state.minSOC} onChange={handleMinSOCChange} />
        <FutureDateTimePicker
          label="Expiry Date/Time"
          value={state.expiry}
          onChange={(date): void => {
            if (date) {
              handleDateChange(date);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          autoFocus
          disabled={!state.valid}
          onClick={handleOverrideUpdate}
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
