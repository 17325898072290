import React, { FunctionComponent } from "react";
import "./PaginatedListTableRenderer.css";
import { Table, Dropdown } from "react-bootstrap";
import { ListRendererProps } from "./PaginatedList";

export interface RowRendererProps<TItem> {
  item: TItem;
  index: number;
  // performs an local deletion of the item at the given index from the
  // list
  onDeleted: (index: number) => void;
}

// renders a table, using a React component that can render using RowRenderProps type.
// any children of the returned component a rendered as table header items.
// see below for an example.
export const renderTable = <TItem extends object>(
  Row: React.ComponentType<RowRendererProps<TItem>>
): React.FC<ListRendererProps<TItem>> => {
  const c: React.FC<ListRendererProps<TItem>> = (props) => {
    const { list, onDeleted, children, ...childProps } = props;

    return (
      <>
        <div className="PaginatedListTableRenderer">
          <Table>
            <thead>
              <tr>
                {React.Children.map(children, (child) => {
                  return <th> {child} </th>;
                })}
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item, index) => (
                  <Row
                    key={index}
                    item={item}
                    index={index}
                    onDeleted={onDeleted}
                    {...childProps}
                  />
                ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  return c;
};

/*

Example:

interface MyObject {
    name: string;
    value: string;
}

const MyRow = ({ item } : RowRendererProps<MyObject> ) => {
    return (
        <tr>
            <td>{item.name}</td>
            <td>{item.value}</td>
        </tr>
    )
}

const TableRenderer = renderTable(MyRow)

const MyTableRenderer = ({...rest}) =>
    <TableRenderer {...rest} >
        <div>Name</div>
        <div>Desc</div>
        <div>Device</div>
        <div>Versions</div>
    </TableRenderer>

*/

export interface TableRowAction<TItem> {
  name: string;
  onClick: (item: TItem) => void;
  disabled?: boolean;
}

interface TableRowProps<TItem> {
  item: TItem;
  actions?: TableRowAction<TItem>[];
}

export const TableRow: FunctionComponent<TableRowProps<any>> = ({
  item,
  actions,
  children,
}): JSX.Element => (
  <tr>
    {React.Children.map(children, (child) => (
      <td>{child}</td>
    ))}
    {actions && (
      <td>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" />
          <Dropdown.Menu>
            {actions.map((a, index) => (
              <Dropdown.Item
                key={index}
                disabled={true === a.disabled}
                onClick={(): void => a.onClick(item)}
              >
                {a.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </td>
    )}
  </tr>
);
