import { defRBAC, RBACPermission, RBACRole } from "./rbac";

export type Scope =
  | "admin" // user admin
  | "connx.regdev" // ConnX registered devices
  | "connx.fw" // ConnX firmware
  | "connx.devcfg" // ConnX device configuration
  | "connx.rvcfg" // ConnX RV models and orders
  | "connx.iot.events" // ConnX IoT scope
  | "connx.provisioning"; // ConnX provisioning scope

export type Role = "read" | "write";

export interface User {
  roles: string[];
  isAuthenticated: boolean;
  groupID?: string;
  rbacRoles?: string[];
}

let curUser: User | null = null;

export const setCurUser = (user: User | null): void => {
  curUser = user;
};

export class UserLib {
  constructor(private getUser: () => User | null = UserLib._getUser) {}

  isAuthenticated(): boolean {
    const u = this.getUser();
    if (!u) return false;
    return u.isAuthenticated;
  }

  hasRole(s: Scope, r: Role): boolean {
    const u = this.getUser();
    if (!u) return false;
    return (
      u.roles &&
      u.roles.find((cr) => {
        if (cr === "super") {
          return true;
        }
        const tokens = cr.split(":");
        return tokens.length === 2 && tokens[0] === s && tokens[1] === r;
      }) !== undefined
    );
  }

  getUserGroupID(): string {
    const u = this.getUser();
    if (!u) {
      throw new Error("User is not logged in");
    }
    if (undefined === u.groupID) {
      throw new Error("User group ID not defined");
    }
    return u.groupID;
  }

  isUserInGroup(): boolean {
    const regexUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regexUUID.test(this.getUserGroupID());
  }

  canRead(scope: Scope): boolean {
    return this.isAuthorisedFor(scope, "read");
  }

  isAuthorisedFor(scope: Scope, role: Role): boolean {
    return this.isAuthenticated() && this.hasRole(scope, role);
  }

  anyGranted(p: RBACPermission): boolean {
    if (!this.isAuthenticated()) return false;
    const u = this.getUser();
    if (!u || !u.rbacRoles) return false;
    return defRBAC.anyGranted(
      u.rbacRoles.map((x) => x as RBACRole),
      p
    );
  }

  static _getUser = (): User | null => {
    return curUser;
  };
}

const defLib = new UserLib();

// convenience methods
export const isAuthenticated = (): boolean => defLib.isAuthenticated();
export const HasRole = (scope: Scope, role: Role): boolean => {
  return defLib.hasRole(scope, role);
};
export function canRead(scope: Scope): boolean {
  return defLib.canRead(scope);
}
export function anyGranted(p: RBACPermission): boolean {
  return defLib.anyGranted(p);
}

// gets the group UUID the user belongs to
export const getUserGroupID = (): string => {
  return defLib.getUserGroupID();
};
export function isUserInGroup(): boolean {
  return defLib.isUserInGroup();
}
