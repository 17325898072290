import React, { useCallback } from "react";
import RegisterDevice, { FormValues } from "./RegisterDevice";
import Client, {
  UpdateRegisteredDeviceInput,
  RegisteredDevice,
} from "../../api/connx";
import { useModelLoader } from "../../helpers/hooks";
import { Loading } from "../../components";

interface EditRegisteredDeviceProps {
  id: string;
  onDone: (e?: Error) => void;
  updateRegisteredDevice?: (
    id: string,
    revision: number,
    changes: UpdateRegisteredDeviceInput
  ) => Promise<RegisteredDevice> | RegisteredDevice;
  getRegisteredDevice?: (
    id: string
  ) => Promise<RegisteredDevice> | RegisteredDevice;
}

// allows user to edit name and description of a registered device
const EditRegisteredDevice = ({
  onDone,
  id,
  updateRegisteredDevice = Client.updateRegisteredDevice,
  getRegisteredDevice = Client.getRegisteredDevice,
}: EditRegisteredDeviceProps): JSX.Element => {
  const handleLoading = useCallback(() => {
    return getRegisteredDevice(id);
  }, [id, getRegisteredDevice]);

  const handleError = useCallback(
    (e: Error) => {
      onDone(e);
    },
    [onDone]
  );

  const [loading, deviceConfig] = useModelLoader(handleLoading, handleError);

  const handleCancel = useCallback(() => {
    onDone();
  }, [onDone]);

  const handleDeviceEdit = useCallback(
    async (values: FormValues) => {
      await updateRegisteredDevice(deviceConfig.id, deviceConfig.revision, {
        name: values.name,
        desc: values.desc,
        manufacturer: values.manufacturer,
      });
      alert("Device configuration updated.");
      onDone();
    },
    [onDone, deviceConfig.id, deviceConfig.revision, updateRegisteredDevice]
  );

  return (
    <>
      <div className="text-center page-header">
        <h1>Registered device</h1>
        <small>Edit registered device values</small>
      </div>
      <div className="container">
        {loading ? (
          <Loading />
        ) : (
          <RegisterDevice
            onSubmit={handleDeviceEdit}
            onCancel={handleCancel}
            submitButtonName="Apply"
            initialValues={{
              manufacturer: deviceConfig.manufacturer,
              name: deviceConfig.name,
              desc: deviceConfig.desc,
            }}
          />
        )}
      </div>
    </>
  );
};

export default EditRegisteredDevice;
