import React from "react";
import AppliedRoute from "../components/AppliedRoute";
import OrdersView from "../containers/user/orders/Orders";
import Root from "../containers/user/settings/Root";

const UserRoutes = ({ childProps }) => (
  <>
    <AppliedRoute
      path="/user/:userID/orders/"
      exact
      component={({
        match: {
          params: { userID = "" },
        },
      }) => <OrdersView userID={userID} {...childProps} />}
    />
    <AppliedRoute
      path="/user/settings"
      exact
      component={Root}
      props={childProps}
    />
  </>
);

UserRoutes.displayName = "UserRoutes";

export default UserRoutes;
