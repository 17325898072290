import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import semver from "semver";

const schema = yup.object().shape({
  fwdir: yup
    .string()
    .matches(
      /^[a-z\-_]+?[a-z0-9\-_]+?\/[a-z0-9-_]+$/,
      "Directory name can only include the characters a-z,'/','-','_'"
    )
    .required("Firmware directory is required"),
  fwver: yup
    .string()
    .test(
      "semver-test",
      "Must conform to semantic versioning spec v2",
      (v) => semver.valid(v) === v
    )
    .required("Version is required"),
  file: yup
    .mixed()
    .test(
      "binary-file-test",
      "No file selected",
      (f) => null !== f && f instanceof File
    )
    .required("Firmware binary required"),
});

const initialValues = {
  fwdir: "",
  fwver: "",
  file: null,
};

const useUploadFirmwareState = (onUpload) => {
  const [values, setValues] = useState(initialValues);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      (await onUpload) && onUpload({ file: values.file });
      console.log(values);
      setValues(initialValues);
      resetForm(initialValues);
      alert("Firmware binary uploaded");
    } catch (e) {
      console.log(e);
      alert(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    schema,
    onSubmit,
    initialValues: values,
    isInitialValid: schema.isValidSync(values),
  };
};

const FirmwareUpload = ({ onUpload }) => {
  const {
    schema,
    onSubmit,
    initialValues,
    isInitialValid,
  } = useUploadFirmwareState(onUpload);
  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        isValid,
        setFieldValue,
        dirty,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Device directory</Form.Label>
            <Form.Control
              type="text"
              placeholder="Device directory. E.g. ozxcorp/brakelight"
              name="fwdir"
              value={values.fwdir}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={(touched.fwdir || isInitialValid) && !errors.fwdir}
            />
            {errors.fwdir && touched.fwdir && (
              <Form.Text>{errors.fwdir}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Version</Form.Label>
            <Form.Control
              type="text"
              placeholder="Version number. E.g. 1.0.0"
              name="fwver"
              value={values.fwver}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={(touched.fwver || isInitialValid) && !errors.fwver}
            />
            {errors.fwver && touched.fwver && (
              <Form.Text>{errors.fwver}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Binary</Form.Label>
            <Form.Control
              type="file"
              accept="*"
              placeholder="Select firmware binary"
              name="files"
              onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
              }}
              onBlur={handleBlur}
              isValid={(touched.file || isInitialValid) && !errors.file}
            />
            {errors.file && touched.file && (
              <Form.Text>{errors.file}</Form.Text>
            )}
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={isSubmitting || !isValid || !dirty}
          >
            Upload
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default FirmwareUpload;
export { schema, initialValues };
