import React from "react";
import { Modal } from "react-bootstrap";
import ReactJson from "react-json-view";

interface ModalJSONProps {
  json: object;
  show: boolean;
  onHide: () => void;
  title?: string;
}

const ModalJSON = (props: ModalJSONProps): JSX.Element => {
  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactJson src={props.json} name={false} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalJSON;
