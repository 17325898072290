import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  Button,
  makeStyles,
  createStyles,
  Theme,
  FormLabel,
  FormControl,
} from "@material-ui/core";
import { SimpleDatePicker } from "../../../../components";
import { Moment } from "moment";
import moment from "moment";
import {
  LIQUID_SENSOR_FULL_VOLTAGE,
  WH_TO_G_CO2,
} from "../../dcx/dashboard/Dashboard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(0),
    },
    button: {
      margin: theme.spacing(3, 1, 0, 0),
    },
  })
);

interface Order {
  docID: string;
  name: string;
  iotThingName: string;
}

interface QueryResult {
  listRVModelOrders: {
    models: Order[];
  };
}

export interface ExportConfig {
  selectedOrderNames: string[];
  selectedOrderIDs: string[];
  selectedSeriesNames: string[];
  selectedSeriesIDs: string[];
  selectedSeriesMultipliers: number[];
  dateRange: Moment[];
}

interface Props {
  orders: Order[];
  onSubmit?: (cfg: ExportConfig) => void;
  onSubmitError?: (e: unknown) => void;
}

const ExportForm: React.FC<Props> = ({ orders, onSubmit, onSubmitError }) => {
  const classes = useStyles();

  // Orders
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);

  // Data
  interface DataInfo {
    displayName: string;
    seriesName: string;
    multiplier: number;
  }
  const liquidMultiplier = 100 / LIQUID_SENSOR_FULL_VOLTAGE;
  const dataChannels: DataInfo[] = [
    { displayName: "Solar Power", seriesName: "pv.power", multiplier: 1 },
    { displayName: "Battery SOC", seriesName: "battery.soc", multiplier: 1 },
    {
      displayName: "Battery Voltage",
      seriesName: "battery.total_voltage",
      multiplier: 1,
    },
    {
      displayName: "Potable1",
      seriesName: "liquids.level0",
      multiplier: liquidMultiplier,
    },
    {
      displayName: "Potable2",
      seriesName: "liquids.level1",
      multiplier: liquidMultiplier,
    },
    {
      displayName: "Grey1",
      seriesName: "liquids.level2",
      multiplier: liquidMultiplier,
    },
    {
      displayName: "Grey2",
      seriesName: "liquids.level5",
      multiplier: liquidMultiplier,
    },
    {
      displayName: "Waste",
      seriesName: "liquids.level3",
      multiplier: liquidMultiplier,
    },
    {
      displayName: "Fuel",
      seriesName: "liquids.level4",
      multiplier: liquidMultiplier,
    },
    {
      displayName: "Estimated CO2 Saving",
      seriesName: "pv.power",
      multiplier: 0.001 * WH_TO_G_CO2,
    },
  ];

  const [selectedSeries, setSelectedSeries] = useState<DataInfo[]>([]);

  // Date
  const toRange = (d: Moment): Moment[] => [
    moment(d),
    moment(d).add(1, "days").subtract(1, "minutes"),
  ];
  const [dateRange, setDateRange] = useState<Moment[]>(
    toRange(moment().startOf("day"))
  );

  // Submit button
  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    const anySelectedOrders = 0 < selectedOrders.length;
    const anySelectedData = 0 < selectedSeries.length;
    const anySelectedOrdersAndData = anySelectedOrders && anySelectedData;
    if (anySelectedOrdersAndData !== valid) {
      setValid(!valid);
    }
  }, [valid, selectedOrders, selectedSeries]);

  const selectedOrderNames = selectedOrders.map((o: Order) => {
    return o.name;
  });
  const selectedOrderIDs = selectedOrders.map((o: Order) => {
    return o.docID;
  });
  const selectedSeriesNames = selectedSeries.map((s: DataInfo) => {
    return s.displayName;
  });
  const selectedSeriesIDs = selectedSeries.map((s: DataInfo) => {
    return s.seriesName;
  });
  const selectedSeriesMultipliers = selectedSeries.map((s: DataInfo) => {
    return s.multiplier;
  });

  const handleSubmit = (): void => {
    try {
      onSubmit?.({
        selectedOrderNames,
        selectedOrderIDs,
        selectedSeriesNames,
        selectedSeriesIDs,
        selectedSeriesMultipliers,
        dateRange,
      });
    } catch (e) {
      if (undefined === onSubmitError) {
        console.log(e);
      } else {
        onSubmitError(e);
      }
    }
  };

  return (
    <div className={classes.root}>
      <FormControl
        component="fieldset"
        className={classes.formControl}
        disabled={false}
      >
        <FormLabel component="legend">Orders</FormLabel>
        <div>
          {orders.map((x) => (
            <div key={x.docID}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={x.name}
                    checked={selectedOrders.includes(x)}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      if (event.target.checked) {
                        if (!selectedOrders.includes(x)) {
                          setSelectedOrders([...selectedOrders, x]);
                        }
                      } else {
                        setSelectedOrders(
                          selectedOrders.filter((order) => {
                            return order.docID !== x.docID;
                          })
                        );
                      }
                    }}
                  />
                }
                label={x.name}
              />
            </div>
          ))}
        </div>
        <FormLabel component="legend">Data</FormLabel>
        <div>
          {dataChannels.map((x) => (
            <div key={x.seriesName}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={x.seriesName}
                    checked={
                      selectedSeries.filter((series) => {
                        return series.displayName === x.displayName;
                      }).length > 0
                    }
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      if (event.target.checked) {
                        if (
                          selectedSeries.filter((series) => {
                            return series.displayName === x.displayName;
                          }).length === 0
                        ) {
                          setSelectedSeries([...selectedSeries, x]);
                        }
                      } else {
                        setSelectedSeries(
                          selectedSeries.filter((series) => {
                            return series.displayName !== x.displayName;
                          })
                        );
                      }
                    }}
                  />
                }
                label={x.displayName}
              />
            </div>
          ))}
        </div>
        <div>
          <SimpleDatePicker
            label="Start Date"
            value={dateRange[0].toDate()}
            onChange={(startDate: Date | null): void => {
              const endDate =
                startDate && moment(startDate).isAfter(dateRange[1])
                  ? moment(startDate)
                  : dateRange[1];
              setDateRange([
                startDate ? moment(startDate) : dateRange[1],
                endDate,
              ]);
            }}
          />
        </div>
        <div>
          <SimpleDatePicker
            label="End Date"
            value={dateRange[1].toDate()}
            onChange={(endDate: Date | null): void => {
              const startDate =
                endDate && moment(endDate).isBefore(dateRange[0])
                  ? moment(endDate)
                  : dateRange[0];
              setDateRange([
                startDate,
                endDate ? moment(endDate) : dateRange[0],
              ]);
            }}
          />
        </div>
        <Button
          disabled={!valid}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleSubmit}
        >
          Download CSV
        </Button>
      </FormControl>
    </div>
  );
};

export default ExportForm;
