import { DataSourceOptions } from "../../../../components/PaginatedSearchList";
import {
  withPaginatedItemSupport,
  Item,
} from "../../../../components/PaginatedList";
import { RVModelOrderClient } from "../../../../api/api";
import { RVModelOrder } from "../../../../api/connx/rvmodelorder";
import { MyCustomFilterValues } from "./ModelFilter";

export interface GetRVModelOrdersResult {
  items: (RVModelOrder & Item)[];
  nextKey?: string;
}

export const getRVModelOrders = async ({
  filterParams,
  nextKey,
}: DataSourceOptions<MyCustomFilterValues>): Promise<
  GetRVModelOrdersResult
> => {
  if (!filterParams.model) {
    return {
      items: [],
    };
  }
  const r = await RVModelOrderClient.listRVOrders(filterParams.model.docID, {
    filterName: "" !== filterParams.filter ? filterParams.filter : undefined,
    nextKey: nextKey,
  });
  return {
    items: r.models.map((m) => withPaginatedItemSupport(m, m.docID)),
    nextKey: r.nextKey,
  };
};
