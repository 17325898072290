import React, { useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { User } from "../../api/user";
import { anyGranted } from "../../user/user";
import { RowRendererProps } from "../../components/PaginatedListTableRenderer";

export interface UserRowProps extends RowRendererProps<User> {
  onEdit?: (user: User) => void;
  canEdit?: () => boolean;
}

const UserRow: React.FC<UserRowProps> = ({
  item,
  onEdit,
  canEdit = (): boolean => anyGranted("user_update"),
}) => {
  const handleEditUser = useCallback(() => {
    onEdit?.(item);
  }, [onEdit, item]);

  return (
    <tr>
      <td>{item.email}</td>
      <td className="action">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" data-testid="action_menu" />
          <Dropdown.Menu>
            <Dropdown.Item
              key="0"
              disabled={!canEdit()}
              onClick={handleEditUser}
            >
              Edit
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default UserRow;
