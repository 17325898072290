import React, { useCallback, useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

interface OnCloseEvent {
  cancelled: boolean;
}

interface ConfirmationDialogProps {
  show: boolean;
  onClose: (e: OnCloseEvent) => Promise<void> | void;
  caption?: string;
  message?: string;
  cancel?: string;
  confirm?: string;
}

const ConfirmationDialog = ({
  show,
  onClose,
  caption = "",
  message = "Are you sure?",
  cancel = "No",
  confirm = "Yes",
}: ConfirmationDialogProps): JSX.Element => {
  const [closing, setClosing] = useState(false);
  const doClose = useCallback(
    async (cancelled: boolean): Promise<void> => {
      setClosing(true);
      await onClose({ cancelled: cancelled });
    },
    [onClose]
  );

  // cleanup function
  useEffect(() => {
    setClosing(false);
  }, [show]);

  const handleHide = useCallback(() => {
    doClose(true);
  }, [doClose]);
  const handleAccept = useCallback(() => {
    doClose(false);
  }, [doClose]);

  return (
    <Modal
      show={show}
      onHide={handleHide}
      backdrop="static"
      keyboard={!closing}
    >
      <Modal.Header>{caption}</Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {closing && <Spinner animation="border" />}
        <Button
          data-testid="confirm-btn-yes"
          variant="primary"
          disabled={closing}
          onClick={handleAccept}
        >
          {confirm}
        </Button>
        <Button variant="secondary" disabled={closing} onClick={handleHide}>
          {cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
