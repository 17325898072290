import React from "react";
import { Card } from "react-bootstrap";

const Feedback = (): JSX.Element => (
  <>
    <div className="text-center page-header">
      <h1>Feedback</h1>
      <small>Tell us what you think!</small>
    </div>
    <div className="container">
      <Card>
        <Card.Body>
          Feature requests, improvement suggestions and bug reports can all be
          submitted by:
          <p />
          <ul>
            <li>
              Posting topics to our{" "}
              <a href="https://groups.google.com/a/ozxcorp.com/forum/#!forum/connx.feedback">
                Google Group
              </a>
            </li>
            <li>
              Emailing <a href="mailto:connx.feedback@ozxcorp.com">us</a>
            </li>
          </ul>
          Tip: Ensure your feedback is detailed, that way we can act on it
          sooner!
        </Card.Body>
      </Card>
    </div>
  </>
);

export default Feedback;
