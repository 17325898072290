import React from "react";
import "./Home.css";
import { Redirect } from "react-router-dom";

interface Props {
  userID: string;
  isAuthenticated: boolean;
}

const Home: React.FC<Props> = (props) => {
  if (props.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: `/user/${props.userID}/orders`,
        }}
      />
    );
  }

  return (
    <div className="Home">
      <div className="lander">
        <p>Welcome to ConnX!</p>
        <p>
          ConnX is currently under development and account creation is by
          invitation only.
        </p>
        <p>
          Please register any expression of interest at{" "}
          <a href="mailto:contact@ozxcorp.com">contact@ozxcorp.com.</a>
        </p>
        <div className="copyright">
          <p>
            {"Copyright "}
            <a
              href="https://ozxcorp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              OzXCorp
            </a>
            {" 2020."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
