import { ConnXClient as GraphQLClient } from "../apolloClient";
import gql from "graphql-tag";

interface CreateRVModelInput {
  name: string;
  desc: string;
}

export interface RVModel {
  groupID: string;
  docID: string;
  name: string;
  desc: string;
  revision: number;
}

interface ListRVModelsInput {
  maxResults?: number;
  nextKey?: string;
  filter?: string;
}

interface ListRVModelsOutput {
  models: RVModel[];
  nextKey?: string;
}

interface UpdateRVModelInput {
  name?: string;
  desc?: string;
}

const fragments = {
  rvmodel: gql`
    fragment rvmodel on RVModel {
      groupID
      docID
      name
      desc
      revision
    }
  `,
};

class RVModelAPI {
  async get(groupID: string, modelID: string): Promise<RVModel> {
    const GQL = gql`
      query($groupID: ID!, $docID: ID!) {
        getRVModel(groupID: $groupID, docID: $docID) {
          ...rvmodel
        }
      }
      ${fragments.rvmodel}
    `;
    interface Output {
      getRVModel: RVModel;
    }
    const r = await GraphQLClient.query<Output>({
      query: GQL,
      variables: { groupID: groupID, docID: modelID },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.getRVModel;
  }

  async update(model: RVModel, changes: UpdateRVModelInput): Promise<RVModel> {
    const GQL = gql`
      mutation(
        $groupID: ID!
        $docID: ID!
        $revision: Int!
        $input: UpdateRVModelInput!
      ) {
        updateRVModel(
          groupID: $groupID
          docID: $docID
          revision: $revision
          input: $input
        ) {
          ...rvmodel
        }
      }
      ${fragments.rvmodel}
    `;
    interface Output {
      updateRVModel: RVModel;
    }
    const r = await GraphQLClient.mutate<Output>({
      mutation: GQL,
      variables: {
        groupID: model.groupID,
        docID: model.docID,
        revision: model.revision,
        input: changes,
      },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.updateRVModel;
  }

  async delete(groupID: string, modelID: string): Promise<boolean> {
    const GQL = gql`
      mutation($groupID: ID!, $docID: ID!) {
        deleteRVModel(groupID: $groupID, docID: $docID)
      }
    `;
    interface Output {
      deleteRVModel: boolean;
    }
    const r = await GraphQLClient.mutate<Output>({
      mutation: GQL,
      variables: {
        groupID: groupID,
        docID: modelID,
      },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.deleteRVModel;
  }

  async createRVModel(input: CreateRVModelInput): Promise<RVModel> {
    const GQL = gql`
      mutation($input: CreateRVModelInput!) {
        createRVModel(input: $input) {
          ...rvmodel
        }
      }
      ${fragments.rvmodel}
    `;
    interface Output {
      createRVModel: RVModel;
    }
    const r = await GraphQLClient.mutate<Output>({
      mutation: GQL,
      variables: { input: input },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.createRVModel;
  }

  async listRVModels(params?: ListRVModelsInput): Promise<ListRVModelsOutput> {
    const GQL = gql`
      query($params: ListRVModelsParams) {
        listRVModels(params: $params) {
          models {
            ...rvmodel
          }
          nextKey
        }
      }
      ${fragments.rvmodel}
    `;
    interface Output {
      listRVModels: ListRVModelsOutput;
    }
    const r = await GraphQLClient.query<Output>({
      query: GQL,
      variables: {
        params: params,
      },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.listRVModels;
  }
}

export default RVModelAPI;
