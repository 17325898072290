import React, { useState, useEffect, useCallback } from "react";
import PaginatedList, {
  DataSourceOutput,
  ListRendererProps,
  Item,
} from "./PaginatedList";
import "./PaginatedSearchList.css";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export interface BaseFilter {
  filter: string;
  refreshKey: number;
}

// defines data source context
export interface DataSourceOptions<TFilterValue extends BaseFilter> {
  filter: string; // deprecated; prefer use of BaseFilter.filter
  nextKey?: string;
  filterParams: TFilterValue;
}

// defines a data source
export interface DataSource<TItem, TFilterValue extends BaseFilter> {
  (input: DataSourceOptions<TFilterValue>):
    | Promise<DataSourceOutput<TItem>>
    | DataSourceOutput<TItem>;
}

interface RenderItemProps<TItem> {
  item: TItem;
}

export interface CustomFilterComponentProps<TFilterValue extends BaseFilter> {
  disabled: boolean;
  onDisableSearch: (disable: boolean) => void;
  value: TFilterValue;
  onChange: (v: TFilterValue) => void;
}

export interface PaginatedSearchListProps<
  TItem extends Item,
  TFilterValue extends BaseFilter
> {
  getItems: DataSource<TItem, TFilterValue>;
  renderItem?: (props: RenderItemProps<TItem>) => JSX.Element;
  refresh?: string | number;
  ListRenderer?: React.ComponentType<ListRendererProps<TItem>>;
  CustomFilters?: React.ComponentType<CustomFilterComponentProps<TFilterValue>>;
  hideSearchField?: boolean;
  children?: any;
}

const dataSourceWrapper = <TItem extends Item, TFilterValue extends BaseFilter>(
  ds: DataSource<TItem, TFilterValue>,
  filter: TFilterValue
) => (
  nextKey?: string
): DataSourceOutput<TItem> | Promise<DataSourceOutput<TItem>> =>
  ds({ filter: filter.filter, nextKey, filterParams: filter });

const PaginatedSearchList = <
  TItem extends Item,
  TFilterValue extends BaseFilter = BaseFilter
>({
  getItems,
  renderItem,
  refresh = undefined,
  ListRenderer,
  CustomFilters,
  hideSearchField = false,
  ...rest
}: PaginatedSearchListProps<TItem, TFilterValue>): JSX.Element => {
  const DEFAULT_FILTER = { filter: "", refreshKey: 0 } as TFilterValue;

  // filter logic state management
  const [filter, setFilter] = useState<TFilterValue>(DEFAULT_FILTER);
  const [curFilter, setCurFilter] = useState<TFilterValue>(DEFAULT_FILTER);
  const [loading, setLoading] = useState(false);
  const [dataSourceFunc, setDataSourceFunc] = useState({
    func: dataSourceWrapper(getItems, filter),
  });
  const [disableSearch, setDisableSearch] = useState(false);
  const onSetLoading = useCallback((l) => setLoading(l), []);

  // update data source when filter changes or data source changes
  useEffect(() => {
    setDataSourceFunc({ func: dataSourceWrapper(getItems, filter) });
  }, [filter, getItems]);

  // handle filter form submit
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      setFilter((f) => ({
        ...curFilter,
        // refresh key ensures a refresh occurs even if the filter value does not change
        refreshKey: f.refreshKey + 1,
      }));
    },
    [curFilter]
  );

  // handle filter change
  const handleFilterChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const filter = e.target.value;
    setCurFilter((f) => ({
      ...f,
      filter,
    }));
  };

  return (
    <div className="PaginatedList">
      <Form onSubmit={handleSubmit}>
        {CustomFilters && (
          <Form.Group>
            <CustomFilters
              disabled={loading}
              onDisableSearch={setDisableSearch}
              value={filter}
              onChange={setCurFilter}
            />
          </Form.Group>
        )}
        {!hideSearchField && (
          <Form.Group>
            <Form.Control
              type="text"
              value={curFilter.filter}
              onChange={handleFilterChange}
              placeholder="Enter filter"
            />
          </Form.Group>
        )}
        <Button
          variant="primary"
          type="submit"
          disabled={loading || disableSearch}
        >
          <FontAwesomeIcon icon={faSearch} title="Search" />
        </Button>
      </Form>
      <br />
      <PaginatedList
        dataSource={dataSourceFunc.func}
        renderItem={renderItem}
        setLoading={onSetLoading}
        ListRenderer={ListRenderer}
        refreshToken={refresh}
        {...rest}
      />
    </div>
  );
};

export default PaginatedSearchList;
