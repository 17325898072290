import Amplify from "aws-amplify";
import getConfig from "./config";

const cfg = getConfig();

function createAWSConfig() {
  return {
    // authentication configuration
    Auth: {
      mandatorySignIn: true,
      region: cfg.cognito.REGION,
      userPoolId: cfg.cognito.USER_POOL_ID,
      userPoolWebClientId: cfg.cognito.APP_CLIENT_ID,
      identityPoolId: cfg.cognito.IDENTITY_POOL_ID,
    },
  };
}

// Configure AWS Amplify
export function amplifyConfigure() {
  // configure Amplify
  Amplify.configure(createAWSConfig());

  // AWS pinpoint configuration
  // NOTE: pinpoint disabled until we work out what we
  //       want to do with it.
  // if (!isLocalhost && cfg.pinpoint) {

  //     const cfgAnalytics = async () => {
  //         // configure the analytics service
  //         let analyticsConfig = {
  //             AWSPinpoint: {
  //                 appId: cfg.pinpoint.appId,
  //                 region: cfg.pinpoint.region,
  //                 mandatorySignIn: false,
  //             }
  //         }
  //         Analytics.configure(analyticsConfig)

  //         // enable auto-tracking of page views
  //         Analytics.autoTrack('pageView', {
  //             enable: true,
  //             type: 'SPA',
  //         });
  //     }

  //     // run as background task
  //     if ('requestIdleCallback' in window) {
  //         // requestIdleCallback supported
  //         requestIdleCallback(cfgAnalytics);
  //     }
  //     else {
  //         // no support
  //         setTimeout(cfgAnalytics, 1);
  //     }
  // }
}
