import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import { FormControl, FormHelperText } from "@material-ui/core";

const MAX = 90;

export const validMinSOC = (
  minSOC: number | string | Array<number | string>
): boolean => {
  if (typeof minSOC !== "number") {
    return false;
  }

  if (!Number.isInteger(minSOC)) {
    return false;
  }

  return 0 < minSOC && MAX >= minSOC;
};

const useStyles = makeStyles({
  root: {
    width: 250,
  },
  input: {
    width: 54,
  },
});

export const MinSOCSlider = ({
  value,
  onChange,
}: {
  value: number | string | Array<number | string>;
  onChange: (minSOC: number | string | Array<number | string>) => void;
}): JSX.Element => {
  const classes = useStyles();

  const handleSliderChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | number[]
  ): void => {
    onChange(newValue);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChange(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = (): void => {
    if (value < 0) {
      onChange(0);
    } else if (value > MAX) {
      onChange(MAX);
    }
  };

  const error = !validMinSOC(value);

  return (
    <div className={classes.root}>
      <Typography id="input-slider" gutterBottom>
        Minimum State of Charge
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography gutterBottom>%</Typography>
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value === "number" ? value : 0}
            max={MAX}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <FormControl error={error}>
            <Input
              className={classes.input}
              value={value}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 10,
                min: 0,
                max: MAX,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
              aria-describedby="min-soc-slider-error-text"
            />
            <FormHelperText id="min-soc-slider-error-text">
              {">"} 0, ≤ {MAX}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
