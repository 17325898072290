import React from "react";
import {
  canRead,
  Scope,
  isUserInGroup,
  anyGranted,
  isAuthenticated,
} from "../../user/user";
import { NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { RBACPermission } from "../../user/rbac";

interface ConnXMenuProps {
  authenticated?: () => boolean;
  hasReadAccess?: (s: Scope) => boolean;
  userIsInGroup?: () => boolean;
  hasPermission?: (p: RBACPermission) => boolean;
}

const ConnXMenu: React.FC<ConnXMenuProps> = (props) => {
  const {
    hasReadAccess = canRead,
    userIsInGroup = isUserInGroup,
    hasPermission = anyGranted,
    authenticated = isAuthenticated,
  } = props;

  if (!authenticated()) {
    return null;
  }

  const userInGroup = userIsInGroup(),
    showDeviceMenu = hasPermission("device_read"),
    showDeviceCfgMenu = hasPermission("device_cfg_read"),
    showFirmwareMenu = hasPermission("fw_list"),
    showModelOrderMenu =
      userInGroup &&
      (hasPermission("rv_model_read") || hasPermission("rv_order_read")),
    showEventMenu = userInGroup && hasReadAccess("connx.iot.events");

  return showDeviceMenu ||
    showDeviceCfgMenu ||
    showFirmwareMenu ||
    showModelOrderMenu ||
    showEventMenu ? (
    <NavDropdown title="ConnX" id="collasible-nav-dropdown">
      {showDeviceMenu && (
        <LinkContainer to="/connx/devices">
          <NavDropdown.Item>Devices</NavDropdown.Item>
        </LinkContainer>
      )}
      {showFirmwareMenu && (
        <LinkContainer to="/connx/fw">
          <NavDropdown.Item>Firmware</NavDropdown.Item>
        </LinkContainer>
      )}
      {showDeviceCfgMenu && (
        <LinkContainer to="/connx/config/defaults">
          <NavDropdown.Item>Configuration</NavDropdown.Item>
        </LinkContainer>
      )}
      {showModelOrderMenu && (
        <LinkContainer to="/connx/rvmodels">
          <NavDropdown.Item>Models and orders</NavDropdown.Item>
        </LinkContainer>
      )}
      {showEventMenu && (
        <LinkContainer to="/connx/iot/events">
          <NavDropdown.Item>System events</NavDropdown.Item>
        </LinkContainer>
      )}
    </NavDropdown>
  ) : null;
};

export default ConnXMenu;
