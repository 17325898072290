import React, { useEffect, ChangeEvent, useReducer, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

interface LinkModalDialogBaseClosed {
  cancelled?: boolean;
  err?: Error | string;
}

interface LinkModalDialogBaseProps {
  onClose?: (e: LinkModalDialogBaseClosed) => void;
  open: boolean;
  title: string;
  text: string;
  submitLabel: string;
  validator: (text: string) => boolean;
  submit: (text: string) => Promise<void>;
}

const LinkModalDialogBase = ({
  open,
  onClose,
  title,
  text,
  validator,
  submit,
  submitLabel,
}: LinkModalDialogBaseProps): JSX.Element => {
  interface State {
    thingName: string;
    validThingName: boolean;
  }

  const [state, setState] = useReducer(
    (_prev: State, thingName: string): State => {
      return {
        thingName,
        validThingName: validator(thingName),
      };
    },
    {
      thingName: "",
      validThingName: false,
    }
  );

  const [submitting, setSubmitting] = useState(false);

  const handleLinkClick = async (): Promise<void> => {
    try {
      setSubmitting(true);
      await submit(state.thingName);
      onClose?.({});
    } catch (e) {
      onClose?.({ err: e });
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = (): void => {
    onClose?.({ cancelled: true });
  };

  const handleThingNameChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setState(e.target.value);
  };

  // reset form state whenever open changes
  useEffect(() => {
    setState("");
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="iot-thing-name"
          label="ConnX device name"
          fullWidth
          value={state.thingName}
          onChange={handleThingNameChange}
          disabled={submitting}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={submitting}>
          Cancel
        </Button>
        <Button
          onClick={handleLinkClick}
          color="primary"
          disabled={!state.validThingName || submitting}
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const LINK_THING_MUTATION = gql`
  mutation LinkThing($input: LinkThingInput!) {
    linkThing(input: $input) {
      thingName
    }
  }
`;

export type LinkModalClosed = LinkModalDialogBaseClosed;

export interface LinkModalProps {
  modelID?: string;
  orderID?: string;
  open: boolean;
  onClose?: (e: LinkModalClosed) => void;
}

export const LinkModal = ({
  orderID,
  modelID,
  open,
  onClose,
}: LinkModalProps): JSX.Element => {
  const [mutate] = useMutation(LINK_THING_MUTATION);

  const handleSubmit = async (text: string): Promise<void> => {
    await mutate({
      variables: {
        input: {
          orderID: orderID,
          modelID: modelID,
          thingName: text,
        },
      },
    });
  };

  return (
    <LinkModalDialogBase
      title="Link to ConnX device"
      text="Please enter the name of the ConnX device to link to this order."
      open={open}
      validator={(text: string): boolean => "" !== text}
      submit={handleSubmit}
      onClose={onClose}
      submitLabel="Link"
    />
  );
};

export const UNLINK_THING_MUTATION = gql`
  mutation UnlinkThing($input: UnlinkThingInput!) {
    unlinkThing(input: $input) {
      thingName
    }
  }
`;

export type UnlinkModalClosed = LinkModalDialogBaseClosed;

export interface UnlinkModalProps {
  modelID?: string;
  orderID?: string;
  thingName: string;
  open: boolean;
  onClose?: (e: UnlinkModalClosed) => void;
}

export const UnlinkModal = ({
  open,
  onClose,
  thingName,
  orderID,
  modelID,
}: UnlinkModalProps): JSX.Element => {
  const [mutate] = useMutation(UNLINK_THING_MUTATION);

  const handleSubmit = async (text: string): Promise<void> => {
    await mutate({
      variables: {
        input: {
          orderID: orderID,
          modelID: modelID,
          thingName: text,
        },
      },
    });
  };

  return (
    <LinkModalDialogBase
      title="Unlink ConnX device"
      text={`Please confirm you wish to unlink this device by entering the text '${thingName}'.`}
      open={open}
      validator={(text: string): boolean => text === thingName}
      submit={handleSubmit}
      onClose={onClose}
      submitLabel="Unlink"
    />
  );
};
