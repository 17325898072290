import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  TextField,
  Button,
} from "@material-ui/core";
import validator from "validator";
import deepEqual from "deep-equal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(0),
    },
    button: {
      margin: theme.spacing(3, 1, 0, 0),
    },
  })
);

export interface DCXAlertsConfig {
  batterySOCCritical: boolean;
  batteryUsageHigh: boolean;
  generatorFailToStart: boolean;
  phoneNumber: string;
}

interface State {
  cfg: DCXAlertsConfig;
  phoneError: boolean;
}

interface ActionPhone {
  action: "phone";
  number: string;
}

interface ActionBatterySOC {
  action: "battery_soc";
  checked: boolean;
}

interface ActionBatteryUsage {
  action: "battery_usage";
  checked: boolean;
}

interface ActionGeneratorFailToStart {
  action: "generator_fail_to_start";
  checked: boolean;
}

type Action =
  | ActionPhone
  | ActionBatterySOC
  | ActionBatteryUsage
  | ActionGeneratorFailToStart;

const useDCXAlertState = (
  refCfg: DCXAlertsConfig
): [State, React.Dispatch<Action>] => {
  return React.useReducer(
    (prev: State, action: Action): State => {
      switch (action.action) {
        case "phone": {
          const phone = action.number;
          // empty? no worries
          if (phone.trim() === "") {
            return {
              ...prev,
              cfg: {
                ...prev.cfg,
                phoneNumber: "",
              },
              phoneError: false,
            };
          }
          // validate mobile number format
          const valid = validator.isMobilePhone(phone, "any", {
            strictMode: true,
          });
          return {
            ...prev,
            cfg: {
              ...prev.cfg,
              phoneNumber: phone,
            },
            phoneError: !valid,
          };
        }
        case "battery_soc": {
          return {
            ...prev,
            cfg: {
              ...prev.cfg,
              batterySOCCritical: action.checked,
            },
          };
        }
        case "battery_usage": {
          return {
            ...prev,
            cfg: {
              ...prev.cfg,
              batteryUsageHigh: action.checked,
            },
          };
        }
        case "generator_fail_to_start": {
          return {
            ...prev,
            cfg: {
              ...prev.cfg,
              generatorFailToStart: action.checked,
            },
          };
        }
      }
    },
    {
      cfg: refCfg,
      phoneError: false,
    }
  );
};

export const DCXAlerts: React.FC<{
  initialState: DCXAlertsConfig;
  onSubmit?: (cfg: DCXAlertsConfig) => void | Promise<void>;
  onSubmitError?: (e: unknown) => void;
}> = ({ initialState, onSubmit, onSubmitError }) => {
  const classes = useStyles();

  const [refCfg, setRefCfg] = React.useState(initialState);
  const [state, setState] = useDCXAlertState(initialState);
  const [submitting, setSubmitting] = React.useState(false);

  const handleBatterySOCChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setState({
      action: "battery_soc",
      checked: event.target.checked,
    });
  };

  const handleBatteryUsageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setState({
      action: "battery_usage",
      checked: event.target.checked,
    });
  };

  const handleGeneratorFailToStartChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setState({
      action: "generator_fail_to_start",
      checked: event.target.checked,
    });
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setState({
      action: "phone",
      number: event.target.value,
    });
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setSubmitting(true);
      await onSubmit?.(state.cfg);
      setRefCfg(state.cfg);
    } catch (e) {
      if (undefined === onSubmitError) {
        console.log(e);
      } else {
        onSubmitError(e);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const {
    batterySOCCritical,
    batteryUsageHigh,
    generatorFailToStart,
  } = state.cfg;
  const isSame = deepEqual(refCfg, state.cfg);

  return (
    <div className={classes.root}>
      <FormControl
        component="fieldset"
        className={classes.formControl}
        disabled={submitting}
      >
        <FormLabel component="legend">DCX alerts</FormLabel>
        <FormHelperText>
          Only applies to orders owned by current user
        </FormHelperText>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={batterySOCCritical}
                onChange={handleBatterySOCChange}
                name="Battery SOC critical"
              />
            }
            label="Battery SOC critical"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={batteryUsageHigh}
                onChange={handleBatteryUsageChange}
                name="High battery usage"
              />
            }
            label="High battery usage"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={generatorFailToStart}
                onChange={handleGeneratorFailToStartChange}
                name="Generator failed to start"
              />
            }
            label="Generator failed to start"
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id="standard-basic"
            label="Phone number"
            variant="outlined"
            helperText={
              state.phoneError
                ? "Please enter a valid international mobile phone number. E.g. +61403111222"
                : undefined
            }
            value={state.cfg.phoneNumber}
            error={state.phoneError}
            onChange={handlePhoneNumberChange}
            disabled={submitting}
          />
        </FormGroup>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={state.phoneError || isSame}
          className={classes.button}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </FormControl>
    </div>
  );
};
