import React from "react";
import { FallbackProps } from "react-error-boundary";
import getConfig from "../config";

export function dumpInfo(): void {
  const { ciRef, ciRevision } = getConfig();
  console.log(`ConnX ${ciRef}:${ciRevision}.`);
}

export const errorBoundaryHandler = (): void => {
  // try {
  //   Analytics.record({
  //     name: 'route-error-boundary',
  //     attributes: {
  //       error: error,
  //       componentStack: componentStack,
  //     }
  //   })
  // } catch (e) {
  //   console.log(e)
  // }
};

export const ErrorFallbackComponent: React.FC<FallbackProps> = ({
  componentStack,
  error,
}) => (
  <div>
    <p>
      <strong>Oops! An error occurred!</strong>
    </p>
    <p>Here’s what we know…</p>
    {error && (
      <p>
        <strong>Error:</strong> {error?.toString()}
      </p>
    )}
    {componentStack && (
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    )}
  </div>
);
