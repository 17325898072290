import React from "react";
import FirmwareList from "./FirmwareList";
import FirmwareUpload from "./FirmwareUpload";
import { Tabs, Tab } from "react-bootstrap";
import { anyGranted } from "../../user/user";
import Client, { ListFirmwareOutput } from "../../api/connx";
import { RBACPermission } from "../../user/rbac";

interface FirmwareViewProps {
  getFirmware?: (
    filter?: string
  ) => Promise<ListFirmwareOutput> | ListFirmwareOutput;
  hasPermission?: (p: RBACPermission) => boolean;
}

/*
A view where the user can:

- upload firmware
- view a list if existing firmware
*/
const FirmwareView: React.FC<FirmwareViewProps> = (props) => {
  const {
    getFirmware = Client.listFirmware,
    hasPermission = anyGranted,
  } = props;

  return (
    <>
      <div className="text-center page-header">
        <h1>ConnX Firmware</h1>
        <small>Manage device firmware</small>
      </div>
      <div className="container">
        <Tabs id="connx-firmware-configuration-tabs">
          <Tab
            eventKey="list-firmwares"
            title="List"
            disabled={!hasPermission("fw_list")}
          >
            <br />
            <FirmwareList getFirmware={getFirmware} />
          </Tab>
          <Tab eventKey="upload-firmwares" title="Upload" disabled={true}>
            {/* This is pending an upload REST endpoint that can access the binary, produce a digital signature and store in S3 */}
            <br />
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
            <FirmwareUpload onUpload={(): void => {}} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default FirmwareView;
