import React, { useCallback, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { RVModelOrder } from "../../../api/connx/rvmodelorder";
import { RVModelOrderClient } from "../../../api/api";
import { ValueType } from "react-select";

export interface RVModelOrderOption {
  value: string;
  label: string;
  data: RVModelOrder;
}

interface RVModelOrderSelectionProps {
  onChange: (v?: RVModelOrder) => void;
  modelID?: string;
  disabled?: boolean;
  loadOptions?: (
    modelID: string,
    inputValue?: string
  ) => Promise<RVModelOrderOption[]>;
}

const rvModelOrderToOption = (v: RVModelOrder): RVModelOrderOption => ({
  value: v.docID,
  label: v.name,
  data: v,
});

const loadRVModelOrders = async (
  modelID: string,
  inputValue?: string
): Promise<RVModelOrderOption[]> => {
  try {
    const r = await RVModelOrderClient.listRVOrders(modelID, {
      filterName: inputValue,
    });
    return r.models.map(rvModelOrderToOption);
  } catch (e) {
    return [];
  }
};

const RVModelOrderSelection = ({
  onChange,
  modelID,
  disabled = false,
  loadOptions = loadRVModelOrders,
}: RVModelOrderSelectionProps): JSX.Element => {
  // manage state of selected order so we can reset
  // it when the model changes
  const [orderID, setOrderID] = useState<RVModelOrderOption | null>(null);

  // handle selection
  const handleChange = (v: ValueType<RVModelOrderOption>): void => {
    setOrderID(v as RVModelOrderOption);
  };

  // handle order loading
  const handleLoad = useCallback(
    async (inputValue: string) => {
      return modelID
        ? loadOptions(modelID, inputValue !== "" ? inputValue : undefined)
        : [];
    },
    [loadOptions, modelID]
  );

  // reset selected order if model changes
  useEffect(() => {
    setOrderID(null);
  }, [modelID]);

  // invoke 'onChange' if order changes
  useEffect(() => {
    onChange(orderID ? orderID.data : undefined);
  }, [orderID, onChange]);

  return (
    <AsyncSelect
      key={modelID}
      defaultOptions={true}
      cacheOptions
      onChange={handleChange}
      loadOptions={handleLoad}
      isDisabled={disabled}
      placeholder={"Search orders"}
      value={orderID}
    />
  );
};

export default RVModelOrderSelection;
