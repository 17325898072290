import React, { useCallback } from "react";
import { MenuItem, Menu, IconButton } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import { GenOverride } from "./GenOverride";
import { useUser } from "../../../../userContext";
import {
  SuccessFailureToasty,
  useSuccessFailureToasty,
} from "../../../../components";
import { FormResult } from "./GenOverrideForm";
import moment from "moment";
import gql from "graphql-tag";
import { useOrder } from "../../../../helpers/orderContext";

export interface OnDCXDashEvent {
  orderID: string;
}

interface Props {
  onDCXDash?: (e: OnDCXDashEvent) => Promise<void> | void;
}

export const GEN_OVERRIDE_MUTATION = gql`
  mutation updateRemoteGeneratorSOCOverride(
    $orderID: ID!
    $minSOC: Int!
    $expiry: String!
  ) {
    updateRemoteGeneratorSOCOverride(
      orderID: $orderID
      minSOC: $minSOC
      expiry: $expiry
    ) {
      minSOC
      expiry
    }
  }
`;

export const ActionMenu: React.FC<Props> = ({ onDCXDash }) => {
  const { user } = useUser();
  const { docID: orderID, isOwner, hasThing } = useOrder();

  // Dialog open/close
  const [overrideOpen, setOverrideOpen] = React.useState(false);

  const handleGenOverride = (): void => {
    setOverrideOpen(true);
  };
  const handleOverrideClose = (): void => {
    setOverrideOpen(false);
  };

  //
  const handleDCXDash = useCallback(() => {
    onDCXDash?.({ orderID });
  }, [onDCXDash, orderID]);

  // Popup menu anchor
  const [
    actionAnchorEl,
    setActionAnchorEl,
  ] = React.useState<null | HTMLElement>(null);

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleActionClose = (): void => {
    setActionAnchorEl(null);
  };

  //
  const [mutate] = useMutation(GEN_OVERRIDE_MUTATION);

  // snackbar state
  const {
    props: toastyProps,
    setSuccess: setToastySuccess,
    setFailure: setToastyFailure,
  } = useSuccessFailureToasty();

  // Update pressed.
  const handleUpdate = async (data: FormResult): Promise<void> => {
    handleOverrideClose();
    if (data.expiry.valueOf() <= Date.now()) {
      setToastyFailure("Failed, expiry must be in the future");
      return;
    }

    // Send values to the backend.
    const r = await mutate({
      variables: {
        orderID: orderID,
        minSOC: data.minSOC,
        expiry: moment(data.expiry).format(),
      },
    });
    if (!r.data) {
      setToastyFailure("Failed to set Generator Override");
    } else {
      setToastySuccess("Set Generator Override OK");
    }
  };

  // Clear pressed.
  const handleClear = async (): Promise<void> => {
    handleOverrideClose();

    // Send values to the backend.
    const r = await mutate({
      variables: {
        orderID: orderID,
        minSOC: 0,
        expiry: moment(new Date(Date.now())).format(),
      },
    });
    if (!r.data) {
      setToastyFailure("Failed to clear Generator Override");
    } else {
      setToastySuccess("Clear Generator Override OK");
    }
  };

  return (
    <React.Fragment>
      <IconButton
        aria-controls="action-menu"
        aria-haspopup="true"
        onClick={handleActionClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="action-menu"
        anchorEl={actionAnchorEl}
        keepMounted
        open={Boolean(actionAnchorEl)}
        onClose={handleActionClose}
      >
        <MenuItem
          onClick={(): void => {
            handleActionClose();
            handleDCXDash();
          }}
        >
          Details
        </MenuItem>
        {isOwner &&
          hasThing &&
          user.features.hasFeature("web.dcx.generator.job.start") && (
            <MenuItem
              onClick={(): void => {
                handleActionClose();
                handleGenOverride();
              }}
            >
              Generator Override
            </MenuItem>
          )}
      </Menu>
      {overrideOpen && (
        <GenOverride
          orderID={orderID}
          open={overrideOpen}
          onClose={handleOverrideClose}
          onUpdate={handleUpdate}
          onClear={handleClear}
        ></GenOverride>
      )}
      <SuccessFailureToasty
        successMessage="Updated OK"
        failureMessage="Failed to update"
        {...toastyProps}
      />
    </React.Fragment>
  );
};
