import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import { UserClient } from "./api/apolloClient";
import { AdminGetUsers, AdminGetUser, UpdateUser } from "./api/user";
import {
  CreateUserGroup,
  GetUserGroup,
  GetUserGroups,
  UpdateUserGroup,
  DeleteUserGroup,
  addUser,
  removeUser,
} from "./api/usergroup";
import SystemEventView from "./containers/connx/SystemEventView";
import HelpRoutes from "./routes/HelpRoutes";
import UserRoutes from "./routes/UserRoutes";
import { ApolloProvider } from "@apollo/react-hooks";
import DCX from "./routes/DCX";
import { useUser } from "./userContext";
import { Loading } from "./components";
import Login from "./containers/Login";
import HeatMap from "./containers/maps/HeatMap";
import ConfigurationView from "./containers/connx/ConfigurationView";
import AdminUserList from "./containers/admin/AdminUserList";
import AdminUserGroupList from "./containers/admin/AdminUserGroupList";
import EditUserGroup from "./containers/admin/EditUserGroup";
import AdminuserEdit from "./containers/admin/AdminUserEdit";
import FirmwareView from "./containers/connx/FirmwareView";
import RegisteredDevicesView from "./containers/connx/RegisteredDevicesView";
import DeviceConfigurationEdit from "./containers/connx/DeviceConfigurationEdit";
import EditRegisteredDevice from "./containers/connx/EditRegisteredDevice";
import EditDefaultConfig from "./containers/connx/registeredDevice/EditDefaultConfig";
import RVModelView from "./containers/connx/rvmodel/RVModelView";
import RVModelEdit from "./containers/connx/rvmodel/RVModelEdit";
import RVModelOrderEdit from "./containers/connx/rvmodel/RVModelOrderEdit";

// FIXME: break-up this into sub-route components (e.g. HelpRoutes.js)
const Routes = ({ childProps }) => {
  const { user } = useUser();

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <AppliedRoute
          path="/"
          exact
          component={() => (
            <Home userID={user.id} isAuthenticated={user.isAuthenticated} />
          )}
          props={childProps}
        />
        <AppliedRoute
          path="/login"
          exact
          component={Login}
          props={childProps}
        />
        <UserRoutes path="/user" component={UserRoutes} props={childProps} />
        <DCX path="/dcx" component={UserRoutes} props={childProps} />

        {/* admin users */}
        <AppliedRoute
          path="/admin/users"
          exact
          component={({ history }) => (
            <AdminUserList
              {...childProps}
              getUsers={AdminGetUsers}
              onEditUser={(user) => {
                history.push(`/admin/users/edit/${user.id}`);
              }}
            />
          )}
        />
        <AppliedRoute
          path="/admin/users/edit/:userId"
          exact
          component={({
            match: {
              params: { userId = "" },
            },
          }) => (
            <ApolloProvider client={UserClient}>
              <AdminuserEdit
                {...childProps}
                userId={userId}
                GetUser={async (userId) => {
                  const r = await AdminGetUser(userId);
                  return r.user;
                }}
                UpdateUser={UpdateUser}
              />
            </ApolloProvider>
          )}
        />

        {/* admin groups */}
        <AppliedRoute
          path="/admin/usergroups"
          exact
          component={({ history }) => (
            <AdminUserGroupList
              {...childProps}
              getItem={GetUserGroup}
              getItems={GetUserGroups}
              deleteItem={DeleteUserGroup}
              createItem={CreateUserGroup}
              updateItem={UpdateUserGroup}
              onEdit={(id) => history.push(`/admin/usergroups/edit/${id}`)}
            />
          )}
        />
        <AppliedRoute
          path="/admin/usergroups/edit/:groupId"
          exact
          component={({
            history,
            match: {
              params: { groupId = "" },
            },
          }) => (
            <EditUserGroup
              {...childProps}
              groupId={groupId}
              onSaveUserGroupDetails={UpdateUserGroup}
              getUserGroup={GetUserGroup}
              getGroupUsers={(groupId, nextKey) => {
                return AdminGetUsers({ groupID: groupId, nextKey });
              }}
              findUsers={({ filter }) => {
                return AdminGetUsers({ filterUsernameLike: filter });
              }}
              addUser={addUser}
              removeUser={removeUser}
              onDone={() => {
                history.goBack();
              }}
            />
          )}
        />

        {/* ConnX - firmware */}
        <AppliedRoute
          path="/connx/fw"
          exact
          component={FirmwareView}
          props={childProps}
        />

        {/* Connx - registered devices */}
        <AppliedRoute
          path="/connx/devices"
          exact
          component={({ history }) => (
            <RegisteredDevicesView
              onEditDevice={(id) => history.push("/connx/devices/edit/" + id)}
              onAssignDeviceConfig={(id) =>
                history.push("/connx/devices/edit/defaultConfig/" + id)
              }
              {...childProps}
            />
          )}
          props={childProps}
        />
        <AppliedRoute
          path="/connx/devices/edit/:id"
          exact
          props={childProps}
          component={({
            history,
            match: {
              params: { id = "" },
            },
          }) => (
            <EditRegisteredDevice
              id={id}
              onDone={(e) => {
                if (e) {
                  console.log(e);
                  alert("Failed to load");
                }
                history.goBack();
              }}
            />
          )}
        />
        <AppliedRoute
          path="/connx/devices/edit/defaultConfig/:id"
          exact
          props={childProps}
          component={({
            history,
            match: {
              params: { id = "" },
            },
          }) => <EditDefaultConfig id={id} done={() => history.goBack()} />}
        />

        {/* Connx - device configuration */}
        <AppliedRoute
          path="/connx/config/defaults"
          exact
          component={ConfigurationView}
          props={childProps}
        />
        <AppliedRoute
          path="/connx/config/defaults/clone/:groupid/:cfgid/:versionid"
          exact
          props={childProps}
          component={({
            history,
            match: {
              params: { groupid = "", cfgid = "", versionid = "" },
            },
          }) => (
            <DeviceConfigurationEdit
              groupID={groupid}
              cfgID={cfgid}
              versionID={versionid}
              onDone={() => {
                history.goBack();
              }}
              onError={(e) => {
                console.log(e);
                alert("Failed to load");
                history.goBack();
              }}
            />
          )}
        />

        {/* Connx - RV models and orders */}
        <AppliedRoute
          path="/connx/rvmodels"
          exact
          component={RVModelView}
          props={childProps}
        />
        <AppliedRoute
          path="/connx/rvmodels/edit/model/:groupid/:modelid"
          exact
          component={({
            history,
            match: {
              params: { groupid = "", modelid = "" },
            },
          }) => (
            <RVModelEdit
              groupID={groupid}
              modelID={modelid}
              onDone={() => history.goBack()}
            />
          )}
          props={childProps}
        />
        <AppliedRoute
          path="/connx/rvmodels/edit/order/:modelid/:orderid"
          exact
          component={({
            history,
            match: {
              params: { modelid = "", orderid = "" },
            },
          }) => (
            <RVModelOrderEdit
              modelID={modelid}
              orderID={orderid}
              onDone={() => history.goBack()}
            />
          )}
          props={childProps}
        />

        {/* ConnX - IoT*/}
        <AppliedRoute
          path="/connx/iot/events"
          exact
          component={SystemEventView}
          props={childProps}
        />

        {/* Help */}
        <AppliedRoute path="/help" component={HelpRoutes} props={childProps} />

        {/* Retired, legacy, unused etc */}
        <AppliedRoute
          path="/maps/heatmap/:tripId"
          exact
          component={HeatMap}
          props={childProps}
        />

        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

Routes.displayName = "RootRouter";

export default Routes;
