import React from "react";
import { PageContainer } from "../../../components";
import { useAnchor } from "../../../helpers/useAnchor";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Alerts from "./Alerts";
import Reports from "./Reports";
import { useUser } from "../../../userContext";
import { ConnXClient } from "../../../api/apolloClient";
import { withApolloProvider } from "../../../helpers/apollo";

type Anchors = "alerts" | "reports";

const viewNames: Record<Anchors, string> = {
  alerts: "Alerts",
  reports: "Reports",
};

const Root: React.FC<{}> = () => {
  const { anchor, setAnchor } = useAnchor<Anchors>("alerts");
  const { user } = useUser();

  return (
    <PageContainer
      title="User settings"
      view={viewNames[anchor]}
      drawerChildren={
        <List>
          <ListItem button onClick={(): void => setAnchor("alerts")}>
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary={"Alerts"} />
          </ListItem>
          <ListItem button onClick={(): void => setAnchor("reports")}>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary={"Reports"} />
          </ListItem>
        </List>
      }
    >
      {anchor === "alerts" &&
      user.features.hasFeature("web.user.settings.alerts.dcx") ? (
        <Alerts />
      ) : (
        <div />
      )}
      {anchor === "reports" &&
      user.features.hasFeature("web.user.settings.reports.dcx") ? (
        <Reports />
      ) : (
        <div />
      )}
    </PageContainer>
  );
};

export default withApolloProvider(ConnXClient, Root);
