import React from "react";
import ReactDOM from "react-dom";
import { Router, withRouter } from "react-router-dom";
import App from "./App";
import { ServiceWorkerProvider } from "./useServiceWorker";
import { UserProvider } from "./userContext";
import { initAnalytics } from "./helpers/analytics";
import { RefreshToast } from "./components/RefreshToast";

const AppWithRouterAndReducer = withRouter(App);
const browserHistory = initAnalytics();

ReactDOM.render(
  <ServiceWorkerProvider>
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Router history={browserHistory}>
        <UserProvider>
          <AppWithRouterAndReducer />
        </UserProvider>
      </Router>
    </div>
    <div
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
      }}
    >
      <RefreshToast />
    </div>
  </ServiceWorkerProvider>,
  document.getElementById("root")
);
