import React, { useContext, useEffect, useReducer, ReactNode } from "react";
import { Hub, Auth } from "aws-amplify";
import { HubCapsule } from "@aws-amplify/core/lib/Hub";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { setCurUser } from "./user/user";

class FeatureSet {
  constructor(
    private readonly features: Record<string, boolean> = {} as Record<
      string,
      boolean
    >
  ) {}
  /**
   * Does the logged in user have the provided feature?
   *
   * @remarks
   * See https://gitlab.com/ozxcorp/rvodo-user-backend/-/blob/master/internal/pkg/features/features.go for feature sets.
   */
  hasFeature(feature: string): boolean {
    return true === this.features[feature];
  }
}

export interface User {
  id: string;
  isAuthenticated: boolean;
  groupID?: string;
  firstName: string;
  familyName: string;
  email: string;
  rbacRoles: string[];
  features: FeatureSet;
  // Deprecated: use RBAC roles.
  roles: string[];
}

export const defaultUserState: User = {
  id: "",
  isAuthenticated: false,
  roles: [],
  rbacRoles: [],
  firstName: "",
  familyName: "",
  email: "",
  features: new FeatureSet(),
};

export const UserContext = React.createContext<{
  user: User;
  checkingSession: boolean;
}>({
  user: defaultUserState,
  checkingSession: true,
});

export const UserProvider = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element => {
  const [checkingSession, setCheckingSession] = React.useState(true);

  // reducer for Cognito user session -> User state
  const [user, setUser] = useReducer(
    (_prevUser: User, session: CognitoUserSession | undefined) => {
      if (session === undefined) {
        return defaultUserState;
      }
      const payload = session.getIdToken().decodePayload();
      const rolesStr = payload.roles || "";
      const roles = rolesStr.split(",");
      const rbacStr = payload.rbac || "";
      const features = payload.features || "{}";
      return {
        id: payload.sub,
        isAuthenticated: true,
        roles: roles,
        groupID: payload.groupID,
        firstName: payload.name,
        familyName: payload.family_name,
        email: payload.email,
        rbacRoles: rbacStr.split(","),
        features: new FeatureSet(
          JSON.parse(features) as Record<string, boolean>
        ),
      };
    },
    defaultUserState
  );

  // subscribe to Amplify authentication events
  useEffect(() => {
    const authListener = (data: HubCapsule): void => {
      // the AWS Amplify React components do not provide
      // any error feedback (???). We can handle these here.
      // FIXME: use a material-ui toasty rather than a window alert.
      if (/.+_failure/.test(data.payload.event)) {
        if (data.payload.data.message) {
          alert(data.payload.data.message);
        } else {
          console.error(data);
        }
        return;
      }
      switch (data.payload.event) {
        case "signIn":
          setUser(data.payload.data.signInUserSession);
          break;
        case "signOut":
          setUser(undefined);
          break;
        default:
          break;
      }
    };
    const checkExistingSession = async (): Promise<void> => {
      try {
        setUser(await Auth.currentSession());
      } catch (e) {
        console.log(e);
      }
      setCheckingSession(false);
    };
    Hub.listen("auth", authListener);
    checkExistingSession();
  }, []);

  // HACK: set legacy global user state.
  setCurUser(user);

  // wrap children in user context provider
  return (
    <UserContext.Provider value={{ user, checkingSession }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): {
  user: User;
  checkingSession: boolean;
} => useContext(UserContext);
