import React, { useState } from "react";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { Button, Form, ButtonToolbar } from "react-bootstrap";

export interface FormValues {
  manufacturer: string;
  name: string;
  desc: string;
}

const schema = yup.object<FormValues>().shape({
  manufacturer: yup
    .string()
    .trim()
    .min(3)
    .max(50)
    .required("Manufacturer is required"),
  name: yup.string().trim().min(3).max(50).required("Name is required"),
  desc: yup.string().trim().min(3).max(300).required("Description is required"),
});

const defaultValues: FormValues = {
  name: "",
  desc: "",
  manufacturer: "",
};

interface RegisterDeviceProps {
  onSubmit: (values: FormValues) => Promise<void> | void;
  onRegistered?: () => void;
  submitButtonName?: string;
  initialValues?: FormValues;
  onCancel?: () => void;
}

const RegisterDevice = ({
  onSubmit,
  onRegistered,
  submitButtonName = "Register",
  initialValues = defaultValues,
  onCancel,
}: RegisterDeviceProps): JSX.Element => {
  const [values, setValues] = useState(initialValues);

  const handleFormSubmit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): Promise<void> => {
    try {
      await onSubmit(values);
      setValues(initialValues);
      resetForm();
      onRegistered && onRegistered();
    } catch (e) {
      console.log(e);
      alert(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={values}
      onSubmit={handleFormSubmit}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        isValid,
        resetForm,
        dirty,
      }): JSX.Element => (
        <Form
          noValidate
          onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
            handleSubmit(e as React.FormEvent<HTMLFormElement>)
          }
        >
          <Form.Group>
            <Form.Label>Manufacturer</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter manufacturer. E.g. OzXcorp"
              name="manufacturer"
              value={values.manufacturer}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={
                (touched.manufacturer || isValid) && !errors.manufacturer
              }
            />
            {errors.manufacturer && touched.manufacturer && (
              <Form.Text>{errors.manufacturer}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name. E.g. brakelight"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={(touched.name || isValid) && !errors.name}
            />
            {errors.name && touched.name && (
              <Form.Text>{errors.name}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter description"
              name="desc"
              value={values.desc}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={(touched.desc || isValid) && !errors.desc}
            />
            {errors.desc && touched.desc && (
              <Form.Text>{errors.desc}</Form.Text>
            )}
          </Form.Group>
          <ButtonToolbar>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || !isValid || !dirty}
            >
              {submitButtonName}
            </Button>
            {onCancel && (
              <Button
                className="ml-1"
                variant="secondary"
                onClick={(): void => {
                  const r = window.confirm("Are you sure?");
                  if (true === r) {
                    resetForm();
                    onCancel();
                  }
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            )}
          </ButtonToolbar>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterDevice;
export { schema, defaultValues };
