import React from "react";
import { Card } from "react-bootstrap";

const Terms: React.FC<{}> = () => (
  <>
    <div className="text-center page-header">
      <h1>Terms and conditions</h1>
    </div>
    <div className="container">
      <Card>
        <Card.Body>Coming soon!</Card.Body>
      </Card>
    </div>
  </>
);

export default Terms;
