import React, { useState, useCallback } from "react";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { Button, Form, ButtonToolbar } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { RVModel } from "../../../api/connx/rvmodel";
import {
  loadRVModelOptions,
  RVModelOption,
  rvModelToOption,
} from "./RVModelSelection";
import { ValueType } from "react-select";

export interface RVModelOrderFormValues {
  name: string;
  desc: string;
  serialNumber: string;
  iotThingName?: string;
  model?: RVModel;
}

const schema = yup.object<RVModelOrderFormValues>().shape({
  name: yup.string().label("Name").trim().min(3).max(50).required(),
  desc: yup.string().label("Description").trim().min(3).max(300).required(),
  serialNumber: yup
    .string()
    .label("Serial number")
    .trim()
    .min(1)
    .max(50)
    .required(),
  iotThingName: yup.string().label("IoT Thing Name").trim(),
  model: yup
    .object()
    .label("Model")
    .test("model-selected-test", "Model is required", (v) => null !== v)
    .required(),
});

const DEFAULT_VALUES: RVModelOrderFormValues = {
  name: "",
  desc: "",
  serialNumber: "",
};

interface RVModelOrderDetailsProps {
  defaultValues?: RVModelOrderFormValues;
  onSubmit: (v: RVModelOrderFormValues) => Promise<void> | void;
  onCancel?: () => void;
  confirmButtonLabel?: string;
  canEditModel?: boolean;
  canEditSN?: boolean;
  showIoTThingName?: boolean;
  // after submitting the form, keep the selected model rather than clearing it
  keepSelectedModelOnClear?: boolean;
}

const RVModelOrderDetails = (props: RVModelOrderDetailsProps): JSX.Element => {
  const {
    confirmButtonLabel = "Create",
    defaultValues = DEFAULT_VALUES,
    onCancel,
    canEditModel = false,
    canEditSN = false,
    showIoTThingName = false,
  } = props;

  const [values, setValues] = useState(defaultValues);

  const handleFormSubmit = useCallback(
    async (
      values: RVModelOrderFormValues,
      { setSubmitting, resetForm }: FormikHelpers<RVModelOrderFormValues>
    ) => {
      try {
        await props.onSubmit(values);
        const { model } = values;
        const resetVals = { ...defaultValues };
        if (true === props.keepSelectedModelOnClear) {
          // reset all values except for the selected model
          resetVals.model = model;
        }
        setValues(resetVals);
        resetForm();
      } catch (e) {
        console.log(e);
        alert(e.message);
      } finally {
        setSubmitting(false);
      }
    },
    [props, defaultValues]
  );

  return (
    <Formik
      validationSchema={schema}
      initialValues={values}
      onSubmit={handleFormSubmit}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        isValid,
        resetForm,
        setFieldValue,
        dirty,
      }): JSX.Element => (
        <Form
          noValidate
          onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
            handleSubmit(e as React.FormEvent<HTMLFormElement>)
          }
        >
          {canEditModel && (
            <Form.Group>
              <Form.Label>Model</Form.Label>
              <AsyncSelect
                cacheOptions
                onChange={(v: ValueType<RVModelOption>): void => {
                  const mdl = v as RVModelOption;
                  setFieldValue("model", mdl ? mdl.data : undefined);
                }}
                defaultOptions={true}
                loadOptions={loadRVModelOptions}
                isDisabled={isSubmitting}
                value={
                  values.model
                    ? rvModelToOption(values.model)
                    : ({} as RVModelOption)
                }
              />
              {errors.model && touched.model && (
                <Form.Text>{errors.model}</Form.Text>
              )}
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter order name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={(touched.name || isValid) && !errors.name}
            />
            {errors.name && touched.name && (
              <Form.Text>{errors.name}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter order description"
              name="desc"
              value={values.desc}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={(touched.desc || isValid) && !errors.desc}
            />
            {errors.desc && touched.desc && (
              <Form.Text>{errors.desc}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Serial number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter serial number"
              name="serialNumber"
              value={values.serialNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={
                (touched.serialNumber || isValid) && !errors.serialNumber
              }
              disabled={!canEditSN}
            />
            {errors.serialNumber && touched.serialNumber && (
              <Form.Text>{errors.serialNumber}</Form.Text>
            )}
          </Form.Group>
          {showIoTThingName && (
            <Form.Group>
              <Form.Label>IoT Thing Name</Form.Label>
              <Form.Control
                readOnly
                type="text"
                placeholder="Enter IoT Thing name"
                name="iotThingName"
                value={values.iotThingName}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={
                  (touched.iotThingName || isValid) && !errors.iotThingName
                }
              />
            </Form.Group>
          )}
          <ButtonToolbar>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || !isValid || !dirty}
            >
              {confirmButtonLabel}
            </Button>
            {onCancel && (
              <Button
                variant="secondary"
                className="ml-1"
                onClick={(): void => {
                  const r = window.confirm("Are you sure?");
                  if (true === r) {
                    resetForm();
                    onCancel();
                  }
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            )}
          </ButtonToolbar>
        </Form>
      )}
    </Formik>
  );
};

export default RVModelOrderDetails;
