import React, { useCallback } from "react";
import { Card, Button } from "react-bootstrap";
import { Value } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom } from "@fortawesome/free-solid-svg-icons";

export type OnReleaseOrderEvent = {
  id: string;
  name: string;
};

interface Props {
  orderID: string;
  name: string;
  description: string;
  serialNumber: string;
  onRelease?: (e: OnReleaseOrderEvent) => Promise<void> | void;
}

const Order: React.FC<Props> = ({
  orderID,
  name,
  description,
  serialNumber,
  onRelease,
}) => {
  const handleRelease = useCallback(() => {
    onRelease?.({ id: orderID, name: name });
  }, [onRelease, orderID, name]);
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <div className="d-flex justify-content-between align-items-center">
            <div>{name}</div>
            <div>
              {handleRelease && (
                <Button
                  data-testid="order-release-btn"
                  onClick={handleRelease}
                  variant="danger"
                >
                  <FontAwesomeIcon icon={faBroom} title="Dashboard" />
                </Button>
              )}
            </div>
          </div>
        </Card.Title>
        <Value value={description} title={"Description"} />
        <br />
        <Value value={serialNumber} title={"Serial number"} />
      </Card.Body>
    </Card>
  );
};

export default Order;
