import React, { useCallback, useState } from "react";
import RegisterDevice, { FormValues } from "./RegisterDevice";
import RegisteredDevicesList from "./RegisteredDevicesList";
import Client, { ListingResult, RegisteredDevice } from "../../api/connx";
import ConfirmationDialog, { PageContainer } from "../../components";
import { Tabs, Tab } from "react-bootstrap";
import { anyGranted } from "../../user/user";
import { RBACPermission } from "../../user/rbac";

const registerDevice = async (values: FormValues): Promise<void> => {
  try {
    await Client.registerNewDevice(values);
    alert("Device registered");
  } catch (e) {
    alert(e.message);
  }
};

// data source adaptor
const defDataSource = ({
  filter,
  nextKey,
}: {
  filter: string;
  nextKey?: string;
}): Promise<ListingResult<RegisteredDevice>> => {
  return Client.listRegisteredDevices(filter, nextKey);
};

interface Props {
  onEditDevice: (id: string) => void;
  onAssignDeviceConfig: (id: string) => void;
  deviceDataSource: ({
    filter,
    nextKey,
  }: {
    filter: string;
    nextKey?: string;
  }) => Promise<ListingResult<RegisteredDevice>>;
  hasPermission?: (p: RBACPermission) => boolean;
}

const RegisteredDevicesView: React.FC<Props> = (props) => {
  const {
    onEditDevice,
    onAssignDeviceConfig,
    deviceDataSource = defDataSource,
    hasPermission = anyGranted,
  } = props;

  const [targetItemID, setTargetItemID] = useState("");
  const [refresh, setRefresh] = useState(0);
  const onRegistered = useCallback(() => {
    setRefresh((p) => p + 1);
  }, []);
  const onDelete = useCallback((id) => {
    setTargetItemID(id);
  }, []);
  const onClose = useCallback(
    async ({ cancelled }) => {
      try {
        if (false === cancelled) {
          await Client.deleteRegisteredDevice(targetItemID);
          alert("Device deleted");
          setRefresh((p) => p + 1);
        }
      } catch (e) {
        alert(e.message);
      } finally {
        setTargetItemID("");
      }
    },
    [targetItemID]
  );

  const DEVICE_ACTIONS = [
    {
      name: "Edit",
      onClick: (item: RegisteredDevice): void => {
        onEditDevice(item.id);
      },
      disabled: !hasPermission("device_update"),
    },
    {
      name: "Assign default config",
      onClick: (item: RegisteredDevice): void => {
        onAssignDeviceConfig(item.id);
      },
      disabled: !hasPermission("device_update"),
    },
    {
      name: "Delete",
      onClick: (item: RegisteredDevice): void => onDelete(item.id),
      disabled: !hasPermission("device_delete"),
    },
  ];

  return (
    <>
      <ConfirmationDialog
        show={targetItemID !== ""}
        onClose={onClose}
        caption={"Delete device"}
      />
      <PageContainer
        title="ConnX devices"
        subtitle="Manage registered device types"
      >
        <Tabs id="device-registrations-tabs">
          <Tab
            eventKey="list-device-config"
            title="List"
            disabled={!hasPermission("device_read")}
          >
            <br />
            <RegisteredDevicesList
              dataSource={deviceDataSource}
              actions={DEVICE_ACTIONS}
              refresh={refresh}
            />
          </Tab>
          <Tab
            eventKey="register-device-config"
            title="Register"
            disabled={!hasPermission("device_create")}
          >
            <br />
            <RegisterDevice
              onRegistered={onRegistered}
              onSubmit={registerDevice}
            />
          </Tab>
        </Tabs>
      </PageContainer>
    </>
  );
};

export default RegisteredDevicesView;
