import { useHistory, useLocation } from "react-router-dom";
import { LocationDescriptorObject } from "history";

/*
useLocationState provides a way to embed UI state into the current entry in the browser's history.

E.g If you want to store only the last filter string from a textbox so that it can be restored
when the user hits the back and forward buttons in their browser.

const [locationState, setLocationState] = useLocationState<LocationState>();
*/
export const useLocationState = <S>(): [S, (state: S) => void] => {
  const location = useLocation<S>();
  const history = useHistory<S>();
  const setLocationState = (state: S): void => {
    const location: LocationDescriptorObject<S> = {
      ...history.location,
      state,
    };
    history.replace(location);
  };
  return [location.state, setLocationState];
};
