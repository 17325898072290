import "date-fns";
import React from "react";
import { PeriodicChart } from "../../../../components/charts";
import { useHistoricalData, DataPoint, Series } from "./iotAnalytics";
import { Moment } from "moment";
import { ErrorView } from "../../../../components";
import { ValueFormatterFunc } from "../../../../components/charts/PeriodicChart";
import { roundToDecimals } from "../../../../helpers/maths";

export interface EventPoint {
  x: number;
  label: string;
  color: string;
}

type AxisDomainFunc = (data?: DataPoint[]) => number[];
export type AxisDomain = AxisDomainFunc | number[];
export type ValueFormatter = ValueFormatterFunc | null;
export type RemapFunc = (data: Series[]) => Series[];
type RoundFunc = (x: number) => number;

export const roundFunc = (axisDecimals: number): RoundFunc => {
  return (x: number): number => {
    return roundToDecimals(x, axisDecimals);
  };
};

export const defaultValueFormatter = (
  axisDecimals: number,
  displayUnits: string
): ValueFormatterFunc => {
  return (y: number): string => {
    return roundFunc(axisDecimals)(y) + displayUnits;
  };
};

export const getDomain = (
  src?: AxisDomain,
  data?: DataPoint[]
): number[] | undefined => {
  if (src instanceof Function) {
    return (src as AxisDomainFunc)(data);
  } else if (src as number[]) {
    return src as number[];
  }
  return undefined;
};

export const computeYDomainData = (
  yds: (number[] | undefined)[],
  yAxisDecimals: number[],
  displayUnits: string[],
  yDomainSeriesIdx: number[],
  valueFormatters?: ValueFormatter[]
): Array<{
  min: number | undefined;
  max: number | undefined;
  seriesIdx: number;
  valueFormatter: ValueFormatterFunc;
}> => {
  return yds.map((yd, i) => {
    const axisDecimals =
      0 === yAxisDecimals.length
        ? 0
        : i < yAxisDecimals.length
        ? yAxisDecimals[i]
        : yAxisDecimals[0];
    const displayUnit =
      0 === displayUnits.length
        ? ""
        : i < displayUnits.length
        ? displayUnits[i]
        : displayUnits[0];
    const seriesIdx =
      0 === yDomainSeriesIdx.length
        ? -1
        : i < yDomainSeriesIdx.length
        ? yDomainSeriesIdx[i]
        : yDomainSeriesIdx[0];
    return {
      min: yd ? yd[0] : undefined,
      max: yd ? yd[1] : undefined,
      seriesIdx,
      valueFormatter:
        undefined === valueFormatters
          ? defaultValueFormatter(axisDecimals, displayUnit)
          : i < valueFormatters.length
          ? null === valueFormatters[i]
            ? defaultValueFormatter(axisDecimals, displayUnit)
            : (valueFormatters[i] as ValueFormatterFunc)
          : (valueFormatters[0] as ValueFormatterFunc),
    };
  });
};

export const reorderSeries = (
  series: Series[] | undefined,
  seriesNames: string[]
): Series[] => {
  // Reorder the returned series to match the seriesName ordering.
  const reorderSeries = Array<Series>();
  seriesNames.forEach((name) => {
    const matchingSeries = series?.filter((series) => {
      return series.name === name;
    });
    if (matchingSeries && 0 < matchingSeries.length) {
      reorderSeries.push(matchingSeries[0]); // There should just be one matching series.
    }
  });

  return reorderSeries;
};

export const mapSeries = (series: Series[], dataMap: RemapFunc[]): Series[] => {
  // Remap
  let remapedSeries = new Array<Series>();
  if (0 < series.length) {
    if (series.length === dataMap.length) {
      // Use separate data map for each series.
      remapedSeries = series.flatMap((s, i) => dataMap[i]([s]));
    } else if (0 < dataMap.length) {
      // Use the first data map for each series.
      remapedSeries = dataMap[0](series);
    } else {
      remapedSeries = series;
    }
  }

  // Label
  return remapedSeries;
};

export const labelSeries = (
  series: Series[],
  displayNames: Record<string, string>
): Series[] => {
  return series.map((x) => ({
    name: displayNames[x.name] || x.name,
    data: x.data,
  }));
};

export const ReportCard: React.FC<{
  orderID: string;
  seriesNames: string[];
  displayNames?: Record<string, string>;
  xDomain?: AxisDomain;
  yDomains: AxisDomain[];
  start: Moment;
  end: Moment;
  dataMap?: RemapFunc[];
  showMin?: boolean;
  showMax?: boolean;
  zoomNonZero?: boolean;
  yAxisDecimals?: number[];
  bar?: boolean;
  displayUnits?: string[];
  yDomainSeriesIdx?: number[];
  curves?: string[];
  valueFormatters?: ValueFormatter[];
  events?: EventPoint[];
  loading?: boolean;
  title?: boolean;
}> = (props) => {
  const {
    orderID,
    seriesNames,
    xDomain,
    yDomains,
    start,
    end,
    dataMap = [(data: Series[]): Series[] => data],
    displayNames = {},
    showMin = false,
    showMax = false,
    zoomNonZero = false,
    yAxisDecimals = [0],
    bar = false,
    displayUnits = [""],
    yDomainSeriesIdx = [0],
    curves = ["straight"],
    valueFormatters,
    events,
    loading,
    title,
  } = props;

  const dataResult = useHistoricalData(orderID, {
    seriesNames: seriesNames,
    start,
    end,
  });

  if (dataResult.error) {
    return <ErrorView />;
  }

  const reorderedSeries = reorderSeries(dataResult.series, seriesNames);
  const dateMappedSeries = mapSeries(reorderedSeries, dataMap);
  const labelledSeries = labelSeries(dateMappedSeries, displayNames);

  // Combine all the data from the series so that it can passed to the getDomain functions.
  const combinedData = labelledSeries.flatMap<DataPoint>(
    (value: Series): DataPoint[] => {
      return value.data;
    }
  );
  // compute domain
  const xd = getDomain(xDomain, combinedData);
  const yds = yDomains.map((yDomain) => {
    return getDomain(yDomain, combinedData);
  });
  const yDomainData = computeYDomainData(
    yds,
    yAxisDecimals,
    displayUnits,
    yDomainSeriesIdx,
    valueFormatters
  );

  let anyLoading = dataResult.loading;
  if (undefined !== loading) {
    anyLoading = anyLoading || loading;
  }

  const titleStr =
    labelledSeries && 0 < labelledSeries.length && title
      ? labelledSeries[0].name + " (" + displayUnits[0] + ")"
      : "";

  return (
    <PeriodicChart
      series={labelledSeries}
      events={events}
      height={400}
      xDomain={xd}
      yDomains={yDomainData}
      curves={curves}
      loading={anyLoading}
      showMin={showMin}
      showMax={showMax}
      zoomNonZero={zoomNonZero}
      bar={bar}
      title={titleStr}
    />
  );
};
