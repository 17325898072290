import React, { useState, useCallback } from "react";
import { Button, Dropdown, Row, Col } from "react-bootstrap";
import AsModal from "../../components/AsModal";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PaginatedSearchList from "../../components/PaginatedSearchList";
import EditUserGroupDetails from "./EditUserGroupDetails";
import "./AdminUserGroupList.css";
import {
  RowRendererProps,
  renderTable,
} from "../../components/PaginatedListTableRenderer";
import {
  UserGroup,
  GetUserGroupsInput,
  GetUserGroupsOutput,
  CreateUserGroupInput,
} from "../../api/usergroup";
import { ListRendererProps } from "../../components/PaginatedList";
import { anyGranted } from "../../user/user";
import { PageContainer } from "../../components";

const EditUserGroupModal = AsModal(EditUserGroupDetails);

interface UserGroupRowProps extends RowRendererProps<UserGroup> {
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  canEdit?: () => boolean;
  canDelete?: () => boolean;
}

const UserGroupRow: React.FC<UserGroupRowProps> = (props) => {
  const {
    item,
    onEdit,
    onDelete,
    canEdit = (): boolean => false,
    canDelete = (): boolean => false,
  } = props;

  const handleEditUser = useCallback(() => {
    onEdit && onEdit(item.id);
  }, [onEdit, item.id]);

  const handleDeleteUser = useCallback(() => {
    onDelete && onDelete(item.id);
  }, [onDelete, item.id]);

  return (
    <tr>
      <td>{item.name}</td>
      <td>{item.desc}</td>
      <td>{item.email}</td>
      <td className="action">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" data-testid="action_menu" />
          <Dropdown.Menu>
            <Dropdown.Item
              key="0"
              disabled={!canEdit()}
              onClick={handleEditUser}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              key="1"
              disabled={!canDelete()}
              onClick={handleDeleteUser}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

const TableRenderer = renderTable(UserGroupRow);

const UserGroupTableRenderer: React.FC<ListRendererProps<UserGroup>> = (
  props
) => (
  <TableRenderer {...props}>
    <div>Name</div>
    <div>Description</div>
    <div>Email</div>
    <div></div>
  </TableRenderer>
);

interface AdminUserGroupListProps {
  getItems: (
    p: GetUserGroupsInput
  ) => Promise<GetUserGroupsOutput> | GetUserGroupsOutput;
  canCreate?: () => boolean;
  canEdit?: () => boolean;
  canDelete?: () => boolean;
  deleteItem: (id: string) => Promise<void> | void;
  createItem: (input: CreateUserGroupInput) => Promise<UserGroup> | UserGroup;
  onEdit: (id: string) => void;
}

const getNewUserGroupFunc = async (): Promise<UserGroup> => {
  return {} as UserGroup;
};

const AdminUserGroupList: React.FC<AdminUserGroupListProps> = (props) => {
  const {
    getItems,
    canCreate = (): boolean => anyGranted("group_create"),
    canEdit = (): boolean => anyGranted("group_update"),
    canDelete = (): boolean => anyGranted("group_delete"),
    deleteItem,
    createItem,
    onEdit,
  } = props;

  // state
  const [showEditUser, setShowEditUser] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteItemId, setDeleteItemID] = useState("");

  // handlers
  const handleDelete = useCallback((id: string) => {
    setDeleteItemID(id);
    setShowConfirmDelete(true);
  }, []);
  const handleConfirmDeleteClosed = useCallback(
    async ({ cancelled = true }) => {
      setShowConfirmDelete(false);
      try {
        if (cancelled === false) {
          await deleteItem(deleteItemId);
          alert("Item deleted");
          setRefresh((p) => p + 1);
        }
      } catch (e) {
        console.log(e);
        alert(e.message);
      } finally {
        setDeleteItemID("");
      }
    },
    [deleteItem, deleteItemId]
  );
  const handleSaveUserGroup = useCallback(
    async (props) => {
      try {
        const r = await createItem(props);
        return { userGroup: r };
      } catch (e) {
        console.log(e);
        alert(e.message);
        return { userGroup: {} as UserGroup };
      } finally {
        setRefresh((p) => p + 1);
        setShowEditUser(false);
      }
    },
    [createItem]
  );
  const handleNewUser = useCallback(() => {
    setShowEditUser(true);
  }, []);
  const dataSource = useCallback(
    ({ filter, nextKey }) => {
      type filter = {
        name: string;
        value: string;
      };
      type params = {
        filters?: filter[];
        nextKey?: string;
      };
      let p: params = {
        nextKey: nextKey,
      };
      if (filter !== "") {
        p = {
          filters: [{ name: "name", value: filter }],
          ...p,
        };
      }
      return getItems(p);
    },
    [getItems]
  );
  const handleOnHideNewUserGroup = useCallback(
    () => setShowEditUser(false),
    []
  );
  const userGroupListProps = {
    canEdit,
    canDelete,
    onEdit: onEdit,
    onDelete: handleDelete,
  };

  return (
    <PageContainer title="Group Administration">
      <Row>
        <Col />
        <Col md="auto">
          <Button disabled={!canCreate()} onClick={handleNewUser}>
            New
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={true}>
          <EditUserGroupModal
            title={"New user group"}
            show={showEditUser}
            getUserGroupDetails={getNewUserGroupFunc}
            onHide={handleOnHideNewUserGroup}
            onSave={handleSaveUserGroup}
          />
          <PaginatedSearchList
            getItems={dataSource}
            ListRenderer={UserGroupTableRenderer}
            refresh={refresh}
            {...userGroupListProps}
          />
        </Col>
      </Row>
      <ConfirmationDialog
        caption={"Delete user group"}
        message={"Are you sure you want to delete this user group?"}
        show={showConfirmDelete}
        onClose={handleConfirmDeleteClosed}
      />
    </PageContainer>
  );
};

export { UserGroupRow };
export default AdminUserGroupList;
