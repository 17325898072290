import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Button,
} from "@material-ui/core";
import deepEqual from "deep-equal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(0),
    },
    button: {
      margin: theme.spacing(3, 1, 0, 0),
    },
  })
);

export interface DCXReportsConfig {
  subscribed: boolean;
}

interface State {
  cfg: DCXReportsConfig;
}

interface ActionReportType {
  action: "subscribed";
  checked: boolean;
}

const useDCXReportState = (
  refCfg: DCXReportsConfig
): [State, React.Dispatch<ActionReportType>] => {
  return React.useReducer(
    (prev: State, action: ActionReportType): State => {
      switch (action.action) {
        case "subscribed": {
          return {
            ...prev,
            cfg: {
              ...prev.cfg,
              subscribed: action.checked,
            },
          };
        }
      }
    },
    {
      cfg: refCfg,
    }
  );
};

export const DCXReports: React.FC<{
  initialState: DCXReportsConfig;
  onSubmit?: (cfg: DCXReportsConfig) => void | Promise<void>;
  onSubmitError?: (e: unknown) => void;
}> = ({ initialState, onSubmit, onSubmitError }) => {
  const classes = useStyles();

  const [refCfg, setRefCfg] = React.useState(initialState);
  const [state, setState] = useDCXReportState(initialState);
  const [submitting, setSubmitting] = React.useState(false);

  const handleCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setState({
      action: "subscribed",
      checked: event.target.checked,
    });
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setSubmitting(true);
      await onSubmit?.(state.cfg);
      setRefCfg(state.cfg);
    } catch (e) {
      if (undefined === onSubmitError) {
        console.log(e);
      } else {
        onSubmitError(e);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const { subscribed } = state.cfg;
  const isSame = deepEqual(refCfg, state.cfg);

  return (
    <div className={classes.root}>
      <FormControl
        component="fieldset"
        className={classes.formControl}
        disabled={submitting}
      >
        <FormLabel component="legend">DCX reports</FormLabel>
        <FormHelperText>
          Only applies to orders owned by current user
        </FormHelperText>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={subscribed}
                onChange={handleCheckChange}
                name="Subscribe to weekly email reports"
              />
            }
            label="Subscribe to weekly email reports"
          />
        </FormGroup>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSame}
          className={classes.button}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </FormControl>
    </div>
  );
};
