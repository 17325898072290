import React, { useCallback } from "react";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { Button, Form, ButtonToolbar } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import Client, {
  DeviceConfiguration,
  DeviceConfigurationVersioned,
  getDeviceConfigVersion,
  unixTimeHumanReadable,
  ListingResult,
  RegisteredDevice,
} from "../../../api/connx";
import { useModelLoader } from "../../../helpers/hooks";
import { Loading } from "../../../components";

type DeviceConfigurationDataSource = (
  filter: string | null
) =>
  | Promise<ListingResult<DeviceConfiguration>>
  | ListingResult<DeviceConfiguration>;

interface EditDefaultConfigProps {
  // the id of the registered device to edit
  id: string;
  // called when editing is complete
  done?: () => void;
  // load the registered device with the given id
  loadRegisteredDevice?: (
    id: string
  ) => Promise<RegisteredDevice> | RegisteredDevice;
  // device configuration data source
  listDeviceConfigurations?: DeviceConfigurationDataSource;
}

interface Option {
  label: string;
  value: string;
  data: DeviceConfiguration | DeviceConfigurationVersioned | undefined;
}

interface FormValues {
  configurationID: Option;
  versionID: Option;
}

const DEFAULT_OPTION = {} as Option;

const DEFAULT_FORM_VALUES = {} as FormValues;

const schema = yup.object<FormValues>().shape({
  configurationID: yup
    .object()
    .test(
      "config-test",
      "Configuration is required",
      (v) => DEFAULT_OPTION !== v
    ),
  versionID: yup
    .object()
    .test("version-test", "Version is required", (v) => DEFAULT_OPTION !== v),
});

function loadDeviceVersionConfigs(config?: DeviceConfiguration) {
  return async (): Promise<Option[]> => {
    return config
      ? config.versions.map((v) => {
          return {
            label: unixTimeHumanReadable(v.version),
            value: v.docID,
            data: v,
          } as Option;
        })
      : [{} as Option];
  };
}

const EditDefaultConfig = (props: EditDefaultConfigProps): JSX.Element => {
  // extract properties
  const {
    listDeviceConfigurations = Client.listDeviceConfigurations,
    loadRegisteredDevice = Client.getRegisteredDevice,
  } = props;

  // adaptor function for registered device data source
  const loadDeviceConfigs = useCallback(
    (deviceID: string) => {
      return async (inputValue: string): Promise<Option[]> => {
        const r = await listDeviceConfigurations(inputValue);
        return (
          r.items
            // FIXME: the backend API should allow filtering using device ID
            .filter((i) => i.device && i.device.id === deviceID)
            .map((i) => {
              return {
                label: i.name,
                value: i.docID,
                data: i,
              };
            })
        );
      };
    },
    [listDeviceConfigurations]
  );

  // model loader callback handler
  const handleModelLoad = useCallback(async () => {
    return loadRegisteredDevice(props.id);
  }, [props.id, loadRegisteredDevice]);

  const handleModelLoadError = useCallback(
    (e: unknown) => {
      console.error(e);
      alert("Failed to load");
      props.done && props.done();
    },
    [props]
  );

  const [loading, config] = useModelLoader(
    handleModelLoad,
    handleModelLoadError
  );

  const selectedVersion = config ? getDeviceConfigVersion(config) : undefined;

  // populate form values
  const initialValues: FormValues =
    config && config.defaultConfig
      ? {
          configurationID: {
            label: config.defaultConfig.name,
            value: config.defaultConfig.docID,
            data: config.defaultConfig,
          },
          versionID: {
            label: selectedVersion
              ? unixTimeHumanReadable(selectedVersion.version)
              : "",
            value: config.defaultConfigVersionID,
            data: selectedVersion,
          },
        }
      : DEFAULT_FORM_VALUES;

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ): Promise<void> => {
    setSubmitting(true);
    try {
      await Client.updateRegisteredDevice(config.id, config.revision, {
        deviceConfigID: values.configurationID.value,
        deviceConfigVersionID: values.versionID.value,
      });
    } catch (e) {
      console.error(e);
      alert("Failed to apply changes");
    } finally {
      setSubmitting(false);
      props.done && props.done();
    }
  };

  return (
    <>
      <div className="text-center form-group page-header">
        <h1>Default configuration</h1>
        <small></small>
      </div>
      <div className="container">
        {loading ? (
          <Loading />
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validateOnMount={true}
          >
            {({
              handleSubmit,
              values,
              errors,
              isSubmitting,
              isValid,
              setFieldValue,
              dirty,
            }): JSX.Element => (
              <Form
                noValidate
                onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
                  handleSubmit(e as React.FormEvent<HTMLFormElement>)
                }
              >
                {/* Parent configuration */}
                <Form.Group>
                  <Form.Label>Configuration</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    onChange={(v): void => {
                      setFieldValue("configurationID", v);
                      setFieldValue("versionID", null);
                    }}
                    loadOptions={loadDeviceConfigs(config.id)}
                    isDisabled={isSubmitting}
                    value={values.configurationID}
                  />
                </Form.Group>

                {/* Version selection */}
                <Form.Group>
                  <Form.Label>Version</Form.Label>
                  <AsyncSelect
                    key={JSON.stringify(values.configurationID)}
                    defaultOptions
                    cacheOptions
                    onChange={(v): void => setFieldValue("versionID", v)}
                    loadOptions={loadDeviceVersionConfigs(
                      values.configurationID
                        ? (values.configurationID.data as DeviceConfiguration)
                        : undefined
                    )}
                    isDisabled={
                      isSubmitting ||
                      DEFAULT_OPTION === values.configurationID ||
                      undefined !== errors.configurationID
                    }
                    value={values.versionID}
                  />
                </Form.Group>

                {/* Save and cancel */}
                <ButtonToolbar>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {" "}
                    {"Apply"}{" "}
                  </Button>
                  <Button
                    variant="secondary"
                    className="ml-1"
                    onClick={(): void => {
                      const r = window.confirm("Are you sure?");
                      if (true === r) {
                        props.done && props.done();
                      }
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default EditDefaultConfig;
