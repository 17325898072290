import React, { useState, useCallback } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { GetUsersOutput, User } from "../../api/user";
import UserSelect, {
  SelectedUserEvent,
} from "../../components/user/UserSelect";

interface AddUserGroupUserProps {
  // user listing
  findUsers: ({
    filter,
  }: {
    filter?: string;
  }) => Promise<GetUsersOutput> | GetUsersOutput;
  // add the given user
  addUser: (id: string) => Promise<void> | void;
}

interface UserOption {
  label: string;
  value: string;
  user: User;
}

const AddUserGroupUser: React.FC<AddUserGroupUserProps> = ({
  findUsers,
  addUser,
}) => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleOnUserChanged = useCallback((e: SelectedUserEvent) => {
    setSelectedUser(e.id);
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSubmitting(true);
      try {
        if (selectedUser) {
          await addUser(selectedUser);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setSelectedUser(null);
        setSubmitting(false);
      }
    },
    [addUser, selectedUser]
  );

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={true} data-testid="react-select-user">
            <UserSelect
              findUsers={findUsers}
              isDisabled={submitting}
              onSelectUser={handleOnUserChanged}
            />
            {error && <Form.Text>{error}</Form.Text>}
          </Col>
          <Col md="auto">
            <Button type="submit" disabled={submitting || !selectedUser}>
              {submitting ? "Adding..." : "Add"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default AddUserGroupUser;
