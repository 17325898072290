import { useState, useEffect } from "react";

/*
Various hooks to aid with the construction of functional React components.
*/

// adds 'loading' state to a component that needs to fetch a model prior to the component
// populating fully.
export function useModelLoader<TModel>(
  loader: () => Promise<TModel> | TModel,
  onError: (e: Error) => void
): [boolean, TModel] {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({} as TModel);

  useEffect(() => {
    let abort = false;
    const loadModel = async (): Promise<void> => {
      let mdl: TModel;
      try {
        mdl = await loader();
      } catch (e) {
        onError(e);
        return;
      }
      if (!abort) {
        setValue(mdl);
        setLoading(false);
      }
    };
    loadModel();
    return (): void => {
      abort = true;
    };
  }, [loader, onError]);

  return [loading, value];
}
