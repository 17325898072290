import React, { useState, useCallback, useEffect } from "react";
import { GetUsersOutput, User, AdminGetUsers } from "../../api/user";
import AsyncSelect from "react-select/async";
import { ValueType } from "react-select";

export interface UserOption {
  label: string;
  value: string;
  user: User;
}

export type SelectedUserEvent = {
  id: string;
  user: UserOption;
};

interface UserSelectProps {
  findUsers?: ({
    filter,
  }: {
    filter?: string;
  }) => Promise<GetUsersOutput> | GetUsersOutput;
  onSelectUser?: (e: SelectedUserEvent) => Promise<void> | void;
  isDisabled?: boolean;
  value?: UserOption;
}

const defaultFindUsers = async ({
  filter,
}: {
  filter?: string;
}): Promise<GetUsersOutput> => {
  return AdminGetUsers({ filterUsernameLike: filter });
};

const UserSelect = ({
  findUsers = defaultFindUsers,
  onSelectUser,
  isDisabled,
  value,
}: UserSelectProps): JSX.Element => {
  const [selectedUser, setSelectedUser] = useState<ValueType<UserOption>>(null);

  useEffect(() => {
    setSelectedUser(value);
  }, [value]);

  const handleSelectedUserChanged = useCallback(
    (v: ValueType<UserOption>) => {
      setSelectedUser(v);
      const user = v as UserOption;
      if (user) {
        onSelectUser?.({ id: user.user.id, user });
      }
    },
    [onSelectUser]
  );

  const loadOptions = useCallback(
    async (inputValue: string): Promise<UserOption[]> => {
      try {
        const r = await findUsers({ filter: inputValue });
        return r.items?.map((i) => {
          return { value: i.id, label: i.email, user: i };
        });
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    [findUsers]
  );

  return (
    <AsyncSelect
      defaultOptions={true}
      cacheOptions
      placeholder="Enter user email..."
      onChange={handleSelectedUserChanged}
      loadOptions={loadOptions}
      noOptionsMessage={(): string => "No users found"}
      isDisabled={isDisabled}
      value={selectedUser}
      className="react-select"
      classNamePrefix="react-select"
    />
  );
};

export default UserSelect;
