import React, { useCallback } from "react";
import "./AdminUserList.css";
import { Tabs, Tab } from "react-bootstrap";
import PaginatedSearchList from "../../components/PaginatedSearchList";
import { User, GetUsersOutput, adminSignUpUser } from "../../api/user";
import AdminSignUpUser, { AdminSignUpUserData } from "./AdminSignUpUser";
import { anyGranted } from "../../user/user";
import { renderTable } from "../../components/PaginatedListTableRenderer";
import { ListRendererProps } from "../../components/PaginatedList";
import UserRow from "./AdminUserListUserRow";
import { RBACPermission } from "../../user/rbac";
import { PageContainer } from "../../components";

const TableRenderer = renderTable(UserRow);

const UserTableRenderer: React.FC<ListRendererProps<User>> = (props) => (
  <TableRenderer {...props}>
    <div>Email</div>
    <div></div>
  </TableRenderer>
);

interface GetUserParams {
  filterUsernameLike?: string;
  nextKey?: string;
}

interface AdminUserListProps {
  getUsers: (p: GetUserParams) => Promise<GetUsersOutput> | GetUsersOutput;
  onEditUser?: (u: User) => void;
  hasPermission?: (p: RBACPermission) => boolean;
}

const AdminUserList: React.FC<AdminUserListProps> = (props) => {
  const { getUsers, onEditUser, hasPermission = anyGranted } = props;

  const handleGetUsers = useCallback(
    ({ filter, nextKey }: { filter: string; nextKey?: string }) => {
      return getUsers({ filterUsernameLike: filter, nextKey });
    },
    [getUsers]
  );

  const handleInviteUser = useCallback(async (v: AdminSignUpUserData) => {
    if (
      !(await adminSignUpUser(
        v.email,
        v.name,
        v.familyName,
        v.group ? v.group.value : "",
        v.rbacRoles
      ))
    ) {
      throw new Error("Failed to invite user");
    } else {
      alert("Invitation sent");
    }
  }, []);

  const rowProps = {
    onEdit: onEditUser,
  };

  return (
    <PageContainer title={"User Administration"}>
      <Tabs id="admin-user-tabs">
        <Tab
          eventKey="list-users"
          title="Users"
          disabled={!hasPermission("user_read")}
        >
          <br />
          <PaginatedSearchList
            getItems={handleGetUsers}
            ListRenderer={UserTableRenderer}
            {...rowProps}
          />
        </Tab>
        <Tab
          eventKey="invite-user"
          title="Invite"
          disabled={!hasPermission("user_create")}
        >
          <br />
          <AdminSignUpUser onSubmit={handleInviteUser} />
        </Tab>
      </Tabs>
    </PageContainer>
  );
};

export { UserRow };
export default AdminUserList;
