import React from "react";
import { anyGranted, isAuthenticated } from "../../user/user";
import { NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { RBACPermission } from "../../user/rbac";

interface Props {
  authenticated?: () => boolean;
  isAdmin?: () => boolean;
  hasPermission?: (p: RBACPermission) => boolean;
}

const AdminMenu: React.FC<Props> = (props) => {
  const { authenticated = isAuthenticated, hasPermission = anyGranted } = props;

  if (!authenticated()) {
    return null;
  }

  const userReadGranted = hasPermission("user_read");
  const groupReadGranted = hasPermission("group_read");

  return userReadGranted || groupReadGranted ? (
    <NavDropdown title="Admin" id="collasible-nav-dropdown">
      {userReadGranted && (
        <LinkContainer to="/admin/users">
          <NavDropdown.Item>Users</NavDropdown.Item>
        </LinkContainer>
      )}
      {groupReadGranted && (
        <LinkContainer to="/admin/usergroups">
          <NavDropdown.Item>Groups</NavDropdown.Item>
        </LinkContainer>
      )}
    </NavDropdown>
  ) : null;
};

export default AdminMenu;
