import React, { useState, useEffect, useReducer } from "react";
import RVModelSelection from "./RVModelSelection";
import { RVModel } from "../../../api/connx/rvmodel";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { ApolloClient } from "@apollo/client";
import { ConnXClient } from "../../../api/apolloClient";
import { Loading, ErrorView } from "../../../components";
import { useLocationState } from "../../../helpers/useLocationState";
import ExportForm, { ExportConfig } from "./export/ExportForm";
import FileSaver from "file-saver";
import moment from "moment";

const ORDERS_QUERY = gql`
  query listRVModelOrders($groupID: ID!) {
    listRVModelOrders(groupID: $groupID) {
      models {
        docID
        name
        iotThingName
      }
    }
  }
`;

interface Order {
  docID: string;
  name: string;
  iotThingName: string;
}

interface QueryResult {
  listRVModelOrders: {
    models: Order[];
  };
}

const DCX_HISTORICAL_DATAS_QUERY = gql`
  query dcxData(
    $orderIDs: [ID!]!
    $names: [String!]!
    $start: String!
    $end: String!
  ) {
    dcxHistoricalDatas(
      orderIDs: $orderIDs
      input: {
        names: $names
        grouping: HOURLY
        periodStart: $start
        periodEnd: $end
      }
    ) {
      data {
        orderID
        series {
          name
          data {
            time
            value
          }
        }
      }
    }
  }
`;

interface DCXHistoricalDatasQueryInput {
  orderIDs: string[];
  names: string[];
  start: string;
  end: string;
}

interface DCXDataPoint {
  time: string;
  value: number;
}

interface DCXSeries {
  name: string;
  data: DCXDataPoint[];
}

interface DCXHistoricalDatasQueryOutput {
  dcxHistoricalDatas: {
    data: {
      orderID: string;
      series?: DCXSeries[];
    }[];
  };
}

interface LocationState {
  model: string;
  modelName: string;
}

interface HistoricData {
  orderID: string;
  series?: DCXSeries[] | undefined;
}

export const createFilename = (cfg: ExportConfig): string => {
  return (
    cfg.selectedOrderNames.join("-") +
    "-" +
    cfg.selectedSeriesNames.join("-") +
    "-" +
    cfg.dateRange[0].format("YYYY-MM-DD") +
    "-" +
    cfg.dateRange[1].format("YYYY-MM-DD") +
    ".csv"
  );
};

export const convertToCSV = (
  cfg: ExportConfig,
  historicData: HistoricData[]
): string => {
  // Change the data to date, time, order1 series1, order1 series2, order2 series1, order2 series2, etc
  // Create the header row.
  const header = ["Date", "Time"];
  cfg.selectedOrderNames.forEach((orderName) => {
    cfg.selectedSeriesNames.forEach((seriesName) => {
      header.push(orderName + " " + seriesName);
    });
  });

  // Not every order will have a value for every dateTime, so create an array of rows and sort at the end.
  type Row = {
    dateTime: number;
    orderInfo: number[]; // order1 series1, order1 series2, order2 series1, order2 series2, etc
  };

  let table: Row[] = [];

  // For each order
  historicData.forEach((h) => {
    // Lookup the order name.
    const numOrders = cfg.selectedOrderIDs.length;
    const numSeries = cfg.selectedSeriesIDs.length;
    const orderIdx = cfg.selectedOrderIDs.indexOf(h.orderID);
    if (h.series) {
      h.series.forEach((s) => {
        // Lookup the series indexes. They can be more than one eg solar output and CO2 use the same series.
        const seriesIdxs: number[] = [];
        cfg.selectedSeriesIDs.forEach((seriesID, i) => {
          if (seriesID === s.name) {
            seriesIdxs.push(i);
          }
        });

        seriesIdxs.forEach((seriesIdx) => {
          const orderInfoIdx = orderIdx * numSeries + seriesIdx;
          const multiplier = cfg.selectedSeriesMultipliers[seriesIdx];
          s.data.forEach((dp) => {
            const dateTime = moment(dp.time).valueOf();
            // Check if already have a row for this dateTime
            const rows = table.filter((r) => {
              return r.dateTime === dateTime;
            });
            if (0 === rows.length) {
              // Doesn't exist
              const orderInfo = new Array<number>(numOrders);
              orderInfo[orderInfoIdx] = dp.value * multiplier;
              table.push({ dateTime, orderInfo });
            } else {
              rows[0].orderInfo[orderInfoIdx] = dp.value * multiplier;
            }
          });
        });
      });
    }
  });
  // Sort the rows
  table = table.sort((a: Row, b: Row) => {
    return a.dateTime - b.dateTime;
  });

  // Convert to CSV
  let csv = header.join(",");
  table.forEach((r) => {
    const dateTime = moment(r.dateTime);
    const date = dateTime.format("YYYY-MM-DD");
    const time = dateTime.format("HH:mm:ss");
    csv += "\n" + date + "," + time + "," + r.orderInfo.join(",");
  });

  return csv;
};

const Export: React.FC<{ client?: ApolloClient<unknown> }> = ({
  client = ConnXClient,
}) => {
  const [rvModel, setModel] = useState<RVModel | undefined>(undefined);

  const [locationState, setLocationState] = useLocationState<LocationState>();

  const handleModelChange = (m?: RVModel): void => {
    setModel(m);

    // Store the currently selected model in the current history location state.
    const newLocationState: LocationState = {
      model: "",
      modelName: "null",
    };
    if (m) {
      newLocationState.model = m.docID;
      newLocationState.modelName = m.name;
    }
    setLocationState(newLocationState);
  };

  const { loading, data, error } = useQuery<QueryResult>(ORDERS_QUERY, {
    skip: rvModel === undefined,
    variables: {
      groupID: rvModel?.docID,
    },
    client: client,
  });

  // Use the location to set the selected model.
  if (locationState) {
    if (locationState.model !== rvModel?.docID) {
      setModel({
        groupID: "",
        docID: locationState.model,
        name: locationState.modelName,
        desc: "",
        revision: -1,
      });
    }
  } else {
    if (undefined !== rvModel) {
      setModel(undefined);
    }
  }

  const [orders, setOrders] = useReducer(
    (
      _prev: Order[] | undefined,
      data: QueryResult | undefined
    ): Order[] | undefined => {
      if (data) {
        return data.listRVModelOrders.models
          .filter((x) => x.iotThingName !== "")
          .sort((a, b) => a.name.localeCompare(b.name));
      }
      return undefined;
    },
    undefined
  );

  useEffect(() => {
    setOrders(data);
  }, [data]);

  const saveData = (cfg: ExportConfig, historicData: HistoricData[]): void => {
    const csv = convertToCSV(cfg, historicData);
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const filename = createFilename(cfg);

    // Save to file
    FileSaver.saveAs(csvData, filename);
  };

  // Backend call
  const [exportData, exportedResult] = useLazyQuery<
    DCXHistoricalDatasQueryOutput,
    DCXHistoricalDatasQueryInput
  >(DCX_HISTORICAL_DATAS_QUERY, {
    fetchPolicy: "no-cache",
  });

  const [exportCfg, setExportCfg] = useState<ExportConfig>();
  const [cachedOutput, setCachedOutput] = useState<
    DCXHistoricalDatasQueryOutput
  >();
  const handleSubmit = (cfg: ExportConfig): void => {
    setExportCfg(cfg);
    exportData({
      variables: {
        orderIDs: cfg.selectedOrderIDs,
        names: Array.from(new Set(cfg.selectedSeriesIDs)), // Remove duplicates
        start: cfg.dateRange[0].format(),
        end: cfg.dateRange[1].format(),
      },
    });
  };

  useEffect(() => {
    if (cachedOutput !== exportedResult.data) {
      setCachedOutput(exportedResult.data);
      if (exportCfg && exportedResult.data) {
        saveData(exportCfg, exportedResult.data.dcxHistoricalDatas.data);
      }
    }
  }, [exportCfg, exportedResult.data, cachedOutput]);

  return (
    <>
      {/* select RV model */}
      <RVModelSelection
        modelOption={{
          value: "testvalue",
          label: rvModel ? rvModel.name : "",
          data: rvModel,
        }}
        onChange={handleModelChange}
      />

      {/* loading data */}
      {loading && <Loading />}

      {/* error */}
      {rvModel && error && <ErrorView />}

      {orders && <hr />}

      {/* list of 'live' data */}
      {orders && <ExportForm orders={orders} onSubmit={handleSubmit} />}

      {exportedResult.loading && <Loading />}
      {exportedResult.error && <ErrorView />}
    </>
  );
};

export default Export;
