import React, { Component, useCallback, ChangeEvent } from "react";
import { ButtonToolbar, Button, Form, Container } from "react-bootstrap";
import "./AdminUserEdit.css";
import { User, UpdateUserInput } from "../../api/user";
import { Scope, Role } from "../../user/user";
import { Loading } from "../../components";

interface RoleDesc {
  title: string;
  scope: Scope;
  role: Role;
}

interface ScopedRole {
  title: string;
  desc: string;
  roles: RoleDesc[];
}

const scopedRoles: ScopedRole[] = [
  {
    title: "Admin",
    desc:
      "Give the user administrator rights. Administrators can administer other users etc.",
    roles: [
      { title: "Read", scope: "admin", role: "read" },
      { title: "Write", scope: "admin", role: "write" },
    ],
  },

  {
    title: "ConnX Registered Device",
    desc: "Give the user access to ConnX registered devices.",
    roles: [
      { title: "Read", scope: "connx.regdev", role: "read" },
      { title: "Write", scope: "connx.regdev", role: "write" },
    ],
  },
  {
    title: "ConnX Device Configuration",
    desc: "Give the user access to configuration of registered ConnX devices.",
    roles: [
      { title: "Read", scope: "connx.devcfg", role: "read" },
      { title: "Write", scope: "connx.devcfg", role: "write" },
    ],
  },
  {
    title: "ConnX RV configuration",
    desc: "Give the user access to ConnX RV models and orders.",
    roles: [
      { title: "Read", scope: "connx.rvcfg", role: "read" },
      { title: "Write", scope: "connx.rvcfg", role: "write" },
    ],
  },
  {
    title: "ConnX Provisioning",
    desc: "Give the user access to provisioning of ConnX systems.",
    roles: [
      { title: "Read", scope: "connx.provisioning", role: "read" },
      { title: "Write", scope: "connx.provisioning", role: "write" },
    ],
  },
  {
    title: "ConnX Firmware",
    desc: "Give the user access to ConnX firmware.",
    roles: [
      { title: "Read", scope: "connx.fw", role: "read" },
      { title: "Write", scope: "connx.fw", role: "write" },
    ],
  },
  {
    title: "ConnX IoT (events)",
    desc: "Give the user access to ConnX system IoT events",
    roles: [
      { title: "Read", scope: "connx.iot.events", role: "read" },
      { title: "Write", scope: "connx.iot.events", role: "write" },
    ],
  },
];

function mungedRole(d: RoleDesc): string {
  return d.scope + ":" + d.role;
}

interface RoleViewProps {
  i: ScopedRole;
  isRoleEnabled: (role: string) => boolean;
  onRoleChanged: React.ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
}

const RoleView: React.FC<RoleViewProps> = ({
  i,
  isRoleEnabled,
  onRoleChanged,
  disabled,
}) => (
  <Form.Group key={i.title}>
    <Form.Label>
      <strong>{i.title}</strong>
      <br />
      <small>{i.desc || ""}</small>
    </Form.Label>
    <>
      {i.roles.map((r) => {
        const mr = mungedRole(r);
        return (
          <Form.Check
            data-testid={mr}
            type="checkbox"
            id={mr}
            title={mr}
            label={r.title}
            key={mr}
            checked={isRoleEnabled(mr)}
            onChange={onRoleChanged}
            disabled={disabled}
          />
        );
      })}
    </>
  </Form.Group>
);

interface RBACRole {
  role: string;
  name: string;
  desc?: string;
}

interface RBACViewProps {
  userRoles: string[];
  onRolesChanged?: (userRoles: string[]) => void;
  allowedRBACRoles?: RBACRole[];
}

// https://gitlab.com/ozxcorp/sw-docs/-/blob/master/concepts/connx_v1/rbac.md
const RBAC_ROLES: RBACRole[] = [
  {
    role: "user_admin",
    name: "User admin",
    desc: "Add, edit, remove users etc",
  },
  {
    role: "user_admin_read",
    name: "User admin (read-only)",
    desc: "List users etc.",
  },
  {
    role: "device_admin",
    name: "Device admin",
    desc: "Add, edit, remove devices etc.",
  },
  {
    role: "device_admin_read",
    name: "Device admin (read-only)",
    desc: "List devices etc.",
  },
  {
    role: "manufacturer_admin",
    name: "Manufacturer admin",
    desc: "Add, edit, remove models, orders etc in a manufacturer group.",
  },
  {
    role: "manufacturer_read",
    name: "Manufacturer admin (read-only)",
    desc: "List models, orders etc in a manufacturer group.",
  },
  {
    role: "manufacturer_provisioning",
    name: "Manufacturer provisioning",
    desc: "Manufacturer provisioning permissions.",
  },
  { role: "user", name: "General user", desc: "General user permissions." },
  {
    role: "model_order_admin_ro",
    name: "Model/order admin (read-only)",
    desc: "Global access to list models, orders etc.",
  },
];

export const RBACView: React.FC<RBACViewProps> = (props) => {
  const { userRoles, onRolesChanged, allowedRBACRoles = RBAC_ROLES } = props;

  // handlers
  const handleRoleCheckChanges = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let newRoles = [...userRoles];
      if (e.target.checked) {
        newRoles.push(e.target.id);
      } else {
        newRoles = newRoles.filter((i) => i !== e.target.id);
      }
      onRolesChanged?.(newRoles);
    },
    [onRolesChanged, userRoles]
  );

  const sortedRoles = allowedRBACRoles.sort((x1: RBACRole, x2: RBACRole) =>
    x1.name.localeCompare(x2.name)
  );

  return (
    <Container data-testid="role-list">
      {sortedRoles?.map((value) => (
        <Form.Check
          id={value.role}
          data-testid={value.role}
          key={value.role}
          type="checkbox"
          title={value.desc}
          label={value.name}
          checked={userRoles.includes(value.role)}
          onChange={handleRoleCheckChanges}
        />
      ))}
    </Container>
  );
};

interface AdminUserEditState {
  user?: User;
  loading: boolean;
  saving: boolean;
  dirty: boolean;
}

interface AdminUserEditProps {
  userId: string;
  GetUser: (userID: string) => Promise<User> | User;
  UpdateUser: (
    userID: string,
    revision: number,
    changes: UpdateUserInput
  ) => Promise<void> | void;
}

export default class AdminUserEdit extends Component<
  AdminUserEditProps,
  AdminUserEditState
> {
  constructor(props: AdminUserEditProps) {
    super(props);
    this.state = {
      loading: false,
      saving: false,
      dirty: false,
    };
  }

  componentDidMount(): void {
    this.refreshUserData();
  }

  render = (): JSX.Element => {
    const rbacRoles = this.state?.user?.rbacRoles || ([] as string[]);
    return this.state.user ? (
      <>
        <div className="text-center page-header">
          <h1>Edit user</h1>
        </div>
        <div className="container">
          <p>
            {" "}
            <strong>
              {this.state.user.name} {this.state.user.familyName}
            </strong>{" "}
          </p>
          <p> {this.state.user.email} </p>
          <hr />
          <h2>Roles</h2>
          <Form onSubmit={this.handleSubmit}>
            <RBACView
              userRoles={rbacRoles}
              onRolesChanged={this.handleRBACRolesChanged}
            />
            <hr />
            <h2>Legacy permissions</h2>
            {scopedRoles.map((i) => (
              <RoleView
                key={i.title}
                i={i}
                disabled={this.state.saving || this.state.loading}
                isRoleEnabled={this.isRoleEnabled}
                onRoleChanged={this.handleRoleChanged}
              />
            ))}
            <hr />
            <ButtonToolbar>
              <Button
                variant="primary"
                type="submit"
                disabled={
                  !this.state.dirty || this.state.saving || this.state.loading
                }
              >
                {this.state.saving ? "Saving..." : "Save"}
              </Button>
              <Button
                variant="secondary"
                onClick={this.handleCancel}
                className="ml-1"
                disabled={this.state.saving}
              >
                Back
              </Button>
            </ButtonToolbar>
          </Form>
        </div>
      </>
    ) : (
      <Loading />
    );
  };

  handleRBACRolesChanged = (newRoles: string[]): void => {
    if (!this.state.user || !this.state.user.roles) return;
    this.setState({
      user: {
        ...this.state.user,
        rbacRoles: newRoles,
      },
      dirty: true,
    });
  };

  handleCancel = (): void => {
    window.history.back();
  };

  handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    this.setState({ saving: true });
    try {
      await this.saveUserData();
      this.setState({ dirty: false });
    } catch (e) {
      console.log(e);
      alert(e.message);
    } finally {
      await this.refreshUserData();
      this.setState({ saving: false });
    }
  };

  handleRoleChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!this.state.user || !this.state.user.roles) return;
    const roles = this.state.user.roles.filter((r) => r !== event.target.id);
    if (event.target.checked === true) {
      roles.push(event.target.id);
    }
    this.setState({
      user: {
        ...this.state.user,
        roles: roles,
      },
      dirty: true,
    });
  };

  isRoleEnabled = (role: string): boolean => {
    if (!this.state.user || !this.state.user.roles) return false;
    return this.state.user.roles && this.state.user.roles.includes(role);
  };

  refreshUserData = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const r = await this.getUserData(this.props.userId);
      this.setState({ user: r });
    } catch (e) {
      console.log(e);
      alert("Failed to fetch user data.");
    } finally {
      this.setState({ loading: false });
    }
  };

  getUserData = async (userId: string): Promise<User> => {
    return this.props.GetUser(userId);
  };

  saveUserData = async (): Promise<void> => {
    if (!this.state.user) return;
    await this.props.UpdateUser(
      this.state.user.id,
      this.state.user.revision || 0,
      {
        roles: this.state.user.roles,
        rbacRoles: this.state.user.rbacRoles,
      }
    );
  };
}
