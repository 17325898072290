import "date-fns";
import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { ConnXClient } from "../../../../api/apolloClient";
import { PageContainer, SimpleDatePicker } from "../../../../components";
import { useLocationState } from "../../../../helpers/useLocationState";
import { DataPoint, Series } from "./iotAnalytics";
import Grid from "@material-ui/core/Grid";
import {
  makeStyles,
  Tabs,
  Tab,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import moment, { Moment } from "moment";
import { roundToDecimals } from "../../../../helpers/maths";
import { ReportCard, RemapFunc } from "./ReportCard";
import { ReportCardWithEvents } from "./ReportCardWithEvents";
import { useUser } from "../../../../userContext";

interface InternalProps {
  orderID: string;
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    paddingTop: 0,
  },
  tab: {
    paddingTop: 10,
  },
}));

export const remapDataToPercentage = (
  min: number,
  max: number,
  data: DataPoint[]
): DataPoint[] => {
  if (max < min) throw new Error("max must be >= min");
  const RANGE = max - min;
  return data.map((x) => {
    const clamped = Math.max(Math.min(max, x.y), min);
    const normalised = (clamped - min) / RANGE;
    return {
      ...x,
      y: normalised * 100,
    };
  });
};

export const remapSeriesPercentage = ({
  min,
  max,
}: {
  min: number;
  max: number;
}): RemapFunc => (series: Series[]): Series[] => {
  return series.map((x) => ({
    name: x.name,
    data: remapDataToPercentage(min, max, x.data),
  }));
};

export const remapDataToFuel = (
  min: number,
  max: number,
  data: DataPoint[]
): DataPoint[] => {
  if (max < min) throw new Error("max must be >= min");
  const RANGE = max - min;
  return data.map((x) => {
    const clamped = Math.max(Math.min(max, x.y), min);
    let normalised = (clamped - min) / RANGE;
    if (0.5 < normalised) {
      normalised = 1;
    } else if (0 < normalised) {
      normalised = 0.4;
    }
    return {
      ...x,
      y: normalised * 100,
    };
  });
};

export const remapSeriesFuel = ({
  min,
  max,
}: {
  min: number;
  max: number;
}): RemapFunc => (series: Series[]): Series[] => {
  return series.map((x) => ({
    name: x.name,
    data: remapDataToFuel(min, max, x.data),
  }));
};

// Gather data into a span of time, eg week.
export const remapDataPointTime = (
  data: DataPoint[],
  milliseconds: number
): DataPoint[] => {
  // If more than twice the time span, merge into time span lots.
  if (2 * milliseconds <= data[data.length - 1].x - data[0].x) {
    const newData = Array<DataPoint>();
    data.forEach((dp) => {
      // Compare this daily date is within 1 week of the last weekly date.
      if (
        0 !== newData.length &&
        milliseconds > dp.x - newData[newData.length - 1].x
      ) {
        // Combine with the last weekly data entry.
        newData[newData.length - 1].y += dp.y;
      } else {
        // This is the first or a week or more past the last.
        newData.push(dp);
      }
    });
    return newData;
  }

  return data;
};

// Gather into units of time, eg days, months.
export const remapDataPointUnitOfTime = (
  data: DataPoint[],
  unitOfTime: moment.unitOfTime.StartOf
): DataPoint[] => {
  const newData = Array<DataPoint>();
  data.forEach((dp) => {
    // Convert x to unitOfTime only (eg day)
    const localMoment = moment(dp.x);
    // Make sure GMT+10. This is because unit tests aren't run with the correct timezone.
    // This would probably be an issue if you were looking at the webpage from another timezone.
    localMoment.utcOffset(10);
    const nearest = localMoment.startOf(unitOfTime);

    // Find a data point for that unitOfTime (eg day).
    const matchingDayDp = newData.filter((v) => {
      return v.x === nearest.valueOf();
    });
    // If found.
    if (0 < matchingDayDp.length) {
      // add to the total for that unitOfTime (eg day).
      matchingDayDp[0].y += dp.y;
    } else {
      // Add a new unitOfTime entry.
      newData.push({ x: nearest.valueOf(), y: dp.y });
    }
  });

  return newData;
};

export const remapSeriesDaily = (multiplier: number): RemapFunc => (
  series: Series[]
): Series[] => {
  series.forEach((s) => {
    // Remap to days.
    s.data = remapDataPointUnitOfTime(s.data, "day");

    // If more than one day.
    if (1 < s.data.length) {
      // If span more than two months, show one bar per month.
      const firstDay = moment(s.data[0].x);
      firstDay.utcOffset(10);
      const lastDay = moment(s.data[s.data.length - 1].x);
      lastDay.utcOffset(10);
      const monthSpan = lastDay.diff(firstDay, "month");
      if (2 <= monthSpan) {
        // Remap to months.
        s.data = remapDataPointUnitOfTime(s.data, "month");
      } else {
        // If the span is more than 14 days, convert x to per week.
        // Assume data is in date order.
        const weekInMilliseconds = 604800000;
        s.data = remapDataPointTime(s.data, weekInMilliseconds);
      }
    }
  });
  return series.map((x) => ({
    name: x.name,
    data: x.data.map((d) => {
      return {
        x: d.x,
        y: roundToDecimals(multiplier * d.y, 2),
      };
    }),
  }));
};

export function computePVPowerDomain(data?: DataPoint[]): number[] {
  const min = 1000;
  let domainUpper = min;
  if (data && data.length > 0) {
    const max = data.reduce((prev: number, cur: DataPoint) => {
      return Math.max(prev, cur.y);
    }, min);
    domainUpper = Math.ceil(max / min) * min;
  }
  return [0, domainUpper];
}

// Try to find a upper domain that provides whole number y axis labels.
export function computeCO2Domain(data?: DataPoint[]): number[] {
  const maxs = [10, 20, 50, 100, 200, 500, 1000];
  let index = 0;
  let domainUpper = maxs[index];
  if (data && data.length > 0) {
    const max = data.reduce((prev: number, cur: DataPoint) => {
      return Math.max(prev, cur.y);
    }, maxs[index]);
    // If greater than the last min, multiple of it.
    const lastMin = maxs[maxs.length - 1];
    if (max > lastMin) {
      domainUpper = Math.ceil(max / lastMin) * lastMin;
    } else {
      while (max > maxs[index] && index < maxs.length - 1) {
        index++;
      }
      domainUpper = maxs[index];
    }
  }
  return [0, domainUpper];
}

interface LocationState {
  dateRange: number[];
  selectedTab: number;
  showVoltage: boolean;
  showEvents: boolean;
}

export const LIQUID_SENSOR_FULL_VOLTAGE = 5;
export const WH_TO_G_CO2 = 1.22; // 1220g/KWh = 1.22g/Wh from Neil's calculations

const DashboardInternal: React.FC<InternalProps> = (props) => {
  const { orderID } = props;
  const { user } = useUser();
  const classes = useStyles();
  const toRange = (d: Moment): Moment[] => [
    moment(d),
    moment(d).add(1, "days").subtract(1, "minutes"),
  ];
  const [locationState, setLocationState] = useLocationState<LocationState>();
  const createLocationStateFromDateRange = (
    dateRange: Moment[],
    selectedTab: number,
    showVoltage: boolean,
    showEvents: boolean
  ): LocationState => {
    return {
      dateRange: [dateRange[0].valueOf(), dateRange[1].valueOf()],
      selectedTab,
      showVoltage,
      showEvents,
    };
  };

  // Default to today.
  let dateRange = toRange(moment().startOf("day"));
  let selectedTab = 0;
  let showVoltage = false;
  let showEvents = false;
  if (undefined !== locationState) {
    dateRange = [
      moment(locationState.dateRange[0]),
      moment(locationState.dateRange[1]),
    ];
    selectedTab = locationState.selectedTab;
    showVoltage = locationState.showVoltage;
    showEvents = locationState.showEvents;
  }
  useEffect(() => {
    if (undefined === locationState) {
      setLocationState(
        createLocationStateFromDateRange(
          toRange(moment().startOf("day")),
          0,
          false,
          false
        )
      );
    }
  });

  const NOMINAL_ECO_SITE_VOLTS_MAX = 60; // Volts
  const NOMINAL_ECO_SITE_VOLTS_MIN = 40; // Volts

  const allowedVoltage = user.features.hasFeature("web.dcx.bms.voltage");
  const allowedEvents = user.features.hasFeature("web.dcx.generator.events");

  return (
    <Grid container className={classes.root} spacing={2} direction="column">
      <SimpleDatePicker
        label="Start Date"
        value={dateRange[0].toDate()}
        onChange={(startDate: Date | null): void => {
          const endDate =
            startDate && moment(startDate).isAfter(dateRange[1])
              ? moment(startDate)
              : dateRange[1];
          setLocationState(
            createLocationStateFromDateRange(
              [startDate ? moment(startDate) : dateRange[1], endDate],
              selectedTab,
              showVoltage,
              showEvents
            )
          );
        }}
      />
      <SimpleDatePicker
        label="End Date"
        value={dateRange[1].toDate()}
        onChange={(endDate: Date | null): void => {
          const startDate =
            endDate && moment(endDate).isBefore(dateRange[0])
              ? moment(endDate)
              : dateRange[0];
          setLocationState(
            createLocationStateFromDateRange(
              [startDate, endDate ? moment(endDate) : dateRange[0]],
              selectedTab,
              showVoltage,
              showEvents
            )
          );
        }}
      />
      <Tabs
        value={selectedTab}
        onChange={(_event: React.ChangeEvent<{}>, newValue: number): void => {
          setLocationState({
            ...locationState,
            selectedTab: newValue,
          });
        }}
        aria-label="simple tabs example"
      >
        <Tab label="Solar Power" />
        <Tab label="Battery" />
        <Tab label="Liquids" />
        {user.features.hasFeature("web.dcx.visual.co2") && <Tab label="CO2" />}
      </Tabs>
      <Grid item xs>
        <div className={classes.tab}>
          {selectedTab === 0 && (
            <ReportCard
              orderID={orderID}
              seriesNames={["pv.power"]}
              displayNames={{ "pv.power": "Solar power" }}
              yDomains={[computePVPowerDomain]}
              start={dateRange[0]}
              end={dateRange[1]}
              showMax={true}
              zoomNonZero={true}
              displayUnits={["W"]}
            />
          )}
          {selectedTab === 1 && allowedEvents && (
            <ReportCardWithEvents
              orderID={orderID}
              seriesNames={
                showVoltage
                  ? ["battery.soc", "battery.total_voltage"]
                  : ["battery.soc"]
              }
              displayNames={
                showVoltage
                  ? { "battery.soc": "SOC", "battery.total_voltage": "V" }
                  : { "battery.soc": "SOC" }
              }
              eventType={showEvents ? "generator-status" : undefined}
              actionDisplayNames={{
                stopped: "Stopped",
                // eslint-disable-next-line @typescript-eslint/camelcase
                failed_to_start: "Failed to Start",
                starting: "Starting",
                running: "Running",
                stopping: "Stopping",
              }}
              actionDisplayColors={{
                stopped: "#ff455f",
                // eslint-disable-next-line @typescript-eslint/camelcase
                failed_to_start: "#ff0000",
                starting: "#5e72e4",
                running: "#00E396",
                stopping: "#feb019",
              }}
              yDomains={
                showVoltage
                  ? [
                      [0, 100],
                      [NOMINAL_ECO_SITE_VOLTS_MIN, NOMINAL_ECO_SITE_VOLTS_MAX],
                    ]
                  : [[0, 100]]
              }
              yDomainSeriesIdx={showVoltage ? [0, 1] : [0]}
              curves={showVoltage ? ["straight", "straight"] : ["straight"]}
              start={dateRange[0]}
              end={dateRange[1]}
              showMin={true}
              yAxisDecimals={[0, 1]}
              displayUnits={["%", "V"]}
            />
          )}
          {selectedTab === 1 && !allowedEvents && (
            <ReportCard
              orderID={orderID}
              seriesNames={
                showVoltage
                  ? ["battery.soc", "battery.total_voltage"]
                  : ["battery.soc"]
              }
              displayNames={
                showVoltage
                  ? { "battery.soc": "SOC", "battery.total_voltage": "V" }
                  : { "battery.soc": "SOC" }
              }
              yDomains={
                showVoltage
                  ? [
                      [0, 100],
                      [NOMINAL_ECO_SITE_VOLTS_MIN, NOMINAL_ECO_SITE_VOLTS_MAX],
                    ]
                  : [[0, 100]]
              }
              yDomainSeriesIdx={showVoltage ? [0, 1] : [0]}
              curves={showVoltage ? ["straight", "straight"] : ["straight"]}
              start={dateRange[0]}
              end={dateRange[1]}
              showMin={true}
              yAxisDecimals={[0, 1]}
              displayUnits={["%", "V"]}
            />
          )}
          {selectedTab === 1 && (allowedVoltage || allowedEvents) && (
            <FormGroup row className="d-flex justify-content-center">
              {allowedVoltage && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showVoltage}
                      onChange={(
                        _event: React.ChangeEvent<{}>,
                        newValue: boolean
                      ): void => {
                        setLocationState({
                          ...locationState,
                          showVoltage: newValue,
                        });
                      }}
                      name="checkedVoltage"
                    />
                  }
                  label="Voltage"
                />
              )}
              {allowedEvents && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showEvents}
                      onChange={(
                        _event: React.ChangeEvent<{}>,
                        newValue: boolean
                      ): void => {
                        setLocationState({
                          ...locationState,
                          showEvents: newValue,
                        });
                      }}
                      name="checkedEvents"
                    />
                  }
                  label="Generator Events"
                />
              )}
            </FormGroup>
          )}
          {selectedTab === 2 && (
            <ReportCard
              orderID={orderID}
              seriesNames={[
                "liquids.level0",
                "liquids.level1",
                "liquids.level2",
                "liquids.level5",
                "liquids.level3",
                "liquids.level4",
              ]}
              displayNames={{
                "liquids.level0": "Potable1",
                "liquids.level1": "Potable2",
                "liquids.level2": "Grey1",
                "liquids.level5": "Grey2",
                "liquids.level3": "Waste",
                "liquids.level4": "Fuel",
              }}
              yDomains={[
                [0, 100],
                [0, 100],
                [0, 100],
                [0, 100],
                [0, 100],
                [0, 100],
              ]}
              start={dateRange[0]}
              end={dateRange[1]}
              dataMap={[
                remapSeriesPercentage({
                  min: 0,
                  max: LIQUID_SENSOR_FULL_VOLTAGE,
                }),
                remapSeriesPercentage({
                  min: 0,
                  max: LIQUID_SENSOR_FULL_VOLTAGE,
                }),
                remapSeriesPercentage({
                  min: 0,
                  max: LIQUID_SENSOR_FULL_VOLTAGE,
                }),
                remapSeriesPercentage({
                  min: 0,
                  max: LIQUID_SENSOR_FULL_VOLTAGE,
                }),
                remapSeriesPercentage({
                  min: 0,
                  max: LIQUID_SENSOR_FULL_VOLTAGE,
                }),
                remapSeriesFuel({
                  min: 0,
                  max: LIQUID_SENSOR_FULL_VOLTAGE,
                }),
              ]}
              displayUnits={["%"]}
              yDomainSeriesIdx={[-1, -1, -1, -1, -1, 5]}
              curves={[
                "straight",
                "straight",
                "straight",
                "straight",
                "straight",
                "stepline",
              ]}
              valueFormatters={[
                null,
                null,
                null,
                null,
                null,
                (val: number): string => {
                  return 0 === val
                    ? "N/A"
                    : 100 === val
                    ? "Ok"
                    : 40 === val
                    ? "Low"
                    : "";
                },
              ]}
            />
          )}
          {selectedTab === 3 && (
            <ReportCard
              orderID={orderID}
              seriesNames={["pv.power"]}
              displayNames={{ "pv.power": "Estimated CO2 Saving" }}
              yDomains={[computeCO2Domain]}
              start={dateRange[0]}
              end={dateRange[1]}
              dataMap={[remapSeriesDaily(0.001 * WH_TO_G_CO2)]} // Use hourly data to estimate CO2 savings in kg.
              bar={true}
              yAxisDecimals={[2]}
              displayUnits={["kg"]}
              title={true}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

interface Props {
  orderID: string;
  dashName?: string;
}

export const Dashboard: React.FC<Props> = ({ orderID, dashName }) => {
  return (
    <ApolloProvider client={ConnXClient}>
      <PageContainer title="DCX Dashboard" subtitle={dashName}>
        <DashboardInternal orderID={orderID} />
      </PageContainer>
    </ApolloProvider>
  );
};
