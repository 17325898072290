import React from "react";
import CreateDeviceConfiguration, {
  FormValues,
} from "./CreateDeviceConfiguration";
import DeviceConfigurationList, {
  ListDeviceConfigurations,
} from "./DeviceConfigurationList";
import { Tabs, Tab } from "react-bootstrap";
import Client, { DeviceConfiguration, ListingResult } from "../../api/connx";
import { anyGranted } from "../../user/user";
import { RBACPermission } from "../../user/rbac";
import { PageContainer } from "../../components";

const handleCreateDeviceConfig = async (values: FormValues): Promise<void> => {
  if (!values.deviceid) {
    throw new Error("deviceid has not been selected");
  }
  if (!values.fwdir) {
    throw new Error("deviceid has not been selected");
  }
  if (!values.fwver) {
    throw new Error("deviceid has not been selected");
  }

  await Client.createNewDeviceConfig({
    name: values.name,
    desc: values.desc,
    deviceid: values.deviceid.value,
    fwdir: values.fwdir.value,
    fwver: values.fwver.value,
  });
};

const handleListDeviceConfiguration = ({
  filter,
  nextKey,
}: {
  filter: string;
  nextKey?: string;
}): Promise<ListingResult<DeviceConfiguration>> => {
  return Client.listDeviceConfigurations(filter, nextKey);
};

interface ConfigurationViewProps {
  onCreateDeviceConfig?: (cfg: FormValues) => Promise<void> | void;
  listDeviceConfigurations?: ListDeviceConfigurations;
  hasPermission?: (p: RBACPermission) => boolean;
}

const ConfigurationView: React.FC<ConfigurationViewProps> = (props) => {
  const {
    onCreateDeviceConfig = handleCreateDeviceConfig,
    listDeviceConfigurations = handleListDeviceConfiguration,
    hasPermission = anyGranted,
  } = props;

  return (
    <PageContainer title="ConnX Device Configuration">
      <Tabs id="device-configuration-tabs">
        <Tab
          eventKey="list-device-config"
          title="List"
          disabled={!hasPermission("device_cfg_read")}
        >
          <br />
          <DeviceConfigurationList
            listDeviceConfigurations={listDeviceConfigurations}
          />
        </Tab>
        <Tab
          eventKey="create-device-config"
          title="Create"
          disabled={!hasPermission("device_cfg_create")}
        >
          <br />
          <CreateDeviceConfiguration
            onSubmit={onCreateDeviceConfig}
            canEditConfig={true}
          />
        </Tab>
      </Tabs>
    </PageContainer>
  );
};

export default ConfigurationView;
