const checkFor = (code, errors) => {
  return (
    errors &&
    errors.find(({ extensions = {} }) => {
      return extensions.code === code;
    })
  );
};

// CheckGraphQLErrorForCode checks a ApolloError for a specific error code
const CheckGraphQLErrorForCode = ({ graphQLErrors }, code) => {
  return checkFor(code, graphQLErrors);
};

// Throws an error using the given message if the given code is present, otherwise
// use the fallback message, or the original error message.
const ThrowGraphQLError = (e, code, message, fallback = null) => {
  if (CheckGraphQLErrorForCode(e, code)) {
    throw new Error(message);
  } else {
    throw new Error(fallback || e.message || "Undefined error");
  }
};

export { CheckGraphQLErrorForCode, ThrowGraphQLError };
