import { Field } from "formik";
import React from "react";
import { RBACView } from "./AdminUserEdit";

const fieldName = "rbacRoles";

type FieldType = {
  value: string[];
};

type FormType = {
  setFieldValue: (fieldName: string, value: string[] | undefined) => void;
};

type Params = {
  field: FieldType;
  form: FormType;
};

const RBACViewInput = (): JSX.Element => (
  <Field name={fieldName} id={fieldName} type="text">
    {(params: Params): JSX.Element => (
      <div>
        <label htmlFor={fieldName} className={"label-color"}>
          {"Roles"}
        </label>
        <div>
          <RBACView
            userRoles={params.field.value}
            onRolesChanged={(userRoles: string[]): void => {
              params.form.setFieldValue(fieldName, userRoles);
            }}
          />
        </div>
      </div>
    )}
  </Field>
);

export default RBACViewInput;
