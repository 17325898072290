// RBAC roles.
// https://gitlab.com/ozxcorp/sw-docs/-/blob/master/concepts/connx_v1/rbac.md
export type RBACRole =
  | "user_admin"
  | "user_admin_read"
  | "device_admin"
  | "device_admin_read"
  | "manufacturer_admin"
  | "manufacturer_read"
  | "manufacturer_provisioning"
  | "user"
  | "model_order_admin_ro";

// RBAC permissions.
export type RBACPermission =
  | "user_create"
  | "user_read"
  | "user_update"
  | "user_delete"
  | "group_list"
  | "group_create"
  | "group_read"
  | "group_list_users"
  | "group_update"
  | "group_delete"
  | "group_add_user"
  | "group_remove_user"
  | "rv_owner_assign"
  | "rv_owner_revoke"
  | "rv_owner_list"
  | "device_read"
  | "device_create"
  | "device_update"
  | "device_delete"
  | "device_cfg_read"
  | "device_cfg_create"
  | "device_cfg_update"
  | "fw_read"
  | "fw_list"
  | "rv_model_read"
  | "rv_model_create"
  | "rv_model_update"
  | "rv_model_delete"
  | "rv_order_read"
  | "rv_order_create"
  | "rv_order_update"
  | "rv_order_delete"
  | "rv_order_admin"
  | "provisioning_link_thing";

export type RBACPermissionSet = RBACPermission[];

export interface Grant {
  permissions: RBACPermissionSet;
  inherits?: RBACRole[];
}

export type RBACOptions = {
  roles: Partial<Record<RBACRole, Grant>>;
};

export class RBAC {
  constructor(private readonly options: RBACOptions) {}

  // for the given roles, do any of them grant the specified permission.
  anyGranted(role: RBACRole[] | RBACRole, p: RBACPermission): boolean {
    if (role instanceof Array) {
      return role.some((r) => {
        const grant = this.options.roles[r];
        return grant ? this.checkRole(grant, p) : false;
      });
    }
    const grant = this.options.roles[role];
    return grant ? this.checkRole(grant, p) : false;
  }

  private checkRole(obj: RBACPermission[] | Grant, p: RBACPermission): boolean {
    switch (true) {
      case obj instanceof Array:
        return (obj as RBACPermissionSet).some((x) => x === p);
      case typeof obj === "object": {
        const g = obj as Grant;
        if (this.checkRole(g.permissions, p)) {
          return true;
        }
        if (g.inherits) {
          return this.anyGranted(g.inherits, p);
        }
        return false;
      }
      default:
        return false;
    }
  }
}

export const defRBAC = new RBAC({
  roles: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    user_admin_read: {
      permissions: [
        "user_read",
        "group_read",
        "group_list",
        "group_list_users",
        "rv_owner_list",
      ],
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    user_admin: {
      permissions: [
        "user_create",
        "user_update",
        "user_delete",
        "group_create",
        "group_update",
        "group_delete",
        "rv_owner_assign",
        "rv_owner_revoke",
      ],
      inherits: ["user_admin_read"],
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    device_admin_read: {
      permissions: ["device_read", "device_cfg_read"],
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    device_admin: {
      permissions: [
        "device_create",
        "device_update",
        "device_cfg_update",
        "device_cfg_create",
        "rv_order_admin",
      ],
      inherits: ["device_admin_read"],
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    manufacturer_read: {
      permissions: ["rv_model_read", "rv_order_read"],
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    manufacturer_admin: {
      permissions: [
        "rv_model_create",
        "rv_model_update",
        "rv_order_create",
        "rv_order_update",
      ],
      inherits: ["manufacturer_read"],
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    manufacturer_provisioning: {
      permissions: ["provisioning_link_thing"],
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    model_order_admin_ro: {
      permissions: [],
      inherits: ["manufacturer_read"],
    },
  },
});
