import React from "react";
import { Card } from "react-bootstrap";

const Documentation = (): JSX.Element => (
  <>
    <div className="text-center page-header">
      <h1>Documentation</h1>
      <small>ConnX documentation</small>
    </div>
    <div className="container">
      <Card>
        <Card.Body>
          Please refer to the documentation found{" "}
          <a
            href="https://help.connxuser.com/web-app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </Card.Body>
      </Card>
    </div>
  </>
);

export default Documentation;
