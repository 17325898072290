import React from "react";
import PaginatedSearchList from "../../../components/PaginatedSearchList";
import {
  TableRow,
  renderTable,
  RowRendererProps,
} from "../../../components/PaginatedListTableRenderer";
import {
  withPaginatedItemSupport,
  ListRendererProps,
} from "../../../components/PaginatedList";
import { RVModel } from "../../../api/connx/rvmodel";
import { RVModelClient } from "../../../api/api";
import RVRowActions from "./RVRow";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RBACPermission } from "../../../user/rbac";
import { anyGranted } from "../../../user/user";

async function getRVModels({
  filter,
  nextKey,
}: {
  nextKey?: string;
  filter: string;
}) {
  const r = await RVModelClient.listRVModels({ nextKey, filter });
  return {
    items: r.models.map((m) => withPaginatedItemSupport(m, m.docID)),
    nextKey: r.nextKey,
  };
}

interface RowProps {
  hasPermission?: (p: RBACPermission) => boolean;
}

const MyRow = withRouter(
  (props: RowProps & RowRendererProps<RVModel> & RouteComponentProps) => {
    const {
      history,
      item,
      index,
      hasPermission = anyGranted,
      onDeleted,
      ...childProps
    } = props;

    const handleDelete = hasPermission("rv_model_delete")
      ? (): Promise<boolean> => RVModelClient.delete(item.groupID, item.docID)
      : undefined;
    const handleEdit = hasPermission("rv_model_update")
      ? (): void =>
          history.push(
            `/connx/rvmodels/edit/model/${item.groupID}/${item.docID}`
          )
      : undefined;

    return (
      <TableRow item={item} {...childProps}>
        <div>{item.name}</div>
        <div>{item.desc}</div>
        <RVRowActions
          getItemName={(): string => item.name}
          onDelete={handleDelete}
          onDeleted={(): void => onDeleted(index)}
          onEdit={handleEdit}
        />
      </TableRow>
    );
  }
);

const TableRenderer = renderTable(MyRow);

const MyTableRenderer = (props: ListRendererProps<RVModel>): JSX.Element => (
  <TableRenderer {...props}>
    <div>Name</div>
    <div>Description</div>
    <div></div>
  </TableRenderer>
);

const RVModelList = ({ ...rest }): JSX.Element => (
  <PaginatedSearchList
    getItems={getRVModels}
    ListRenderer={MyTableRenderer}
    {...rest}
  />
);

export default RVModelList;
