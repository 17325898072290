import React, { useState, useCallback } from "react";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { Button, Form } from "react-bootstrap";

interface GenericFormProps<M extends object> {
  // the default form values
  defaultValues: M;
  // submit callback
  onSubmit: (v: M) => Promise<void> | void;
  // validation schema
  schema: yup.ObjectSchema<M | undefined>;
  // confirmation button label
  confirmLabel?: string;
  // children form input items to render
  children?: React.ReactNode;
}

const GenericForm = <M extends object>(
  props: GenericFormProps<M>
): JSX.Element => {
  const {
    onSubmit,
    defaultValues,
    children,
    confirmLabel: saveLabel = "Confirm",
    schema,
  } = props;

  const [values, setValues] = useState<M>(defaultValues);

  const handleFormSubmit = useCallback(
    async (values: M, { setSubmitting, resetForm }: FormikHelpers<M>) => {
      try {
        await onSubmit(values);
        setValues(defaultValues);
        resetForm(defaultValues);
      } catch (e) {
        console.log(e);
        alert(e.message);
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, defaultValues]
  );

  return (
    <Formik
      validationSchema={schema}
      initialValues={values}
      onSubmit={handleFormSubmit}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty }): JSX.Element => (
        <Form
          noValidate
          onSubmit={(e): void => {
            handleSubmit(e as React.FormEvent<HTMLFormElement>);
          }}
        >
          {children}
          <Button
            variant="primary"
            type="submit"
            disabled={isSubmitting || !isValid || !dirty}
          >
            {saveLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default GenericForm;
