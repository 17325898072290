import ReactGA from "react-ga";
import { createBrowserHistory, History } from "history";
import getConfig from "../config";

export const filterOutId = (path: string): string => {
  // Remove <id> from dcx/<id>
  // <id> is in the format /8-4-4-4-12 characters, where each character is alphanumeric.
  const start = path.search(
    "/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}"
  );

  if (0 <= start) {
    const end = start + 37;
    return path.substring(0, start) + path.substring(end);
  }
  return path;
};

export const filterOutSearch = (path: string): string => {
  // Remove "?<search_type>=<search_value>"
  const start = path.search("[?]([&a-z_]+=[%\\w]+)+");

  if (0 <= start) {
    return path.substring(0, start);
  }
  return path;
};

export const filterLocationPath = (path: string): string => {
  const noId = filterOutId(path);
  const result = filterOutSearch(noId);
  return result;
};

export const initAnalytics = (): History<History.UnknownFacade> => {
  const browserHistory = createBrowserHistory();
  const { analytics } = getConfig();
  if (analytics.enabled) {
    // Initialize google analytics page view tracking
    ReactGA.initialize("UA-172952268-2");
    // Capture first page load.
    const path = filterLocationPath(
      window.location.pathname + window.location.search + window.location.hash
    );
    ReactGA.pageview(path);
    // Capture route changes.
    browserHistory.listen((location) => {
      // Record a pageview for the given page
      const path = filterLocationPath(
        location.pathname + location.search + location.hash
      );
      ReactGA.pageview(path);
    });
  }

  return browserHistory;
};
