import React, { useCallback } from "react";
import EditUserGroupDetails from "./EditUserGroupDetails";
import EditUserGroupUsers from "./EditUserGroupUsers";
import {
  UpdateUserGroupInput,
  GetUserGroupOutput,
  UpdateUserGroupOutput,
  UserGroup,
} from "../../api/usergroup";
import { GetUsersOutput } from "../../api/user";
import { Button } from "react-bootstrap";

interface EditUserGroupProps {
  groupId: string;
  onSaveUserGroupDetails: (
    input: UpdateUserGroupInput
  ) => Promise<UpdateUserGroupOutput> | UpdateUserGroupOutput;
  getUserGroup: (
    id: string
  ) => Promise<GetUserGroupOutput> | GetUserGroupOutput;
  getGroupUsers: (
    groupId?: string,
    nextKey?: string
  ) => Promise<GetUsersOutput> | GetUsersOutput;
  findUsers: ({
    filter,
  }: {
    filter?: string;
  }) => Promise<GetUsersOutput> | GetUsersOutput;
  addUser: (userId: string, groupId: string) => Promise<void> | void;
  removeUser: (userId: string, groupId: string) => Promise<void> | void;
  onDone?: () => void;
}

const EditUserGroup = ({
  onSaveUserGroupDetails,
  getUserGroup,
  getGroupUsers,
  findUsers,
  addUser,
  removeUser,
  groupId,
  onDone,
}: EditUserGroupProps): JSX.Element => {
  // memoize function wrappers
  const getUserGroupWrapped = useCallback(async (): Promise<UserGroup> => {
    const r = await getUserGroup(groupId);
    if (!r.UserGroup) {
      throw new Error("User group not found");
    }
    return r.UserGroup;
  }, [groupId, getUserGroup]);
  const getGroupUsersWrapped = useCallback(
    (nextKey) => getGroupUsers(groupId, nextKey),
    [groupId, getGroupUsers]
  );
  const addUserWrapped = useCallback((userId) => addUser(userId, groupId), [
    groupId,
    addUser,
  ]);
  const removeUserWrapped = useCallback(
    (userId) => removeUser && removeUser(userId, groupId),
    [groupId, removeUser]
  );
  const handleDone = useCallback(() => onDone && onDone(), [onDone]);

  return (
    <>
      <div className="text-center page-header">
        <h1>Edit user group</h1>
      </div>
      <div className="container">
        <h2>Summary</h2>
        <EditUserGroupDetails
          onSave={onSaveUserGroupDetails}
          getUserGroupDetails={getUserGroupWrapped}
        />
        {getGroupUsers && (
          <>
            <hr />
            <h2>Users</h2>
            <EditUserGroupUsers
              getGroupUsers={getGroupUsersWrapped}
              removeUser={removeUserWrapped}
              findUsers={findUsers}
              addUser={addUserWrapped}
            />
          </>
        )}
        {onDone && (
          <>
            <hr />
            <Button onClick={handleDone}>Done</Button>
          </>
        )}
      </div>
    </>
  );
};

export default EditUserGroup;
