import React from "react";
import { renderTable } from "../../../../components/PaginatedListTableRenderer";
import { ListRendererProps } from "../../../../components/PaginatedList";
import { RVModelOrder } from "../../../../api/connx/rvmodelorder";
import { Row, RowProps } from "./Row";

const TableRenderer = renderTable(Row);

export const MyTableRenderer = (
  props: ListRendererProps<RVModelOrder> & RowProps
): JSX.Element => (
  <TableRenderer {...props}>
    <div>Name</div>
    <div>Description</div>
    <div>Serial #</div>
    <div>Order date</div>
    <div>Provisioned</div>
    {props.displayIDs && <div>ID</div>}
    <div></div>
  </TableRenderer>
);
