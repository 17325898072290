import React from "react";
import { useHistoricalEvents, Event } from "./dcxEvents";
import { Moment } from "moment";
import {
  ReportCard,
  AxisDomain,
  RemapFunc,
  EventPoint,
  ValueFormatter,
} from "./ReportCard";
import { Series } from "./iotAnalytics";
import { ErrorView } from "../../../../components";

export const transformActions = (
  requestEvents: boolean,
  events: Event[] | undefined,
  actionDisplayNames: Record<string, string>,
  actionDisplayColors: Record<string, string>
): EventPoint[] => {
  // Convert event actions to actionDisplayNames.
  let result = Array<EventPoint>();
  if (requestEvents && events && 0 < events.length) {
    result = events.map((e) => ({
      x: e.time,
      label: actionDisplayNames[e.action] || e.action,
      color: actionDisplayColors[e.action] || "#000",
    }));
  }

  return result;
};

export const ReportCardWithEvents: React.FC<{
  orderID: string;
  seriesNames: string[];
  displayNames?: Record<string, string>;
  eventType?: string;
  actionDisplayNames?: Record<string, string>;
  actionDisplayColors?: Record<string, string>;
  xDomain?: AxisDomain;
  yDomains: AxisDomain[];
  start: Moment;
  end: Moment;
  dataMap?: RemapFunc[];
  showMin?: boolean;
  showMax?: boolean;
  zoomNonZero?: boolean;
  yAxisDecimals?: number[];
  bar?: boolean;
  displayUnits?: string[];
  yDomainSeriesIdx?: number[];
  curves?: string[];
  valueFormatters?: ValueFormatter[];
}> = (props) => {
  const {
    orderID,
    seriesNames,
    displayNames = {},
    eventType = "",
    actionDisplayNames = {},
    actionDisplayColors = {},
    xDomain,
    yDomains,
    start,
    end,
    dataMap = [(data: Series[]): Series[] => data],
    showMin = false,
    showMax = false,
    zoomNonZero = false,
    yAxisDecimals = [0],
    bar = false,
    displayUnits = [""],
    yDomainSeriesIdx,
    curves,
    valueFormatters,
  } = props;

  // Get events.
  const MAX_RESULTS = 100;
  const eventsResult = useHistoricalEvents(orderID, {
    eventType,
    start,
    end,
    maxResults: MAX_RESULTS,
  });

  const requestEvents = 0 < eventType.length;

  if (requestEvents && eventsResult.error) {
    return <ErrorView />;
  }

  // Transform event actions to EventPoints.
  const events = transformActions(
    requestEvents,
    eventsResult.events,
    actionDisplayNames,
    actionDisplayColors
  );

  return (
    <ReportCard
      orderID={orderID}
      seriesNames={seriesNames}
      displayNames={displayNames}
      xDomain={xDomain}
      yDomains={yDomains}
      start={start}
      end={end}
      dataMap={dataMap}
      showMin={showMin}
      showMax={showMax}
      zoomNonZero={zoomNonZero}
      yAxisDecimals={yAxisDecimals}
      bar={bar}
      displayUnits={displayUnits}
      yDomainSeriesIdx={yDomainSeriesIdx}
      curves={curves}
      valueFormatters={valueFormatters}
      events={events}
      loading={requestEvents && eventsResult.loading}
    />
  );
};
