import { UserClient } from "./apolloClient";
import gql from "graphql-tag";
import * as randomBytes from "randombytes";
import { CheckGraphQLErrorForCode } from "../graphql/errors";

export interface User {
  id: string;
  revision: number;
  email: string;
  name?: string;
  familyName?: string;
  roles?: string[];
  rbacRoles?: string[];
}

export interface GetUsersOutput {
  items: User[];
  nextKey?: string;
}

interface AdminGetUserOutput {
  user: User;
}

export interface UpdateUserInput {
  roles?: string[];
  rbacRoles?: string[];
}

interface GetUsersInput {
  // filter for users with a username like the given value
  filterUsernameLike?: string;
  // list users in the specified group. only applicable to users with 'admin:read' writes, otherwise
  // ignore and only users in the calling users group are returned
  groupID?: string;
  // pagination support
  nextKey?: string;
}

// AdminGetUsers will return a list of user based on the input filter. Requires 'admin:read' or 'ug:read' role.
const AdminGetUsers = async (p: GetUsersInput): Promise<GetUsersOutput> => {
  const ListUsers = gql`
    query Users($input: UserQueryInput!) {
      Users(input: $input) {
        users {
          id
          revision
          email
        }
        nextKey
      }
    }
  `;

  // define the query input
  interface QueryInput {
    maxResults: number;
    nextKey?: string;
    filterUsernameLike?: string;
    groupID?: string;
  }
  const input: QueryInput = {
    maxResults: 10,
    ...p,
  };
  // execute the query
  const r = await UserClient.query({
    query: ListUsers,
    variables: { input: input },
  });

  // translate to output
  return {
    items: r.data.Users.users,
    nextKey: r.data.Users.nextKey,
  };
};

// AdminGetUser returns the details of a given user ID. Requires 'admin' role.
const AdminGetUser = async (userID: string): Promise<AdminGetUserOutput> => {
  const GetUser = gql`
    query AdminUser($id: ID!) {
      AdminUser(id: $id) {
        email
        id
        roles
        name
        familyName
        revision
        rbacRoles
      }
    }
  `;

  const r = await UserClient.query({
    query: GetUser,
    variables: { id: userID },
  });

  return { user: r.data.AdminUser };
};

// UpdateUser updates a users attributes. Requires 'admin' role.
// UserID - the ID of the user to update
// revision - incremented revision number to ensure user data consistency
// attr - sets of user attributes to update
const UpdateUser = async (
  userID: string,
  revision: number,
  changes: UpdateUserInput
): Promise<{ user: User }> => {
  const UpdateUser = gql`
    mutation AdminUser($input: UpdateUserInput!) {
      updateUser(input: $input) {
        user {
          email
          id
          roles
          name
          familyName
          revision
          rbacRoles
        }
        clientMutationId
      }
    }
  `;

  // form the mutation parameters
  interface UpdateUserParams {
    id: string;
    revision: number;
    clientMutationId: string;
    roles?: string[];
    rbacRoles?: string[];
  }
  const clientId = randomBytes.default(10);
  const params: UpdateUserParams = {
    id: userID,
    revision: revision,
    clientMutationId: clientId.toString("hex"),
    roles: changes.roles,
    rbacRoles: changes.rbacRoles,
  };

  // execute
  try {
    const r = await UserClient.mutate({
      mutation: UpdateUser,
      variables: { input: params },
    });
    return { user: r.data.user };
  } catch (e) {
    console.log(e);
    if (CheckGraphQLErrorForCode(e, "revision_error")) {
      throw new Error("User data has changed since loaded, please try again");
    } else {
      throw new Error("Failed to save");
    }
  }
};

const adminSignUpUser = async (
  email: string,
  name: string,
  familyName: string,
  group: string,
  rbacRoles: string[]
): Promise<boolean> => {
  try {
    const GQL = gql`
      mutation($input: AdminSignUpUser!) {
        adminSignUpUser(input: $input)
      }
    `;
    interface Output {
      adminSignUpUser: boolean;
    }
    const r = await UserClient.mutate<Output>({
      mutation: GQL,
      variables: {
        input: {
          email: email,
          firstName: name,
          familyName: familyName,
          group: group,
          rbacRoles: rbacRoles,
        },
      },
    });
    if (!r.data) {
      throw new Error("unexpected result");
    }
    return r.data.adminSignUpUser;
  } catch (e) {
    console.log(e);
    throw new Error("Failed to invite user");
  }
};

export { AdminGetUsers, AdminGetUser, UpdateUser, adminSignUpUser };
