import React from "react";
import { LoadingSpinner } from ".";

export interface FieldProps {
  value: string;
  title: string;
  loading?: boolean;
  error?: string;
}

export const Value: React.FC<FieldProps> = ({
  value,
  title,
  loading = false,
  error = false,
}) => {
  return (
    <div>
      {!loading && !error && <div>{value}</div>}
      {loading && <LoadingSpinner />}
      {error && <div className="text-danger">{error}</div>}
      <div className="font-weight-light font-italic">{title}</div>
    </div>
  );
};
