import "./custom.scss";
import React, { useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import "./App.scss";
import Routes from "./Routes";
import AdminMenu from "./components/menu/AdminMenu";
import ConnXMenu from "./components/menu/ConnXMenu";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { Auth } from "aws-amplify";
import UserMenu from "./components/menu/UserMenu";
import { amplifyConfigure } from "./amplify";
import { useUser } from "./userContext";
import {
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
  Grid,
  Paper,
} from "@material-ui/core";
import {
  dumpInfo,
  errorBoundaryHandler,
  ErrorFallbackComponent,
} from "./helpers/errors";

dumpInfo();

amplifyConfigure();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navBar: {
      zIndex: theme.zIndex.drawer + 100,
    },
    appLoading: {
      flexGrow: 1,
      height: "100%",
    },
    appLoadingPaper: {
      padding: theme.spacing(3, 3),
    },
  })
);

export type AppProps = RouteComponentProps;

const App: React.FC<AppProps> = (props) => {
  const { history } = props;

  const handleLogout = useCallback(async () => {
    await Auth.signOut();
    history.push("/");
  }, [history]);

  const { user, checkingSession } = useUser();
  const childProps = {};
  const classes = useStyles();
  return checkingSession ? (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.appLoading}
    >
      <Grid item>
        <Paper className={classes.appLoadingPaper}>
          <CircularProgress />
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <>
      <Navbar
        collapseOnSelect
        expand="sm"
        variant="dark"
        bg="dark"
        sticky="top"
        className={classes.navBar}
      >
        <Navbar.Brand as={LinkContainer} to="/">
          <img src="/connx-logo-dark.png" alt="" height="40px"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <AdminMenu />
            <ConnXMenu />
          </Nav>
          <div className="navbar-nav">
            <a
              className="nav-link"
              href="https://help.connxuser.com/web-app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help
            </a>
          </div>
          <Nav>
            {user.isAuthenticated ? (
              <UserMenu
                userID={user.id}
                userName={user.firstName + " " + user.familyName}
                userEmail={user.email}
                onLogout={handleLogout}
              />
            ) : (
              <NavItem>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </NavItem>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="App">
        <div className="AppContent">
          {/* 
            Wrap routes in an error boundary.
            You can simulate an error condition by starting the app locally (e.g. `yarn start`),
            opening the app in your browser, stopping the local server, then switching to a page in the app
            that is lazily loaded.
            */}
          <ErrorBoundary
            onError={errorBoundaryHandler}
            FallbackComponent={ErrorFallbackComponent}
          >
            <Routes childProps={childProps} />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default App;
