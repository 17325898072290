import React from "react";
import OrdersList from "./OrderList";
import { PageContainer } from "../../../components";
import { ApolloProvider } from "@apollo/react-hooks";
import { UserClient } from "../../../api/apolloClient";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props {
  userID: string;
}

const OrdersView: React.FC<Props & RouteComponentProps> = ({
  userID,
  history,
}) => (
  <PageContainer
    title="Your orders"
    subtitle="If you have any orders, they will be shown here."
  >
    <ApolloProvider client={UserClient}>
      <OrdersList
        userID={userID}
        onDCXDash={(e): void =>
          history.push(
            `/dcx/${e.order.docID}/dashboard?dash_name=${e.order.name}`
          )
        }
        canViewDCX={true}
      />
    </ApolloProvider>
  </PageContainer>
);

export default withRouter(OrdersView);
