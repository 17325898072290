/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { Modal } from "react-bootstrap";

interface AsModalProps {
  title: string;
  show?: boolean;
  onHide?: () => void;
}

const AsModal = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<P & AsModalProps> => ({
  title,
  show,
  onHide,
  ...otherProps
}: AsModalProps) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body>
        <WrappedComponent {...(otherProps as P)} />
      </Modal.Body>
    </Modal>
  );
};

export default AsModal;
