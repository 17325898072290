import React from "react";
import SystemEventList from "./systemevents/SystemEventList";
import { PageContainer } from "../../components";

const SystemEventView: React.FC<{}> = () => (
  <PageContainer title="ConnX System Events">
    <div className="container">
      <SystemEventList />
    </div>
  </PageContainer>
);

export default SystemEventView;
