import React from "react";
import { NavDropdown, DropdownButton } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

interface Props {
  userName: string;
  userEmail: string;
  userID: string;
  onLogout: () => Promise<void> | void;
}

const UserMenu: React.FC<Props> = (props) => {
  return (
    <NavDropdown
      title="User"
      id="collapsible-nav-dropdown"
      as={DropdownButton}
      alignRight
    >
      <NavDropdown.Header>
        <div className="font-weight-bold">{props.userName}</div>
        {props.userEmail}
      </NavDropdown.Header>
      <NavDropdown.Divider />
      <LinkContainer to={`/user/${props.userID}/orders`}>
        <NavDropdown.Item>Orders</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/user/settings`}>
        <NavDropdown.Item>Settings</NavDropdown.Item>
      </LinkContainer>
      <NavDropdown.Divider />
      <NavDropdown.Item href="#!" onClick={props.onLogout}>
        Logout
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserMenu;
