import React from "react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const FutureDateTimePicker = ({
  value,
  onChange,
  label,
}: {
  value: Date;
  onChange: (date: Date | null) => void;
  label: string;
}): JSX.Element => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        variant="inline"
        format="MM/dd/yyyy HH:mm a"
        margin="normal"
        label={label}
        value={value}
        onChange={onChange}
        disablePast={true}
      />
    </MuiPickersUtilsProvider>
  );
};
