import React, { useCallback, useState } from "react";
import PaginatedSearchList from "../../../../components/PaginatedSearchList";
import Form from "react-bootstrap/Form";
import { getRVModelOrders } from "./GetRVModelOrders";
import { ModelFilter } from "./ModelFilter";
import { MyTableRenderer } from "./Table";
import { AssignOwnerModal } from "./AssignOwnerModal";
import { useHistory } from "react-router-dom";
import { OrderEvent, RowProps, LinkEvent } from "./Row";
import { RVModelOrderClient } from "../../../../api/api";
import { anyGranted } from "../../../../user/user";
import {
  LinkModal,
  UnlinkModal,
  LinkModalClosed,
  UnlinkModalClosed,
} from "./LinkModal";
import { ConnXClient, UserClient } from "../../../../api/apolloClient";
import { ApolloProvider } from "@apollo/react-hooks";
import {
  useSuccessFailureToasty,
  SuccessFailureToasty,
} from "../../../../components";

const RVModelOrderList = ({ ...rest }): JSX.Element => {
  // state
  const [displayIDs, setDisplayIDs] = useState(false);
  const [assignOrderID, setAssignOrderID] = useState<string | undefined>(
    undefined
  );
  const [linkOrder, setLinkOrder] = useState<{
    modelID?: string;
    orderID?: string;
  }>();
  const [unlinkOrder, setUnlinkOrder] = useState<{
    modelID?: string;
    orderID?: string;
    thingName?: string;
  }>();
  const {
    props: toastyProps,
    setSuccess: setToastySuccess,
    setFailure: setToastyFailure,
  } = useSuccessFailureToasty();
  const [listRefreshToken, setListRefreshToken] = useState(0);

  // handlers
  const handleCheckboxChanged = useCallback(() => {
    setDisplayIDs((d) => !d);
  }, []);
  const handleAssignOwner = (orderID: string): void => {
    setAssignOrderID(orderID);
  };

  const history = useHistory();

  const rowProps: RowProps = {
    ...rest,
    displayIDs: displayIDs,
    onAssignOwner: handleAssignOwner,
    onEdit: (e: OrderEvent): void => {
      history.push(`/connx/rvmodels/edit/order/${e.modelID}/${e.orderID}`);
    },
    onDelete: (e: OrderEvent): Promise<boolean> =>
      RVModelOrderClient.delete(e.modelID, e.orderID),
    onLink: (e: LinkEvent): void => {
      if (e.thingName && "" !== e.thingName) {
        setUnlinkOrder(e);
      } else {
        setLinkOrder(e);
      }
    },
    canDelete: anyGranted("rv_order_delete"),
    canUpdate: anyGranted("rv_order_update"),
    canAssignOrder: anyGranted("rv_owner_assign"),
    canLink: anyGranted("provisioning_link_thing"),
  };

  const handleLinkModalClose = (e: LinkModalClosed): void => {
    setLinkOrder(undefined);
    if (e.cancelled) return;
    if (e.err) {
      setToastyFailure("Linking failed");
    } else {
      setListRefreshToken((p) => p + 1);
      setToastySuccess("Linked OK");
    }
  };

  const handleUnlinkModalClose = (e: UnlinkModalClosed): void => {
    setUnlinkOrder(undefined);
    if (e.cancelled) return;
    if (e.err) {
      setToastyFailure("Unlinking failed");
    } else {
      setToastySuccess("Unlinked OK");
      setListRefreshToken((p) => p + 1);
    }
  };

  return (
    <>
      <SuccessFailureToasty
        successMessage="Updated OK"
        failureMessage="Failed to update"
        {...toastyProps}
      />
      <ApolloProvider client={UserClient}>
        <AssignOwnerModal
          orderID={assignOrderID || ""}
          show={undefined !== assignOrderID}
          onDone={(): void => setAssignOrderID(undefined)}
        />
      </ApolloProvider>
      <ApolloProvider client={ConnXClient}>
        <LinkModal
          open={undefined !== linkOrder}
          onClose={handleLinkModalClose}
          {...linkOrder}
        />
        <UnlinkModal
          open={undefined !== unlinkOrder}
          onClose={handleUnlinkModalClose}
          thingName={unlinkOrder?.thingName || ""}
          {...unlinkOrder}
        />
      </ApolloProvider>
      <PaginatedSearchList
        getItems={getRVModelOrders}
        ListRenderer={MyTableRenderer}
        CustomFilters={ModelFilter}
        refresh={listRefreshToken}
        {...rowProps}
      >
        {{
          tableOptions: (
            <Form.Check
              label="Display IDs"
              checked={displayIDs}
              onChange={handleCheckboxChanged}
            />
          ),
        }}
      </PaginatedSearchList>
    </>
  );
};

export default RVModelOrderList;
