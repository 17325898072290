import React, { useCallback, useEffect } from "react";
import {
  BaseFilter,
  CustomFilterComponentProps,
} from "../../../../components/PaginatedSearchList";
import { RVModel } from "../../../../api/connx/rvmodel";
import RVModelSelection from "../RVModelSelection";

export interface MyCustomFilterValues extends BaseFilter {
  model?: RVModel;
}

export const ModelFilter = ({
  value,
  onChange,
  disabled,
  onDisableSearch,
}: CustomFilterComponentProps<MyCustomFilterValues>): JSX.Element => {
  const handleChange = useCallback(
    (mdl?: RVModel) => {
      onDisableSearch(!mdl);
      onChange({ ...value, model: mdl });
    },
    [onDisableSearch, onChange, value]
  );
  useEffect(() => {
    onDisableSearch(!value.model);
  }, [onDisableSearch, value]);
  return <RVModelSelection onChange={handleChange} disabled={disabled} />;
};
