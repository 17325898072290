import React, { useCallback } from "react";
import { AuthState } from "@aws-amplify/ui-components";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { makeStyles, createStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(
  createStyles({
    authenticator: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      height: "90vh",
    },
  })
);

const Login = (): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();

  // handle authentication state change
  const handleStateChange = useCallback(
    (nextAuthState: AuthState) => {
      if (nextAuthState === AuthState.SignedIn) {
        history.goBack();
      }
    },
    [history]
  );

  return (
    <AmplifyAuthenticator
      className={classes.authenticator}
      initialAuthState={AuthState.SignIn}
      handleAuthStateChange={handleStateChange}
    >
      <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
    </AmplifyAuthenticator>
  );
};

export default Login;
