import React, { useCallback } from "react";
import { useModelLoader } from "../../../helpers/hooks";
import { RVModelOrderClient } from "../../../api/api";
import RVModelOrderDetails, {
  RVModelOrderFormValues,
} from "./RVModelOrderDetails";
import { RVModel } from "../../../api/connx/rvmodel";
import { Loading } from "../../../components";

interface RVModelOrderEditProps {
  modelID: string;
  orderID: string;
  onDone?: () => void;
}

const RVModelOrderEdit: React.FC<RVModelOrderEditProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const defaultDone = (): void => {};
  const done = props.onDone || defaultDone;

  const handleOrderLoad = useCallback(() => {
    return RVModelOrderClient.get(props.modelID, props.orderID);
  }, [props]);

  const handleError = useCallback(
    (e: Error) => {
      console.log(e);
      alert("Failed to load");
      done();
    },
    [done]
  );

  // load the model
  const [loading, model] = useModelLoader(handleOrderLoad, handleError);

  // handle submission of form
  const handleSubmit = useCallback(
    async (f: RVModelOrderFormValues) => {
      try {
        await RVModelOrderClient.update(model, f);
        done();
      } catch (e) {
        console.log(e);
        throw new Error("Failed to save");
      }
    },
    [done, model]
  );

  return (
    <>
      <div className="text-center page-header">
        <h1>Edit RV Order</h1>
      </div>
      <div className="container">
        {loading ? (
          <Loading />
        ) : (
          <RVModelOrderDetails
            onSubmit={handleSubmit}
            defaultValues={{
              name: model.name,
              desc: model.desc,
              serialNumber: model.serialNumber,
              iotThingName: model.iotThingName,
              model: {} as RVModel, // provide a dummy so the form validation works
            }}
            onCancel={props.onDone}
            confirmButtonLabel={"Save"}
            showIoTThingName
          />
        )}
      </div>
    </>
  );
};

export default RVModelOrderEdit;
