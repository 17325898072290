import { useLocation, useHistory } from "react-router-dom";

/*
useAnchor provides a way to embed UI state into the browsers URL.

E.g. a component may have multiple tabs. To ensure the previously
selected tab is again selected after the user navigates back to the view,
the selected tab can be embedded in the URL using an anchor/hash.

const { anchor, setAnchor } = useAnchor<"edit" | "dash">("edit");
*/
export const useAnchor = <T extends string>(
  defaultAnchor: T
): { anchor: T; setAnchor: (anchor: T) => void } => {
  const location = useLocation();
  const anchor = location.hash ? location.hash.substr(1) : defaultAnchor;
  const history = useHistory();
  return {
    anchor: anchor as T,
    setAnchor: (anchor: T): void => {
      history.push(`#${anchor}`);
    },
  };
};
